<script setup lang="ts">
import { type UserId, UserRoleEnum } from '/@src/types/users'
import { SaveIcon } from '/@src/models/standardIcons'
import dayjs, { Dayjs } from 'dayjs'
import { useDashboardStore } from '/@src/stores/dashboard'
import type {
  MatchmakerDashboardKPITotals,
  MatchmakerDashboardStatistics,
  MatchmakerDashboardStatisticsKey,
  MatchmakerKpiDashboardResource,
  MatchmakerKPIMatchRequestStatusCamelCase,
  MatchmakerKpiTarget,
  SpecificMatchmakerKpiDashboardResource,
} from '/@src/types/dashboard'
import { MatchRequestStatusCamelCase } from '/@src/types/matchRequests'
import type { FAIconName, VTableColumn, VTableColumns } from '/@src/types/elements-ui'
import {
  copyStringToClipBoard,
  toCapitalizedWords,
  transformKeyToDayNumber,
} from '/@src/utils/helpers'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { isLeadMatchmaker } from '/@src/utils/entity-checks'
import {
  dayjsUTC,
  formatLocalizedDate,
  formatLocalizedDatePeriod,
} from '/@src/utils/date-formatter'
import type { IsoDayNumber } from '/@src/types/utils'
import { debounce, sumBy, uniq } from 'lodash'
import { toast } from 'vue-sonner'
import { useModal } from '/@src/composable/useModal'
import type { AdminUser, MatchmakerKpiAdminUserResource } from '/@src/types/admin-users'
import { useWebSocketModel } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import {
  bronzeMatchmakerKpiMultiplier,
  countMatchRequestKpisForStatuses,
  formatMatchmakerDashboardStatistics,
  formatMatchmakerKpi,
  getMatchKpiColorClass,
  getMatchmakerStatisticsCellClass,
  getMatchRequestKpisForStatuses,
  getNumberOfMatchRequestsInFocusMunicipalities,
  getNumberOfMatchRequestsInNation,
  goldMatchmakerKpiMultiplier,
  silverMatchmakerKpiMultiplier,
} from '/@src/mapping/dashboard'

definePage({
  meta: {
    requiresAdmin: true,
    roles: ['lead-matchmaker', 'office-manager'],
  },
})

const adminUserStore = useAdminUsersStore()
const dashboardStore = useDashboardStore()

const isLoading = ref(false)

const weekNumberInput = ref<Dayjs>(dayjs().startOf('week'))
const startDate = computed(() => weekNumberInput.value)
const endDate = computed(() => weekNumberInput.value.endOf('week'))

const showPercentages = ref(false)
const showKPIs = ref(false)
const teamLeadFilterId = ref<UserId>()
const sortAlphabetically = ref(false)

const isPastData = ref(false)

const data = ref<MatchmakerKpiDashboardResource>()

const currentUser = ref<MatchmakerKpiAdminUserResource>()

const { setModels } = useWebSocketModel<MatchmakerKpiAdminUserResource>({
  baseChannel: 'tg-admin-channel-admin-user',
  event: '.AdminUserUpdated',
  callback: (newAdminUser: WebSocketsModelReply<AdminUser>) => {
    const adminUser = data.value?.data?.find(
      (m) => m.adminUser.id === newAdminUser.model.id,
    )

    if (adminUser) {
      updateModel(adminUser.adminUser, newAdminUser.model)
      if (adminUser.statistics.targets) {
        adminUser.statistics.targets.open =
          newAdminUser.model.maxOpen * newAdminUser.model.targetModifier
        adminUser.statistics.targets.suggested =
          newAdminUser.model.maxSuggested * newAdminUser.model.targetModifier
      }
    }
  },
})
watch(
  () => data.value?.data,
  (newValue) => {
    if (newValue) {
      setModels(newValue.map((mr) => mr.adminUser))
    }
  },
  { immediate: true },
)

const matchmakerData = computed<SpecificMatchmakerKpiDashboardResource[]>(() => {
  if (!data.value) {
    return []
  }

  return data.value.data
    .filter((adminUser) => {
      if (!teamLeadFilterId.value || teamLeadFilterId.value === (-1 as UserId)) {
        return true
      } else {
        return adminUser.adminUser.leadUserId === teamLeadFilterId.value
      }
    })
    .toSorted((user1, user2) => {
      if (sortAlphabetically.value) {
        return user1.adminUser.firstname.localeCompare(user2.adminUser.firstname)
      }

      const user1IsMatchmaker = user1.adminUser.mainRole === UserRoleEnum.Matchmaker
      const user2IsMatchmaker = user2.adminUser.mainRole === UserRoleEnum.Matchmaker

      const user1Municipalities = user1.adminUser.municipalities
      const user2Municipalities = user2.adminUser.municipalities

      if (user1IsMatchmaker && !user2IsMatchmaker) {
        return -1
      } else if (!user1IsMatchmaker && user2IsMatchmaker) {
        return 1
      } else if (!user1IsMatchmaker && !user2IsMatchmaker) {
        return user1.adminUser.firstname.localeCompare(user2.adminUser.firstname)
      } else if (user1Municipalities.length === 0 && user2Municipalities.length !== 0) {
        return 1
      } else if (user1Municipalities.length !== 0 && user2Municipalities.length === 0) {
        return -1
      } else if (user1Municipalities.length === 0 && user2Municipalities.length === 0) {
        return user1.adminUser.firstname.localeCompare(user2.adminUser.firstname)
      } else if (user1Municipalities === user2Municipalities) {
        return user1.adminUser.firstname.localeCompare(user2.adminUser.firstname)
      } else {
        return user1Municipalities
          .sort()
          .join(',')
          .localeCompare(user2Municipalities.sort().join(','))
      }
    })
})

const statisticsTotals = computed<MatchmakerDashboardStatistics>(() =>
  matchmakerData.value.reduce<MatchmakerDashboardStatistics>(
    (agg, curr) => {
      if (curr.workdays.length === 0) {
        return agg
      }

      const values = curr.statistics?.values

      return {
        values: {
          open: {
            count: (agg.values?.open.count ?? 0) + (values?.open.count ?? 0),
            municipalities: (agg.values?.open.municipalities ?? []).concat(
              ...(values?.open.municipalities ?? []),
            ),
          },
          currentWeek: {
            count:
              (agg.values?.currentWeek.count ?? 0) + (values?.currentWeek.count ?? 0),
            municipalities: (agg.values?.currentWeek.municipalities ?? []).concat(
              ...(values?.currentWeek.municipalities ?? []),
            ),
          },
          suggested: {
            count: (agg.values?.suggested.count ?? 0) + (values?.suggested.count ?? 0),
            municipalities: (agg.values?.suggested.municipalities ?? []).concat(
              ...(values?.suggested.municipalities ?? []),
            ),
          },
        },
        targets: {
          open: (agg.targets?.open ?? 0) + (curr.statistics?.targets?.open ?? 0),
          currentWeek:
            (agg.targets?.currentWeek ?? 0) +
            (curr.statistics?.targets?.currentWeek ?? 0),
          suggested:
            (agg.targets?.suggested ?? 0) + (curr.statistics?.targets?.suggested ?? 0),
        },
      }
    },
    {
      values: {
        open: {
          count: 0,
          municipalities: [],
        },
        currentWeek: {
          count: 0,
          municipalities: [],
        },
        suggested: {
          count: 0,
          municipalities: [],
        },
      },
      targets: {
        open: 0,
        currentWeek: 0,
        suggested: 0,
      },
    },
  ),
)

const kpiTotals = computed<MatchmakerDashboardKPITotals>(() =>
  matchmakerData.value.reduce<MatchmakerDashboardKPITotals>(
    (agg, curr) => {
      if (curr.adminUser.mainRole === UserRoleEnum.Helpdesker || !agg.targets) {
        return agg
      }

      const kpis = curr.kpis

      agg.matchRequests[MatchRequestStatusCamelCase.Forwarded] +=
        countMatchRequestKpisForStatuses(curr, [
          MatchRequestStatusCamelCase.Forwarded,
          MatchRequestStatusCamelCase.FollowUp,
          MatchRequestStatusCamelCase.Cancelled,
          MatchRequestStatusCamelCase.NotVulnerable,
        ])

      agg.matchRequests[MatchRequestStatusCamelCase.Suggested] +=
        countMatchRequestKpisForStatuses(curr, [MatchRequestStatusCamelCase.Suggested])
      agg.matchRequests[MatchRequestStatusCamelCase.Match] +=
        countMatchRequestKpisForStatuses(curr, [MatchRequestStatusCamelCase.Match])

      agg.contactMoments += kpis.contactMoments

      Object.keys(agg.targets).forEach((k) => {
        const key = k as MatchmakerKpiTarget
        agg.targets![key] += kpis.targets?.[key] ?? 0
      })

      agg.totalMatchTargets.bronze += Math.floor(
        (kpis.targets?.endOfWeek ?? 0) * bronzeMatchmakerKpiMultiplier,
      )
      agg.totalMatchTargets.silver += Math.floor(
        (kpis.targets?.endOfWeek ?? 0) * silverMatchmakerKpiMultiplier,
      )
      agg.totalMatchTargets.gold += Math.floor(
        (kpis.targets?.endOfWeek ?? 0) * goldMatchmakerKpiMultiplier,
      )

      return agg
    },
    {
      matchRequests: {
        [MatchRequestStatusCamelCase.Forwarded]: 0,
        [MatchRequestStatusCamelCase.Suggested]: 0,
        [MatchRequestStatusCamelCase.Match]: 0,
      },
      contactMoments: 0,
      targets: {
        intakes: 0,
        suggested: 0,
        matches: 0,
        endOfWeek: 0,
        contactMoments: 0,
        passedWorkDays: 0,
      },
      totalMatchTargets: {
        bronze: 0,
        silver: 0,
        gold: 0,
      },
    },
  ),
)

const focusMunicipalities = computed<string[]>(() => {
  if (!data.value) {
    return []
  }

  return Object.keys(data.value.targetStatusHistory)
})

const totalSuggestedAndMatchedTexts = computed(() => {
  if (!data.value?.data) {
    return {
      [MatchRequestStatusCamelCase.Suggested]: {
        text: 'Geen mensen voorgesteld',
        inFocus: 0,
        inNation: 0,
      },
      [MatchRequestStatusCamelCase.Match]: {
        text: 'Geen matches gemaakt',
        inFocus: 0,
        inNation: 0,
      },
    }
  }

  const suggestedMatchRequests = uniq(
    matchmakerData.value.flatMap(
      (matchmaker) =>
        matchmaker.kpis.matchRequests?.[MatchRequestStatusCamelCase.Suggested]?.map(
          (mr) => mr.fullName,
        ) ?? [],
    ),
  )
  const suggestedNames = suggestedMatchRequests.join(', ')

  let suggestedText: string
  if (suggestedNames.length === 0) {
    suggestedText = 'Geen mensen voorgesteld'
  } else {
    suggestedText = `Voorgesteld: ${suggestedNames}`
  }

  const suggestedInFocus = sumBy(matchmakerData.value, (matchmaker) =>
    getNumberOfMatchRequestsInFocusMunicipalities(
      matchmaker,
      MatchRequestStatusCamelCase.Suggested,
      focusMunicipalities.value,
    ),
  )
  const suggestedInNation = suggestedMatchRequests.length - suggestedInFocus

  const matchedMatchRequests = uniq(
    matchmakerData.value.flatMap(
      (matchmaker) =>
        matchmaker.kpis.matchRequests?.[MatchRequestStatusCamelCase.Match]?.map(
          (mr) => mr.fullName,
        ) ?? [],
    ),
  )
  const matchedNames = matchedMatchRequests.join(', ')

  let matchedText: string
  if (matchedNames.length === 0) {
    matchedText = 'Geen matches gemaakt'
  } else {
    matchedText = `Gematched: ${matchedNames}`
  }

  const matchedInFocus = sumBy(matchmakerData.value, (matchmaker) =>
    getNumberOfMatchRequestsInFocusMunicipalities(
      matchmaker,
      MatchRequestStatusCamelCase.Match,
      focusMunicipalities.value,
    ),
  )
  const matchedInNation = matchedMatchRequests.length - matchedInFocus

  return {
    [MatchRequestStatusCamelCase.Suggested]: {
      text: suggestedText,
      inFocus: suggestedInFocus,
      inNation: suggestedInNation,
    },
    [MatchRequestStatusCamelCase.Match]: {
      text: matchedText,
      inFocus: matchedInFocus,
      inNation: matchedInNation,
    },
  }
})

const getMatchmakerKPIs = async () => {
  isPastData.value = dayjs().startOf('week').isAfter(startDate.value)

  isLoading.value = true
  const result = await dashboardStore.getMatchmakerKpisDashboardData(
    startDate.value,
    endDate.value,
  )
  isLoading.value = false

  if (result) {
    data.value = result
  }
}

const makeWorkdaySnapshot = async () => {
  isLoading.value = true
  await dashboardStore.createMatchmakersDashboardSnapshot()
  isLoading.value = false
}

const getWorkDays = (
  adminUser: SpecificMatchmakerKpiDashboardResource,
): IsoDayNumber[] => {
  return adminUser.workdays
}

const toggleWorkDay = async (
  adminUser: SpecificMatchmakerKpiDashboardResource,
  column: ColumnKey,
) => {
  const day = transformKeyToDayNumber(column)

  if (!day) {
    return
  }

  const workdays = getWorkDays(adminUser)

  const index = workdays.indexOf(day)

  if (index === -1) {
    workdays.push(day)
    workdays.sort()
  } else {
    workdays.splice(index, 1)
  }

  await storeWorkdays(adminUser, workdays)
}

const createDebouncedStoreWorkdays = function () {
  return debounce(
    async (
      adminUser: SpecificMatchmakerKpiDashboardResource,
      workdays: IsoDayNumber[],
    ) => {
      await adminUserStore.updateSpecificAdminUserWorkdaysWithKey(
        adminUser.adminUser.id,
        {
          key:
            adminUser.adminUser.mainRole === UserRoleEnum.Helpdesker
              ? 'helpdesker'
              : 'matchmaker',
          days: workdays,
        },
      )
    },
    1000,
    { leading: false, trailing: true },
  )
}

let previousAdminUser: SpecificMatchmakerKpiDashboardResource | null = null
let debouncedUpdate: ReturnType<typeof createDebouncedStoreWorkdays> | null = null

const storeWorkdays = async (
  adminUser: SpecificMatchmakerKpiDashboardResource,
  workdays: IsoDayNumber[],
) => {
  if (!debouncedUpdate) {
    debouncedUpdate = createDebouncedStoreWorkdays()
  }

  if (adminUser.adminUser.id !== previousAdminUser?.adminUser.id) {
    debouncedUpdate?.flush()
    debouncedUpdate = createDebouncedStoreWorkdays()
  }

  previousAdminUser = adminUser
  await debouncedUpdate(adminUser, workdays)
}

const getMatchRequestNames = (
  data: SpecificMatchmakerKpiDashboardResource,
  status: MatchmakerKPIMatchRequestStatusCamelCase,
) => {
  if (!data.kpis.matchRequests?.[status]) {
    return []
  }

  return data.kpis.matchRequests[status].map((kpi) => kpi.fullName).join(', ')
}

const copyMatchRequestNamesToClipboard = (
  data: SpecificMatchmakerKpiDashboardResource,
  status: MatchmakerKPIMatchRequestStatusCamelCase,
) => {
  const names = data.kpis.matchRequests?.[status]?.map((kpi) => kpi.fullName).join(', ')

  if (!names) {
    return
  }

  copyStringToClipBoard(names)
    .then(() => toast.success(`Namen gekopieerd`))
    .catch(() => toast.error('Het kopiëren van de namen is niet gelukt.'))
}

const getDayClass = (
  adminUser: SpecificMatchmakerKpiDashboardResource,
  day: ColumnKey,
) => {
  const baseClass = isLeadMatchmaker.value ? 'cursor-pointer' : ''

  const dayNumber = transformKeyToDayNumber(day)
  if (!dayNumber) {
    return ''
  }

  const foundWorkdays = getWorkDays(adminUser)

  if (!foundWorkdays) {
    return baseClass
  }

  const dayIsActiveClass = foundWorkdays.includes(dayNumber)
    ? 'bg-success !text-success-text'
    : 'bg-danger !text-danger-text'

  const isDisabled = isPastData.value ? 'is-disabled' : ''

  return `my-1 ${dayClasses} ${baseClass} ${dayIsActiveClass} ${isDisabled}`
}

const getDayIcon = (
  adminUser: SpecificMatchmakerKpiDashboardResource,
  day: ColumnKey,
): FAIconName => {
  const dayNumber = transformKeyToDayNumber(day)
  if (!dayNumber) {
    return 'fa-x'
  }

  const workdays = getWorkDays(adminUser)
  return workdays.includes(dayNumber) ? 'fa-check' : 'fa-x'
}

const formatStatistics = (
  statistics: MatchmakerDashboardStatistics,
  key: MatchmakerDashboardStatisticsKey,
) => {
  const value = statistics.values?.[key].count

  return formatMatchmakerDashboardStatistics(
    value,
    statistics.targets?.[key],
    showPercentages.value,
  )
}

const getStatisticsCellClass = (
  statistics: MatchmakerDashboardStatistics | null | undefined,
  column: 'open' | 'currentWeek' | 'suggested',
) => {
  const currentCount = statistics?.values?.[column].count
  const maxCount = statistics?.targets?.[column]

  return `cursor-pointer !text-black ${getMatchmakerStatisticsCellClass(currentCount, maxCount)}`
}

const getStatisticsTotalsCellClass = (column: 'open' | 'currentWeek' | 'suggested') => {
  const baseClass = 'cursor-pointer !text-black justify-center'

  if (!statisticsTotals.value?.values || !statisticsTotals.value?.targets) {
    return baseClass
  }

  const currentCount = statisticsTotals.value.values[column].count
  const maxCount = statisticsTotals.value.targets[column]

  if (currentCount >= maxCount) {
    return `${baseClass} bg-danger-200`
  } else if (currentCount > maxCount * 0.8) {
    return `${baseClass} bg-warning-200`
  } else {
    return `${baseClass} bg-success-200`
  }
}

const copyToClipboard = (names?: string) => {
  if (names) {
    copyStringToClipBoard(names)
      .then(() => toast.success(`Namen gekopieerd`))
      .catch(() => toast.error('Het kopiëren van de namen is niet gelukt.'))
  }
}

const { modalIsOpen, openModal } = useModal({
  open: (adminUser: SpecificMatchmakerKpiDashboardResource) => {
    currentUser.value = adminUser.adminUser
  },
  onClose: () => {
    currentUser.value = undefined
  },
})

onMounted(getMatchmakerKPIs)

type ColumnKey =
  | 'name'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'gap'
  | 'currentOpen'
  | 'currentWeek'
  | 'currentSuggested'
  | 'municipalities'
  | MatchRequestStatusCamelCase.Forwarded
  | MatchRequestStatusCamelCase.Suggested
  | MatchRequestStatusCamelCase.Match
  | 'contactMoment'

const dayClasses = 'max-w-8'
const todayClasses = `${dayClasses} !font-bold !text-base`

const today = dayjsUTC().locale('en').format('dddd').toLowerCase()
const columns = computed<
  VTableColumns<ColumnKey, SpecificMatchmakerKpiDashboardResource>
>(() => {
  const baseColumns: VTableColumn<ColumnKey, SpecificMatchmakerKpiDashboardResource>[] = [
    {
      key: 'name',
      label: 'Naam',
      cellClass: 'cursor-pointer',
      onClick: (row) => {
        openModal(row)
      },
    },
    {
      key: 'monday',
      label: 'Ma.',
      headerClass: (column) => (column.key === today ? todayClasses : dayClasses),
      preRowClass: dayClasses,
      cellClass: (row, column) => getDayClass(row, column.key),
      align: 'center',
      skipForPlaceholder: true,
      onClick: toggleWorkDay,
    },
    {
      key: 'tuesday',
      label: 'Di.',
      headerClass: (column) => (column.key === today ? todayClasses : dayClasses),
      preRowClass: dayClasses,
      cellClass: (row, column) => getDayClass(row, column.key),
      align: 'center',
      skipForPlaceholder: true,
      onClick: toggleWorkDay,
    },
    {
      key: 'wednesday',
      label: 'Wo.',
      headerClass: (column) => (column.key === today ? todayClasses : dayClasses),
      preRowClass: dayClasses,
      cellClass: (row, column) => getDayClass(row, column.key),
      align: 'center',
      skipForPlaceholder: true,
      onClick: toggleWorkDay,
    },
    {
      key: 'thursday',
      label: 'Do.',
      headerClass: (column) => (column.key === today ? todayClasses : dayClasses),
      preRowClass: dayClasses,
      cellClass: (row, column) => getDayClass(row, column.key),
      align: 'center',
      skipForPlaceholder: true,
      onClick: toggleWorkDay,
    },
    {
      key: 'friday',
      label: 'Vr.',
      headerClass: (column) => (column.key === today ? todayClasses : dayClasses),
      preRowClass: dayClasses,
      cellClass: (row, column) => getDayClass(row, column.key),
      align: 'center',
      skipForPlaceholder: true,
      onClick: toggleWorkDay,
    },
  ]

  if (showKPIs.value) {
    baseColumns.push(
      ...([
        {
          key: MatchRequestStatusCamelCase.Forwarded,
          label: 'Intakes',
          align: 'center',
        },
        {
          key: MatchRequestStatusCamelCase.Suggested,
          label: 'Voorgesteld',
          align: 'center',
        },
        {
          key: MatchRequestStatusCamelCase.Match,
          label: 'Matches',
          align: 'center',
          cellClass: (row) => {
            if (row.adminUser.mainRole === UserRoleEnum.Helpdesker) {
              return
            }
            return getMatchKpiColorClass(
              countMatchRequestKpisForStatuses(row, [MatchRequestStatusCamelCase.Match]),
              row.kpis.targets?.passedWorkDays,
            )
          },
          preRowClass: () => {
            const baseClass = 'font-medium'

            const totalValue =
              kpiTotals.value.matchRequests?.[MatchRequestStatusCamelCase.Match]
            const targets = kpiTotals.value?.totalMatchTargets
            if (totalValue == null || !targets) {
              return `${baseClass} bg-success-200`
            }

            if (totalValue >= targets.gold) {
              return `${baseClass} bg-gold`
            } else if (totalValue >= targets.silver) {
              return `${baseClass} bg-silver`
            } else if (totalValue >= targets.bronze) {
              return `${baseClass} bg-bronze`
            } else if (totalValue >= targets.bronze - 1) {
              return `${baseClass} bg-warning-200`
            } else {
              return `${baseClass} bg-danger-200`
            }
          },
        },
        {
          key: 'contactMoment',
          label: 'Contactmomenten',
          align: 'center',
        },
      ] satisfies VTableColumn<ColumnKey, SpecificMatchmakerKpiDashboardResource>[]),
    )
  } else {
    baseColumns.push(
      ...([
        {
          key: 'gap',
          label: '',
          widthClass: 'max-w-1',
        },
        {
          key: 'currentOpen',
          label: 'Open',
          preRowClass: () => getStatisticsTotalsCellClass('open'),
          cellClass: (row) => getStatisticsCellClass(row.statistics, 'open'),
          align: 'center',
        },
        {
          key: 'currentWeek',
          label: 'Huidig',
          preRowClass: () => getStatisticsTotalsCellClass('currentWeek'),
          cellClass: (row) => getStatisticsCellClass(row.statistics, 'currentWeek'),
          align: 'center',
        },
        {
          key: 'currentSuggested',
          label: 'Voorgesteld',
          preRowClass: () => getStatisticsTotalsCellClass('suggested'),
          cellClass: (row) => getStatisticsCellClass(row.statistics, 'suggested'),
          align: 'center',
        },
        {
          key: 'municipalities',
          label: 'Gemeentes',
          widthClass: 'w-1/3',
        },
      ] satisfies VTableColumn<ColumnKey, SpecificMatchmakerKpiDashboardResource>[]),
    )
  }

  return baseColumns
})
</script>

<template>
  <div>
    <div class="columns items-center">
      <div class="column narrow">
        <WeekNumberInput v-model="weekNumberInput" @enter="getMatchmakerKPIs" />
      </div>

      <div class="column is-2 px-1">
        <VButton
          color="success"
          width="full"
          icon-right="fa-download"
          :loading="isLoading"
          @click="getMatchmakerKPIs"
        >
          Ophalen
        </VButton>
      </div>
      <div class="column is-2 px-1">
        <VButton
          color="warning"
          width="full"
          :icon-left="SaveIcon"
          :loading="isLoading"
          :disabled="!!data"
          @click="makeWorkdaySnapshot"
        >
          Opslaan
        </VButton>
      </div>
    </div>

    <VTable :data="matchmakerData" :columns :loading="isLoading">
      <template #toolbar-left>
        <div v-if="isLeadMatchmaker" class="columns" :style="{ width: '40vw' }">
          <div class="column half">
            <AdminUserFilter
              v-model="teamLeadFilterId"
              label="Filter op team"
              :type="UserRoleEnum.LeadMatchmaker"
              color="info"
            />
          </div>
          <div class="column half">
            <VField label="Sorteer alfabetisch?">
              <VSwitchBlock
                v-model="sortAlphabetically"
                name="sort-alphabetically"
                left-label="Nee"
                right-label="Ja"
              />
            </VField>
          </div>
        </div>
      </template>
      <template #toolbar-right>
        <div class="columns w-full items-end justify-end">
          <div class="column narrow !flex justify-end">
            <VSwitchBlock
              v-model="showPercentages"
              name="percentage-switch-matchmaker-kpi-dashboard"
              left-label="Absoluut"
              right-label="Percentage"
              color="info"
            />
          </div>
          <div class="column narrow !flex justify-end">
            <VSwitchBlock
              v-model="showKPIs"
              name="show-results-switch-block"
              left-label="Huidig"
              right-label="KPIs"
              color="info"
            />
          </div>
        </div>
      </template>

      <template #pre-body-cell="{ column }">
        <template v-if="column.key === 'name'">
          <span class="w-full text-left">
            <span class="font-semibold"> Totaal </span>
          </span>
        </template>

        <template v-else-if="column.key === 'currentOpen'">
          <span class="text-base">
            {{ formatStatistics(statisticsTotals, 'open') }}
          </span>
        </template>

        <template v-else-if="column.key === 'currentWeek'">
          <span class="text-base">
            {{ formatStatistics(statisticsTotals, 'currentWeek') }}
          </span>
        </template>

        <template v-else-if="column.key === 'currentSuggested'">
          <span class="text-base">
            {{ formatStatistics(statisticsTotals, 'suggested') }}
          </span>
        </template>

        <template v-else-if="column.key === MatchRequestStatusCamelCase.Forwarded">
          <span>
            {{
              formatMatchmakerKpi(
                kpiTotals.matchRequests?.[MatchRequestStatusCamelCase.Forwarded],
                kpiTotals.targets?.intakes,
                showPercentages,
              )
            }}
          </span>
        </template>
        <template v-else-if="column.key === MatchRequestStatusCamelCase.Suggested">
          <VMenu placement="left">
            <template #popper>
              <span
                @click.prevent="
                  copyToClipboard(
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                      .text,
                  )
                "
                @keyup.prevent="
                  copyToClipboard(
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                      .text,
                  )
                "
              >
                <span class="font-semibold">
                  Totaal lokaal voorgesteld:
                  {{
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                      .inFocus
                  }}
                  <br />
                  Totaal landelijk voorgesteld:
                  {{
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                      .inNation
                  }}
                </span>
                <br />
                {{
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                    .text
                }}
              </span>
            </template>
            <span
              class="is-match-span"
              @click.prevent="
                copyToClipboard(
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                    .text,
                )
              "
              @keyup.prevent="
                copyToClipboard(
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Suggested]
                    .text,
                )
              "
              >{{
                formatMatchmakerKpi(
                  kpiTotals.matchRequests?.[MatchRequestStatusCamelCase.Suggested],
                  kpiTotals.targets?.suggested,
                  showPercentages,
                )
              }}
            </span>
          </VMenu>
        </template>
        <template v-else-if="column.key === MatchRequestStatusCamelCase.Match">
          <VMenu placement="left">
            <template #popper>
              <span
                @click.prevent="
                  copyToClipboard(
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match].text,
                  )
                "
                @keyup.prevent="
                  copyToClipboard(
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match].text,
                  )
                "
              >
                <span class="font-semibold">
                  Totaal lokaal gematched:
                  {{
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match]
                      .inFocus
                  }}
                  <br />
                  Totaal landelijk gematched:
                  {{
                    totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match]
                      .inNation
                  }}
                </span>
                <br />
                {{
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match].text
                }}
              </span>
            </template>
            <span
              v-if="kpiTotals.targets"
              class="is-match-span text-black"
              @click.prevent="
                copyToClipboard(
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match].text,
                )
              "
              @keyup.prevent="
                copyToClipboard(
                  totalSuggestedAndMatchedTexts[MatchRequestStatusCamelCase.Match].text,
                )
              "
            >
              {{
                formatMatchmakerKpi(
                  kpiTotals.matchRequests[MatchRequestStatusCamelCase.Match],
                  kpiTotals.targets.matches,
                  showPercentages,
                )
              }}
              ({{ Math.floor(kpiTotals.totalMatchTargets.bronze) }}/{{
                Math.floor(kpiTotals.totalMatchTargets.silver)
              }}/{{ Math.floor(kpiTotals.totalMatchTargets.gold) }})
            </span>
          </VMenu>
        </template>

        <template v-else-if="column.key === 'contactMoment'">
          <span>
            {{
              formatMatchmakerKpi(
                kpiTotals.contactMoments,
                kpiTotals.targets?.contactMoments,
                showPercentages,
              )
            }}
          </span>
        </template>
      </template>

      <template #body-cell="{ row, column }">
        <template v-if="column.key === 'name'">
          <div class="flex w-full items-center justify-between">
            <div class="flex flex-col">
              <span class="font-semibold">
                {{ row.adminUser.firstname }}
              </span>
              <span
                v-if="
                  (!teamLeadFilterId || teamLeadFilterId < 0) && row.adminUser.leadUserId
                "
              >
                Lead:
                {{ adminUserStore.getAdminUser(row.adminUser.leadUserId)?.firstname }}
              </span>
              <span v-if="row.adminUser.awayFrom && row.adminUser.awayUntil">
                Afwezig
                {{
                  formatLocalizedDatePeriod(
                    row.adminUser.awayFrom,
                    row.adminUser.awayUntil,
                  )
                }}
              </span>
              <template v-else>
                <span v-if="row.adminUser.awayFrom">
                  Afwezig vanaf: {{ formatLocalizedDate(row.adminUser.awayFrom) }}
                </span>
                <span v-if="row.adminUser.awayUntil">
                  Afwezig tot: {{ formatLocalizedDate(row.adminUser.awayUntil) }}
                </span>
              </template>

              <template v-if="isLeadMatchmaker && isPastData && row.targetModifier !== 1">
                Weging: {{ row.targetModifier }}
              </template>
            </div>
            <VIconBox
              :icon="row.adminUser.lockWorkdays ? 'fa-lock' : 'fa-lock-open'"
              size="small"
              color="info"
            />
          </div>
        </template>
        <template v-if="column.key === 'monday'">
          <VIcon :icon="getDayIcon(row, column.key)" />
        </template>
        <template v-else-if="column.key === 'tuesday'">
          <VIcon :icon="getDayIcon(row, column.key)" />
        </template>
        <template v-else-if="column.key === 'wednesday'">
          <VIcon :icon="getDayIcon(row, column.key)" />
        </template>
        <template v-else-if="column.key === 'thursday'">
          <VIcon :icon="getDayIcon(row, column.key)" />
        </template>
        <template v-else-if="column.key === 'friday'">
          <VIcon :icon="getDayIcon(row, column.key)" />
        </template>

        <template v-else-if="column.key === 'currentOpen'">
          <span v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            {{ formatStatistics(row.statistics, 'open') }}
          </span>
          <span v-else>-</span>
        </template>
        <template v-else-if="column.key === 'currentWeek'">
          <span v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            {{ formatStatistics(row.statistics, 'currentWeek') }}
          </span>
          <span v-else>-</span>
        </template>
        <template v-else-if="column.key === 'currentSuggested'">
          <span v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            {{ formatStatistics(row.statistics, 'suggested') }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-else-if="column.key === 'municipalities'">
          <template v-if="row.adminUser.municipalities.length === 0"> Geen</template>
          <span v-else>
            <template v-for="(municipality, index) in row.adminUser.municipalities">
              <HeadlessFocusMunicipalityStatus :municipality="municipality">
                <template #default="{ municipality, statusTailwindColor, isFocus }">
                  <span
                    class="municipality-status rounded px-1"
                    :class="[
                      isFocus && `is-focus bg-${statusTailwindColor}-200`,
                      statusTailwindColor && 'text-black',
                    ]"
                    >{{ toCapitalizedWords(municipality) }}</span
                  >
                </template>
              </HeadlessFocusMunicipalityStatus>
              <template v-if="index < row.adminUser.municipalities.length - 1"
                >,
              </template>
            </template>
          </span>
        </template>

        <template v-else-if="column.key === MatchRequestStatusCamelCase.Forwarded">
          <VTooltip v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            <template #popper>
              <div class="prose">
                <ul class="mt-0">
                  <li>
                    Uitgezet:
                    {{
                      row.kpis.matchRequests?.[MatchRequestStatusCamelCase.Forwarded]
                        ?.length ?? 0
                    }}
                  </li>
                  <li>
                    Nabellen:
                    {{
                      row.kpis.matchRequests?.[MatchRequestStatusCamelCase.FollowUp]
                        ?.length ?? 0
                    }}
                  </li>
                  <li>
                    Geannuleerd:
                    {{
                      row.kpis.matchRequests?.[MatchRequestStatusCamelCase.Cancelled]
                        ?.length ?? 0
                    }}
                  </li>
                  <li>
                    Niet kwetsbaar:
                    {{
                      row.kpis.matchRequests?.[MatchRequestStatusCamelCase.NotVulnerable]
                        ?.length ?? 0
                    }}
                  </li>
                </ul>
              </div>
            </template>
            <span class="is-match-span">
              {{
                getMatchRequestKpisForStatuses(
                  row,
                  [
                    MatchRequestStatusCamelCase.Forwarded,
                    MatchRequestStatusCamelCase.FollowUp,
                    MatchRequestStatusCamelCase.Cancelled,
                    MatchRequestStatusCamelCase.NotVulnerable,
                  ],
                  row.kpis.targets?.intakes,
                  showPercentages,
                )
              }}
            </span>
          </VTooltip>
        </template>
        <template v-else-if="column.key === MatchRequestStatusCamelCase.Suggested">
          <VTooltip v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            <template #popper>
              <template
                v-if="
                  row.kpis.matchRequests?.[MatchRequestStatusCamelCase.Suggested]?.length
                "
              >
                Mensen voorgesteld:
                {{ getMatchRequestNames(row, MatchRequestStatusCamelCase.Suggested) }}
                <template v-if="focusMunicipalities.length > 0">
                  <br />
                  <br />
                  Lokaal mensen voorgesteld:
                  {{
                    getNumberOfMatchRequestsInFocusMunicipalities(
                      row,
                      MatchRequestStatusCamelCase.Suggested,
                      focusMunicipalities,
                    )
                  }}
                  <br />
                  Landelijk mensen voorgesteld:
                  {{
                    getNumberOfMatchRequestsInNation(
                      row,
                      MatchRequestStatusCamelCase.Suggested,
                      focusMunicipalities,
                    )
                  }}
                </template>
              </template>
              <template v-else> Niemand voorgesteld.</template>
            </template>
            <span
              class="is-match-span cursor-pointer"
              @click="
                copyMatchRequestNamesToClipboard(
                  row,
                  MatchRequestStatusCamelCase.Suggested,
                )
              "
              @keyup="
                copyMatchRequestNamesToClipboard(
                  row,
                  MatchRequestStatusCamelCase.Suggested,
                )
              "
            >
              {{
                getMatchRequestKpisForStatuses(
                  row,
                  [MatchRequestStatusCamelCase.Suggested],
                  row.kpis.targets?.suggested,
                  showPercentages,
                )
              }}
            </span>
          </VTooltip>
        </template>

        <template v-else-if="column.key === MatchRequestStatusCamelCase.Match">
          <VTooltip v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            <template #popper>
              <template
                v-if="row.kpis.matchRequests?.[MatchRequestStatusCamelCase.Match]?.length"
              >
                Matches gemaakt voor:
                {{ getMatchRequestNames(row, MatchRequestStatusCamelCase.Match) }}
                <template v-if="focusMunicipalities.length > 0">
                  <br />
                  <br />
                  Lokaal mensen gematched:
                  {{
                    getNumberOfMatchRequestsInFocusMunicipalities(
                      row,
                      MatchRequestStatusCamelCase.Match,
                      focusMunicipalities,
                    )
                  }}
                  <br />
                  Landelijk mensen gematched:
                  {{
                    getNumberOfMatchRequestsInNation(
                      row,
                      MatchRequestStatusCamelCase.Match,
                      focusMunicipalities,
                    )
                  }}
                </template>
              </template>
              <template v-else> Geen matches gemaakt.</template>
            </template>
            <span
              class="is-match-span text-black"
              @click="
                copyMatchRequestNamesToClipboard(row, MatchRequestStatusCamelCase.Match)
              "
              @keyup="
                copyMatchRequestNamesToClipboard(row, MatchRequestStatusCamelCase.Match)
              "
            >
              {{
                getMatchRequestKpisForStatuses(
                  row,
                  [MatchRequestStatusCamelCase.Match],
                  row.kpis.targets?.matches,
                  showPercentages,
                )
              }}
              <template v-if="row.kpis.targets">
                ({{
                  Math.floor(row.kpis.targets.endOfWeek * bronzeMatchmakerKpiMultiplier)
                }}/{{
                  Math.floor(row.kpis.targets.endOfWeek * silverMatchmakerKpiMultiplier)
                }}/{{
                  Math.round(row.kpis.targets.endOfWeek * goldMatchmakerKpiMultiplier)
                }})
              </template>
            </span>
          </VTooltip>
        </template>

        <template v-else-if="column.key === 'contactMoment'">
          <span v-if="row.adminUser.mainRole === UserRoleEnum.Matchmaker">
            {{
              formatMatchmakerKpi(
                row.kpis.contactMoments,
                row.kpis.targets?.contactMoments,
                showPercentages,
              )
            }}
          </span>
        </template>
      </template>
    </VTable>

    <AdminUserSettingsModal v-model:open="modalIsOpen" :admin-user="currentUser" />
  </div>
</template>

<style scoped>
.is-match-span {
  display: inline-block;
  cursor: pointer;
}

:deep(.right) {
  width: 40%;
}
</style>
