<script setup lang="ts">
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { z } from 'zod'
import { type Dayjs } from 'dayjs'
import { standardRatings } from '/@src/mapping/elements-ui'
import { useAuthStore } from '/@src/stores/auth'
import type { AdminUserEnpsAnswerParams } from '/@src/types/admin-users'
import { dayjsUTC } from '/@src/utils/date-formatter'

type EnpsModalEmits = {
  close: []
}

const emits = defineEmits<EnpsModalEmits>()

const isOpen = defineModel<boolean>('open', { required: true })

const today = dayjsUTC()
let startOfLastWeekOfMonth: Dayjs
// If the first day of the month is not the first day of the week, we need to do some extra calculations
if (today.date() < 7 && today.startOf('month').isoWeekday() !== 1) {
  startOfLastWeekOfMonth = today
    .clone()
    .month(today.month() - 1)
    .endOf('month')
    .startOf('week')
} else {
  startOfLastWeekOfMonth = today.clone().endOf('month').startOf('week')
}
const firstWeekOfNextMonth = today
  .clone()
  .isoWeek(startOfLastWeekOfMonth.isoWeek() + 1)
  .startOf('week')
const showEndOfMonthQuestion =
  today.isSameOrAfter(startOfLastWeekOfMonth) &&
  today.isSameOrBefore(firstWeekOfNextMonth)

const hasRetrievedAnswer = ref(false)
const hasAlreadyAnswered = ref(false)
const isLoading = ref(false)

const adminUserStore = useAdminUsersStore()
const authStore = useAuthStore()

const { handleSubmit, meta, resetForm, setFieldValue, useField, formProps } =
  useTypedForm<AdminUserEnpsAnswerParams>({
    id: 'enps',
    schema: {
      answer: z.number(),
      endOfMonthAnswer: z.number().nullable(),
    },
  })

const { setErrors } = useField('endOfMonthAnswer')

const retrieveExistingAnswer = async () => {
  isLoading.value = true
  const existingAnswer = await adminUserStore.getEnpsAnswer()
  isLoading.value = false
  hasRetrievedAnswer.value = true

  if (existingAnswer !== null) {
    setFieldValue('answer', existingAnswer.answer)
    setFieldValue('endOfMonthAnswer', existingAnswer.endOfMonthAnswer)
    hasAlreadyAnswered.value = true
  }

  return existingAnswer
}

whenever(isOpen, retrieveExistingAnswer)

const onSubmit = handleSubmit(async (values) => {
  if (showEndOfMonthQuestion && !values.endOfMonthAnswer) {
    setErrors('Required')
    return
  }

  isLoading.value = true
  const result = await adminUserStore.updateEnps(values)
  isLoading.value = false

  if (result) {
    resetForm({
      values: values,
    })

    closeModal()
  }
})

const closeModal = () => {
  if (
    meta.value.dirty &&
    !confirm(
      'Antwoord is nog niet opgeslagen, weet je zeker dat je de pop-up wilt sluiten?',
    )
  ) {
    return
  }
  emits('close')
  isOpen.value = false
}

const checkForFinalWorkWeekDay = async () => {
  const result = await retrieveExistingAnswer()

  // If no answer has been given, check if today is the final workday in the week and open the modal if it is
  if (!result?.answer && adminUserStore.adminUser?.workdays) {
    const workdays = new Set(Object.values(adminUserStore.adminUser.workdays).flat())
    const finalWorkday = Math.max(...workdays)

    const today = dayjsUTC().isoWeekday()

    if (finalWorkday === today && !authStore.adminUser) {
      isOpen.value = true
    }
  }
}

watch(() => adminUserStore.adminUser?.workdays, checkForFinalWorkWeekDay)
onMounted(() => {
  if (isOpen.value) {
    checkForFinalWorkWeekDay()
  }
})
</script>

<template>
  <VModal v-model:open="isOpen" title="e-NPS" @close="closeModal">
    <template #content>
      <VCard>
        <template #icon>
          <VIcon icon="fa-square-poll-vertical" size="large" />
        </template>
        <template #content>
          <FormWrapper v-bind="formProps" v-slot="{ k }">
            <div class="prose text-center">
              We willen graag weten hoe het met je gaat! Sta even stil bij wat je deze
              week hebt gedaan en hoe dat voelt.
              <br />
              <br />
              Welk cijfer geef jij jouw afgelopen werkweek?
            </div>
            <FormRating :name="k.answer" label="Antwoord" :tabs="standardRatings" />

            <template v-if="showEndOfMonthQuestion">
              <VDivider />

              <div class="prose text-center">
                Aan het eind van elke maand willen we je ook graag de volgende vraag
                stellen:
                <br />
                <br />
                Hoe waarschijnlijk is het dat je Thuisgekookt als werkgever aan anderen
                zou aanbevelen?
              </div>

              <FormRating
                :name="k.endOfMonthAnswer"
                label="Antwoord"
                :tabs="standardRatings"
              />
            </template>
          </FormWrapper>
        </template>
      </VCard>
    </template>
    <template #actions>
      <VButton
        color="success"
        :loading="isLoading"
        icon-right="fa-check"
        :disabled="!meta.valid"
        @click.prevent="onSubmit"
      >
        Opslaan
      </VButton>
    </template>
  </VModal>
</template>

<style scoped></style>
