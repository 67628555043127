<script setup lang="ts">
import { calculateContrast } from '/@src/utils/helpers'
import { useTagsStore } from '/@src/stores/tags'
import { useModal } from '/@src/composable/useModal'
import type { Tag } from '/@src/types/tags'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { useWebSocketModel } from '/@src/composable/useWebSocket'

definePage({
  meta: {
    roles: ['manager', 'impact-accelerator', 'marketing', 'lead-matchmaker'],
  },
})

const tagStore = useTagsStore()

const tag = ref<Tag>()

const updateTag = async (event: WebSocketsModelReply<Tag>) => {
  const currentTag = availableTags.value.find((tag) => {
    return tag.id === event.model.id
  })

  if (currentTag) {
    updateModel(currentTag, event.model)
  }
}

const { setModels } = useWebSocketModel<Tag>({
  baseChannel: 'tg-admin-channel-tag',
  event: '.TagUpdated',
  callback: updateTag,
})

const availableTags = computed(() => tagStore.tags)

const {
  modalIsOpen: tagModalIsOpen,
  openModal: openTagModal,
  closeModal: closeTagModal,
} = useModal({
  open: (selectedTag: Tag) => {
    tag.value = selectedTag
  },
  close: () => {
    tag.value = undefined
  },
})

onMounted(async () => {
  await tagStore.getTags()
  setModels(availableTags.value)
})
</script>

<template>
  <div>
    <VCard title="Tag toevoegen">
      <template #content>
        <NewTagForm />
      </template>
    </VCard>

    <VCard title="Beschikbare tags:">
      <template #content>
        <VTag
          v-for="tagData in availableTags"
          :key="tagData.id"
          class="m-2"
          :string-color="tagData.color"
          rounded
          size="medium"
          :class="calculateContrast(tagData.color)"
          @click="openTagModal(tagData)"
          @keyup="openTagModal(tagData)"
        >
          {{ tagData.tag }}
        </VTag>
      </template>
    </VCard>

    <!--  MODAL  -->
    <TagModal
      v-if="tag"
      v-model:open="tagModalIsOpen"
      :tag="tag"
      @close="closeTagModal"
    />
  </div>
</template>

<style scoped></style>
