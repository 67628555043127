import type {
  DashboardLoadout,
  DashboardLoadoutId,
  DashboardSlot,
  DashboardWidgetKey,
  DashboardWidgetProps,
  DashboardWidgetTranslationDirection,
  LoadoutSettings,
  MatchmakerDashboardStatisticsKey,
  MatchmakerKPIMatchRequestStatusCamelCase,
  SpecificMatchmakerKpiDashboardResource,
} from '/@src/types/dashboard'
import { match } from 'ts-pattern'
import dayjs from 'dayjs'
import { UserRoleEnum } from '/@src/types/users'
import { dayjsUTC } from '/@src/utils/date-formatter'
import type { MinimalAdminUser } from '/@src/types/admin-users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { formatPercentage } from '/@src/utils/helpers'
import type { MaybeArray } from '/@src/types/utils'
import type { ExtendedTailwindColor } from '/@src/types/elements-ui'
import { camelCase, uniq } from 'lodash'
import { AdminTaskCategoryList } from '/@src/types/admin-tasks'

export const dashboardWidgetMap: Record<DashboardWidgetKey, Component> = {
  empty: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/EmptyDashboardWidget.vue'),
  ),
  'hidden-empty': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/EmptyDashboardWidget.vue'),
  ),
  'general-stats': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/GeneralStatsDashboardWidget.vue'),
  ),
  notes: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/NotesDashboardWidget.vue'),
  ),
  'global-search': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/GlobalSearchDashboardWidget.vue'),
  ),
  'global-bars': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/GlobalBarsDashboardWidget.vue'),
  ),
  'focus-municipalities': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/FocusMunicipalityDashboardWidget.vue'),
  ),
  'focus-municipality-changes': defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/FocusMunicipalityChangesDashboardWidget.vue'
      ),
  ),
  'admin-tasks': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/AdminTaskWidget.vue'),
  ),
  'average-ratings': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/AverageRatingsDashboardWidget.vue'),
  ),
  workdays: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/WorkdaysWidget.vue'),
  ),
  'new-cook-stats': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/NewCookStatsDashboardWidget.vue'),
  ),
  'open-match-request-stats': defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/OpenMatchRequestStatsDashboardWidget.vue'
      ),
  ),
  matchmakers: defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/MatchmakerAssignedMatchRequestsDashboardWidget.vue'
      ),
  ),
  'matchmaker-stats': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/MatchmakerStatsDashboardWidget.vue'),
  ),
  'matchmaker-kpis': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/MatchmakerKpisDashboardWidget.vue'),
  ),
  'active-match-requests': defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/ActiveMatchRequestsDashboardWidget.vue'
      ),
  ),
  'new-match-requests': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/NewMatchRequestsDashboardWidget.vue'),
  ),
  'waiting-list-match-requests': defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/WaitingListMatchRequestsDashboardWidget.vue'
      ),
  ),
  'helpdesk-kpis': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/HelpdeskKpisDashboardWidget.vue'),
  ),
  vogs: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/VogDashboardWidget.vue'),
  ),
  'contact-moments': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/ContactMomentsWidget.vue'),
  ),
  animals: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/AnimalsDashboardWidget.vue'),
  ),
}

export const getDashboardWidgetDefaultSettings = (
  key: DashboardWidgetKey | null | undefined,
): DashboardWidgetProps => {
  return match(key)
    .returnType<DashboardWidgetProps>()
    .with(
      'general-stats',
      'average-ratings',
      'focus-municipality-changes',
      'new-cook-stats',
      (value) => {
        let startDate = dayjs().subtract(1, 'week').startOf('week')
        let endDate = dayjs()
        if (value !== 'focus-municipality-changes') {
          startDate = dayjs().startOf('year')
        }

        return {
          componentKey: value,
          size: 'single',
          hidden: false,
          startDate: startDate,
          endDate: endDate,
        }
      },
    )
    .with(
      'focus-municipalities',
      'global-search',
      'new-match-requests',
      'notes',
      (value) => ({
        componentKey: value,
        size: 'single',
        hidden: false,
        stringInput: '',
      }),
    )
    .with('matchmakers', 'matchmaker-kpis', (value) => ({
      componentKey: value,
      size: 'single',
      hidden: false,
      userIdFilter: undefined,
    }))
    .with('vogs', () => ({
      componentKey: 'vogs',
      size: 'single',
      hidden: false,
      vogTab: 'new',
    }))
    .with('open-match-request-stats', (value) => {
      let startDate = dayjs().subtract(1, 'week').startOf('week')
      let endDate = dayjs()
      if (value === 'open-match-request-stats') {
        startDate = dayjs('2021-01-01')
        endDate = dayjs().subtract(1, 'year').endOf('year')
      }

      return {
        componentKey: value,
        size: 'single',
        hidden: false,
        startDate: startDate,
        endDate: endDate,
        userId: undefined,
      }
    })
    .with('waiting-list-match-requests', (value) => ({
      componentKey: value,
      size: 'single',
      hidden: false,
      booleanInput: false,
    }))
    .with('admin-tasks', 'helpdesk-kpis', (value) => {
      let category: string = AdminTaskCategoryList.NotAutomatic
      if (value === 'helpdesk-kpis') {
        category = 'thisWeek'
      }

      return {
        componentKey: value,
        size: 'single',
        hidden: false,
        category: category,
      }
    })
    .with('active-match-requests', (value) => {
      return {
        componentKey: value,
        size: 'single',
        hidden: false,
        category: 'inProgress',
        userIdFilter: undefined,
      }
    })
    .with('animals', () => ({
      componentKey: 'animals',
      size: 'single',
      hidden: false,
      animal: useAdminUsersStore().adminUser?.favoriteAnimal ?? 'cat',
    }))
    .with(
      'workdays',
      'global-bars',
      'contact-moments',
      'matchmaker-stats',
      'empty',
      'hidden-empty',
      null,
      undefined,
      (value) => ({
        componentKey: value ?? 'empty',
        size: 'single',
        hidden: false,
      }),
    )
    .exhaustive()
}

export interface DashboardWidgetInfo {
  key: DashboardWidgetKey
  title: string
  description: string
  disabled?: boolean | (() => boolean)
  category?: MaybeArray<UserRoleEnum>
}

export const dashboardWidgetSelectorMap: Record<DashboardWidgetKey, DashboardWidgetInfo> =
  {
    empty: {
      key: 'empty',
      title: '',
      description: '',
      disabled: true,
    },
    'hidden-empty': {
      key: 'hidden-empty',
      title: '',
      description: '',
      disabled: true,
    },
    'general-stats': {
      key: 'general-stats',
      title: 'Algemene statistieken',
      description:
        'Laat algemene statistieken zien, o.e. aantal aanvragen per status en de gemiddelde cijfers van thuiskoks en maaltijdzoekers',
    },
    notes: {
      key: 'notes',
      title: 'Notities',
      description: 'Maak snel een notitie',
    },
    'global-search': {
      key: 'global-search',
      title: 'Zoeken',
      description:
        'Zoek snel naar gebruikers, aanvragen, matches, of voer andere acties uit',
    },
    'global-bars': {
      key: 'global-bars',
      title: 'KPI balkjes',
      description: 'Uitgebreide weergaves van de balkjes boven in de admin',
    },
    'focus-municipalities': {
      key: 'focus-municipalities',
      title: 'Focus-gemeentes',
      description:
        'Overzicht van focus-gemeentes en de status van de doelen in de focus-gemeentes',
    },
    'focus-municipality-changes': {
      key: 'focus-municipality-changes',
      title: 'Focus-gemeente veranderingen',
      description:
        'Laat de veranderingen van focus-gemeente statussen zien tussen twee datums',
    },
    'admin-tasks': {
      key: 'admin-tasks',
      title: 'Herinneringen',
      description: 'Overzicht van herinneringen voor vandaag',
    },
    'average-ratings': {
      key: 'average-ratings',
      title: 'Tevredenheidscijfers',
      description:
        'Gemiddelde cijfers van thuiskoks en maaltijdzoekers uit de contactmomenten en vragenlijsten',
    },
    workdays: {
      key: 'workdays',
      title: 'Werkdagen',
      description: 'Gebruik deze widget om snel je werkdagen aan te passen',
    },
    'new-cook-stats': {
      key: 'new-cook-stats',
      title: 'Binnengekomen thuiskok aanmeldingen',
      description: 'Toont binnengekomen thuiskoks in landelijk en lokale gebieden',
    },
    'open-match-request-stats': {
      key: 'open-match-request-stats',
      title: 'Openstaande aanvragen statistieken',
      description:
        'Laat aanvragen die nog in het proces zitten zien, gesplitst in landelijke, lokale, en rode focus-gemeente aanvragen',
    },
    matchmakers: {
      key: 'matchmakers',
      title: 'Matchmaker Aanvragen',
      description: 'Overzicht van de toegewezen aanvragen van de matchmakers',
      category: UserRoleEnum.Matchmaker,
    },
    'matchmaker-stats': {
      key: 'matchmaker-stats',
      title: 'Matchmaker Overzicht',
      description:
        'Overzicht van de matchmakers. Bevat onder andere de werkdagen en hoeveel aanvragen er actief zijn per matchmaker',
      category: [UserRoleEnum.Matchmaker, UserRoleEnum.Helpdesker],
    },
    'matchmaker-kpis': {
      key: 'matchmaker-kpis',
      title: 'Matchmaker KPIs',
      description: 'Overzicht van de KPIS van een matchmaker',
      category: UserRoleEnum.Matchmaker,
    },
    'active-match-requests': {
      key: 'active-match-requests',
      title: 'Openstaande Aanvragen',
      description:
        'Overzicht van openstaande aanvragen, voorgestelde aanvragen, of aanvragen die op proefmaaltijd staan',
      category: UserRoleEnum.Matchmaker,
    },
    'contact-moments': {
      key: 'contact-moments',
      title: 'Contactmomenten',
      description: 'Overzicht van toegewezen contactmomenten',
      category: UserRoleEnum.Matchmaker,
    },
    'new-match-requests': {
      key: 'new-match-requests',
      title: 'Nieuwe aanvragen',
      description: 'Overzicht van nieuw binnengekomen aanvragen',
      category: UserRoleEnum.Helpdesker,
    },
    'waiting-list-match-requests': {
      key: 'waiting-list-match-requests',
      title: 'Wachtlijst aanvragen',
      description: 'Overzicht van de aanvragen op de wachtlijst',
      category: UserRoleEnum.Helpdesker,
    },
    'helpdesk-kpis': {
      key: 'helpdesk-kpis',
      title: 'Helpdesk KPIs',
      description: 'Laat KPIs voor de helpdesk zien',
      category: UserRoleEnum.Helpdesker,
    },
    vogs: {
      key: 'vogs',
      title: 'VOGs',
      description: 'Overzicht van VOG status van koks',
      category: UserRoleEnum.Helpdesker,
    },
    animals: {
      key: 'animals',
      title: '???',
      description: '',
    },
  }

export const getNeighborCellSlot = (
  cell: DashboardSlot,
  direction: DashboardWidgetTranslationDirection,
) => {
  return match([cell, direction])
    .returnType<DashboardSlot | undefined | null>()
    .with(['slotA', 'up'], () => undefined)
    .with(['slotA', 'right'], () => 'slotB')
    .with(['slotA', 'down'], () => 'slotC')
    .with(['slotA', 'left'], () => undefined)
    .with(['slotB', 'up'], () => undefined)
    .with(['slotB', 'right'], () => undefined)
    .with(['slotB', 'down'], () => 'slotD')
    .with(['slotB', 'left'], () => 'slotA')
    .with(['slotC', 'up'], () => 'slotA')
    .with(['slotC', 'right'], () => 'slotD')
    .with(['slotC', 'down'], () => undefined)
    .with(['slotC', 'left'], () => undefined)
    .with(['slotD', 'up'], () => 'slotB')
    .with(['slotD', 'right'], () => undefined)
    .with(['slotD', 'down'], () => undefined)
    .with(['slotD', 'left'], () => 'slotC')
    .exhaustive()
}

export const getDefaultLayoutForRole = (
  adminUser: MinimalAdminUser,
): DashboardLoadout => {
  const newLoadout = match(adminUser.mainRole)
    .returnType<LoadoutSettings>()
    .with(UserRoleEnum.Helpdesker, () => ({
      position: {
        slotA: 'matchmaker-stats',
        slotB: 'hidden-empty',
        slotC: 'new-match-requests',
        slotD: 'global-search',
      },
      settings: [
        {
          ...getDashboardWidgetDefaultSettings('matchmaker-stats'),
          size: 'horizontal',
        },
        getDashboardWidgetDefaultSettings('new-match-requests'),
        getDashboardWidgetDefaultSettings('global-search'),
      ],
    }))
    .with(UserRoleEnum.Matchmaker, () => ({
      position: {
        slotA: 'active-match-requests',
        slotB: 'open-match-request-stats',
        slotC: 'matchmaker-kpis',
        slotD: 'matchmakers',
      },
      settings: [
        getDashboardWidgetDefaultSettings('active-match-requests'),
        getDashboardWidgetDefaultSettings('open-match-request-stats'),
        getDashboardWidgetDefaultSettings('matchmaker-kpis'),
        getDashboardWidgetDefaultSettings('matchmakers'),
      ],
    }))
    .otherwise(() => ({
      position: {
        slotA: 'general-stats',
        slotB: 'hidden-empty',
        slotC: 'focus-municipalities',
        slotD: 'average-ratings',
      },
      settings: [
        {
          ...getDashboardWidgetDefaultSettings('general-stats'),
          size: 'horizontal',
        },
        getDashboardWidgetDefaultSettings('focus-municipalities'),
        getDashboardWidgetDefaultSettings('average-ratings'),
      ],
    }))

  return {
    id: -1 as DashboardLoadoutId,
    adminUserId: adminUser.id,
    loadoutName: 'Standaard',
    createdAt: dayjsUTC(),
    updatedAt: dayjsUTC(),
    loadout: newLoadout,
  }
}

export function getMatchmakerStatisticsTarget(
  adminUser: MinimalAdminUser,
  key: MatchmakerDashboardStatisticsKey,
) {
  if (key === 'open') {
    return Math.floor(adminUser.maxOpen * adminUser.targetModifier)
  } else if (key === 'currentWeek') {
    const target = adminUser.workdays.matchmaker.length * 2.5
    return Math.floor(target * adminUser.targetModifier)
  } else {
    return adminUser.maxSuggested * adminUser.targetModifier
  }
}

export function formatMatchmakerDashboardStatistics(
  value: number | undefined,
  target: number | undefined,
  showPercentages: boolean,
) {
  if (showPercentages) {
    return formatPercentage(value ?? undefined, target ?? undefined)
  } else {
    return `${value ?? '-'} / ${target?.toFixed(0) ?? 0}`
  }
}

export function getMatchmakerStatisticsCellClass(
  value: number | undefined,
  target: number | undefined,
  baseClass?: string,
) {
  if (!baseClass) {
    baseClass = ''
  }

  if (value == null || target == null) {
    return baseClass
  }

  const currentCount = value

  if (currentCount === 0 && target === 0) {
    return `${baseClass} !bg-success-200`
  } else if (currentCount !== 0 && currentCount <= target) {
    return `${baseClass} !bg-success-200`
  } else if (
    currentCount > target &&
    currentCount <= Math.max(target + 3, target * 1.1)
  ) {
    return `${baseClass} !bg-warning-200`
  } else {
    return `${baseClass} !bg-danger-200`
  }
}

export function getNumberOfFocusMunicipalities(
  municipalities: string[] | null | undefined,
  focusMunicipalities: string[],
) {
  if (!municipalities) {
    return 0
  }
  return municipalities.filter((m) => focusMunicipalities.includes(camelCase(m))).length
}

export function getNumberOfMatchRequestsInFocusMunicipalities(
  data: SpecificMatchmakerKpiDashboardResource,
  status: MatchmakerKPIMatchRequestStatusCamelCase,
  focusMunicipalities: string[],
) {
  const matchRequests = data.kpis.matchRequests?.[status]
  if (!matchRequests) {
    return 0
  }

  return getNumberOfFocusMunicipalities(
    matchRequests.map((m) => m.municipality),
    focusMunicipalities,
  )
}

export function getNumberOfMatchRequestsInNation(
  data: SpecificMatchmakerKpiDashboardResource,
  status: MatchmakerKPIMatchRequestStatusCamelCase,
  focusMunicipalities: string[],
) {
  const matchRequests = data.kpis.matchRequests?.[status]
  if (!matchRequests) {
    return 0
  }

  return (
    matchRequests.length -
    getNumberOfMatchRequestsInFocusMunicipalities(data, status, focusMunicipalities)
  )
}

export function countMatchRequestKpisForStatuses(
  data: SpecificMatchmakerKpiDashboardResource | undefined,
  statuses: MatchmakerKPIMatchRequestStatusCamelCase[],
) {
  if (!data) {
    return 0
  }
  const matchRequestIds: number[] = []

  statuses.forEach((status) => {
    matchRequestIds.push(
      ...(data.kpis.matchRequests?.[status]?.map((item) => item.id) ?? []),
    )
  })

  return uniq(matchRequestIds).length
}

export function getMatchRequestKpisForStatuses(
  data: SpecificMatchmakerKpiDashboardResource,
  statuses: MatchmakerKPIMatchRequestStatusCamelCase[],
  target: number | null | undefined,
  showPercentages: boolean,
) {
  const amount = countMatchRequestKpisForStatuses(data, statuses)

  return formatMatchmakerKpi(amount, target, showPercentages)
}

export function formatMatchmakerKpi(
  value: number | null | undefined,
  target: number | null | undefined,
  showPercentages: boolean,
) {
  if (showPercentages) {
    return formatPercentage(value ?? undefined, target ?? undefined)
  } else {
    return `${value ?? '-'} / ${target?.toFixed(0) ?? '?'}`
  }
}

export const goldMatchmakerKpiMultiplier = 2
export const silverMatchmakerKpiMultiplier = 1.75
export const bronzeMatchmakerKpiMultiplier = 1.5

export function getMatchKpiColor(
  amount?: number,
  target?: number,
  isTextColor: boolean = false,
): ExtendedTailwindColor {
  if (!amount || !target) {
    if (isTextColor) {
      return 'white'
    }
    return 'success-200'
  } else if (amount >= Math.floor(target * goldMatchmakerKpiMultiplier)) {
    return 'gold'
  } else if (amount >= Math.floor(target * silverMatchmakerKpiMultiplier)) {
    return 'silver'
  } else if (amount >= Math.floor(target * bronzeMatchmakerKpiMultiplier)) {
    return 'bronze'
  } else if (amount === Math.floor(target * bronzeMatchmakerKpiMultiplier - 1)) {
    if (isTextColor) {
      return 'white'
    }
    return 'warning-200'
  } else {
    if (isTextColor) {
      return 'white'
    }
    return 'danger-200'
  }
}

export function getMatchKpiColorClass(amount?: number, target?: number): string {
  const baseClass = 'font-medium'
  return `${baseClass} bg-${getMatchKpiColor(amount, target)}`
}
