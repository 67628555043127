<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { teamDayQuiz, type TeamDayQuizEntry } from '/@src/assets/team-day-quiz'
import { isOfficeManager } from '/@src/utils/entity-checks'
import type { ApexProperties, BaseTailwindColor } from '/@src/types/elements-ui'
import type { UserId } from '/@src/types/users'
import { useApi } from '/@src/composable/useApi'
import { useWebSocket } from '/@src/composable/useWebSocket'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import ApexCharts from 'vue3-apexcharts'
import waitingMusic from '/@src/assets/sound/waiting-music.mp3'

type TeamDayQuizStats = Record<string, Record<UserId, string>>

interface TeamDayQuizInitial {
  step: number
  stats: TeamDayQuizStats
}

type TeamDayQuizEvent = { userId: UserId } & (
  | {
      operation: 'change_index'
      data: {
        step: number
      }
    }
  | {
      operation: 'enable_answers' | 'show_answers'
      data: {
        isEnabled: boolean
      }
    }
  | {
      operation: 'answer'
      data: {
        answer: string
        questionKey: string
      }
    }
)

useHead({
  title: 'Wist je datjes?',
})

definePage({
  meta: {
    requiresAdmin: true,
    roles: 'office-manager',
  },
})

const adminUserStore = useAdminUsersStore()

const api = useApi()

const maxTime = 30

const answersAreEnabled = ref(false)
const showResults = ref(false)
const showAnswers = ref(false)

const currentStepIndex = ref(0)

const audioElement = new Audio(waitingMusic)
const playWaitingMusic = () => {
  // Generate a random time in seconds within the audio duration (assuming the audio duration is accessible)
  if (audioElement.played.length === 0) {
    audioElement.currentTime = 0
  } else {
    audioElement.currentTime = Math.random() * (audioElement.duration || 0)
  }

  audioElement.loop = true
  audioElement.play().catch((error) => {
    console.error('Error trying to play the waiting music:', error)
  })
}

const stopWaitingMusic = () => {
  audioElement.pause()
  audioElement.currentTime = 0 // Reset the audio to the beginning
}

const answerStats = ref<TeamDayQuizStats>({})

const adminUser = computed(() => adminUserStore.adminUser)

useWebSocket({
  event: 'TeamDayQuizEvent',
  callback: (event: TeamDayQuizEvent) => {
    console.log(event)
    if (event.operation === 'change_index') {
      setIndex(event.data.step)
    } else if (event.operation === 'enable_answers') {
      answersAreEnabled.value = event.data.isEnabled
    } else if (event.operation === 'answer') {
      answerStats.value[event.data.questionKey] =
        answerStats.value[event.data.questionKey] || {}
      answerStats.value[event.data.questionKey][event.userId] = event.data.answer
    } else if (event.operation === 'show_answers') {
      showAnswers.value = event.data.isEnabled
    }
  },
})

const currentStep = computed<TeamDayQuizEntry | undefined>(() => {
  if (currentStepIndex.value < teamDayQuiz.length) {
    return teamDayQuiz[currentStepIndex.value]
  } else {
    return undefined
  }
})
const currentSelectedAnswer = computed<string | undefined>(() => {
  const curr = currentStep.value
  const activeUserId = adminUser.value?.userId
  if (!curr || !answerStats.value[curr.correctAnswer] || !activeUserId) {
    return undefined
  }

  return answerStats.value[curr.correctAnswer][activeUserId]
})
const hasPrevious = computed(() => currentStepIndex.value > 0)
const hasNext = computed(() => currentStepIndex.value < teamDayQuiz.length - 1)

watch(
  currentStep,
  (newValue) => {
    const correctAnswer = newValue?.correctAnswer
    if (!correctAnswer) {
      return
    }

    answerStats.value[correctAnswer] = answerStats.value[correctAnswer] || {}
  },
  { immediate: true },
)

const setIndex = async (newIndex: number) => {
  const oldIndex = currentStepIndex.value
  answersAreEnabled.value = false
  if (newIndex > oldIndex) {
    showResults.value = false
  }
  await nextTick()
  currentStepIndex.value = newIndex
}

const subtractIndex = () => {
  if (!isOfficeManager.value) {
    return
  }
  if (hasPrevious.value) {
    updateCurrentStepIndex(currentStepIndex.value - 1)
  }
}

const addIndex = () => {
  if (!isOfficeManager.value) {
    return
  }
  if (hasNext.value) {
    updateCurrentStepIndex(currentStepIndex.value + 1)
  }
}

const {
  start: internalStartTimer,
  reset: resetTimer,
  remaining,
} = useCountdown(maxTime, {
  onComplete: () => {
    showResults.value = true
    answersAreEnabled.value = false
    resetTimer()
    stopWaitingMusic()
  },
})
const startTimer = () => {
  if (!isOfficeManager.value) {
    return
  }
  if (answersAreEnabled.value) {
    return
  }
  internalStartTimer()
  updateAnswersEnabled(true)
}

onKeyStroke('ArrowLeft', subtractIndex)
onKeyStroke('ArrowRight', addIndex)
onKeyStroke(' ', startTimer)

const getButtonClass = (answer: string) => {
  const correctAnswer = currentStep.value?.correctAnswer
  const isCurrentAnswer = currentSelectedAnswer.value === answer

  if (showAnswers.value) {
    if (correctAnswer === answer) {
      return 'border-success bg-success-100 hover:bg-success-300 dark:bg-success text-black black:text-success-text hover:dark:bg-success-900'
    } else if (isCurrentAnswer && correctAnswer !== answer) {
      return 'border-danger bg-danger-100 hover:bg-danger-300 dark:bg-danger text-black black:text-danger-text hover:dark:bg-danger-900'
    } else {
      return 'border-border hover:bg-background-300 dark:border-border-dark dark:bg-background-darker dark:hover:bg-background-darkest'
    }
  }

  if (!showAnswers.value || !correctAnswer) {
    if (isCurrentAnswer) {
      return 'border-info bg-info-100 hover:bg-info-300 dark:bg-info text-black dark:text-info-text hover:dark:bg-info-900'
    } else {
      return 'border-border hover:bg-background-300 dark:border-border-dark dark:bg-background-darker dark:hover:bg-background-darkest'
    }
  }

  if (showAnswers.value && currentSelectedAnswer.value === answer) {
    return 'border-info bg-background-300'
  }
}

const progressColour = computed<BaseTailwindColor>(() => {
  if (!answersAreEnabled.value) {
    return 'info'
  } else if (remaining.value / maxTime > 1 / 3) {
    return 'success'
  } else if (remaining.value / maxTime > 1 / 6) {
    return 'warning'
  } else {
    return 'danger'
  }
})

const columnChartData = computed<ApexProperties | undefined>(() => {
  if (!currentStep.value) {
    return undefined
  }
  const stats = answerStats.value[currentStep.value.correctAnswer]
  if (!stats) {
    return undefined
  }

  const groupedAnswers = Object.groupBy(Object.values(stats), (entry) => entry)
  const series = Object.entries(groupedAnswers).map(([answerKey, answers]) => {
    return { x: answerKey, y: answers?.length ?? 0 }
  })

  return {
    options: {
      chart: {
        type: 'bar',
      },
    },
    series: [
      {
        name: 'Resultaten',
        data: series,
      },
    ],
  } satisfies ApexProperties
})

const fetchTeamQuizData = async () => {
  const data = await api.get<TeamDayQuizInitial>('/admin/admin-users/team-day-quiz')

  currentStepIndex.value = data.data.step
  answerStats.value = data.data.stats
}

const updateCurrentStepIndex = (step: number) => {
  api.post('/admin/admin-users/team-day-quiz', {
    instruction: 'change_index',
    data: { step },
  })
}

const updateAnswersEnabled = (isEnabled: boolean) => {
  api.post('/admin/admin-users/team-day-quiz', {
    instruction: 'enable_answers',
    data: { isEnabled },
  })
  playWaitingMusic()
}

const postAnswer = (answer: string) => {
  if (!currentStep.value || !adminUser.value) {
    return
  }

  answerStats.value[currentStep.value.correctAnswer] =
    answerStats.value[currentStep.value?.correctAnswer] || {}
  answerStats.value[currentStep.value.correctAnswer][adminUser.value.userId] = answer

  api.post('/admin/admin-users/team-day-quiz', {
    instruction: 'answer',
    data: { answer, questionKey: currentStep.value.correctAnswer },
  })
}

const enableShowingAnswers = (isEnabled: boolean) => {
  api.post('/admin/admin-users/team-day-quiz', {
    instruction: 'show_answers',
    data: { isEnabled },
  })
}

onMounted(fetchTeamQuizData)
</script>

<template>
  <div>
    <h3 class="title mb-12 text-2xl">Wist je datjes?</h3>

    <div v-if="currentStep" class="columns">
      <div class="column half !flex flex-col justify-center">
        <h4 class="title text-center text-xl">{{ currentStep.question }}</h4>

        <div class="columns multiline !mx-2 !mt-6 justify-evenly">
          <button
            v-for="i in [0, 1, 2, 3]"
            :key="`button-${i}`"
            class="column !my-2 w-[45%] rounded border border-solid text-center capitalize"
            :class="getButtonClass(currentStep.answers[i])"
            :disabled="showAnswers || !answersAreEnabled"
            @click="postAnswer(currentStep.answers[i])"
          >
            {{ currentStep.answers[i] }}
          </button>
        </div>
      </div>

      <div class="column half !flex flex-col items-center justify-center text-center">
        <template v-if="showResults || showAnswers">
          <h1 class="mt-24 text-[4rem]">Resultaten</h1>

          <div class="w-full" v-if="columnChartData">
            <ApexCharts
              class="!capitalize"
              :options="columnChartData.options"
              :series="columnChartData.series"
            />
          </div>
        </template>
        <template v-else>
          <h1 class="mt-24 text-[8rem]">
            {{ remaining }}
          </h1>
          <h3 class="-mt-12 text-[4rem]">seconde{{ remaining !== 1 ? 'n' : '' }}</h3>
          <div class="w-full">
            <VProgress
              class="mx-24 mb-24 w-full"
              :value="remaining"
              :max="maxTime"
              size="large"
              :foreground-color="progressColour"
              label="&nbsp;"
            />
          </div>
        </template>
      </div>
    </div>

    <div v-if="isOfficeManager" class="mt-32 flex justify-center">
      <VButton
        icon-only="fa-chevron-left"
        :disabled="!hasPrevious || answersAreEnabled"
        @click="subtractIndex"
      />
      <VButton v-if="!showAnswers" label="Vraag openzetten" @click="startTimer" />
      <VButton label="Antwoorden tonen" @click="enableShowingAnswers" />
      <VButton
        icon-only="fa-chevron-right"
        :disabled="!hasNext || answersAreEnabled"
        @click="addIndex"
      />
    </div>
  </div>
</template>

<style scoped></style>
