<script setup lang="ts">
import { match } from 'ts-pattern'
import type { BaseTailwindColor, FAIconName } from '/@src/types/elements-ui'
import type { LengthUnit } from '/@src/types/utils'

interface SideCardProps {
  direction: 'left' | 'right'
  startOpen?: boolean
  wide?: boolean
  offset?: LengthUnit
  sideCardColor?: BaseTailwindColor
}

const props = defineProps<SideCardProps>()

const isOpen = ref(props.startOpen)
const showContent = ref(props.startOpen)

const chevronIsOpen = computed<FAIconName>(() =>
  match(props.direction)
    .returnType<FAIconName>()
    .with('left', () => 'fa-chevron-left')
    .with('right', () => 'fa-chevron-right')
    .exhaustive(),
)

const chevronIsClosed = computed<FAIconName>(() =>
  match(props.direction)
    .returnType<FAIconName>()
    .with('left', () => 'fa-chevron-right')
    .with('right', () => 'fa-chevron-left')
    .exhaustive(),
)

const { start: toggleTabsContent } = useTimeoutFn(
  () => (showContent.value = !showContent.value),
  400,
  { immediate: false },
)

const openCard = () => {
  if (!isOpen.value) {
    isOpen.value = true
    toggleTabsContent()
  }
}

const closeCard = () => {
  if (isOpen.value) {
    isOpen.value = false
    showContent.value = false
  }
}

const toggleCard = () => {
  if (isOpen.value) {
    closeCard()
  } else {
    openCard()
  }
}

defineExpose({ openCard, closeCard, toggleCard })
</script>

<template>
  <VCard
    class="side-card"
    :class="[
      `is-${direction}`,
      isOpen && 'is-active',
      wide && 'wide-side-card',
      direction === 'left' && offset && `left-[${offset}]`,
    ]"
    :style="offset && { left: offset }"
    clickable-icon
    :header-icon-class="sideCardColor && `!bg-${sideCardColor}`"
    :content-class="!showContent && 'hidden'"
    @icon-click="toggleCard"
  >
    <template #title>
      <slot v-if="showContent" name="title"></slot>
    </template>
    <template #icon>
      <VIcon
        id="sidecard-toggle-button"
        :icon="showContent ? chevronIsOpen : chevronIsClosed"
        force-clickable
        :color="sideCardColor ? `${sideCardColor}-text` : undefined"
      />
    </template>

    <template #content>
      <div v-show="showContent">
        <slot name="content" />
      </div>
    </template>

    <template v-if="showContent" #footer>
      <slot name="footer" />
    </template>
  </VCard>
</template>

<style scoped></style>
