<script setup lang="ts">
definePage({
  meta: {
    requiresAdmin: true,
  },
})
</script>

<template>
  <RouterView v-slot="{ Component }">
    <transition name="translate-page-x" mode="out-in">
      <component :is="Component" />
    </transition>
  </RouterView>
</template>

<style scoped></style>
