<script setup lang="ts">
import { mobileOperatingSystem } from '/@src/utils/entity-checks'

definePage({
  meta: {
    requiresAdmin: true,
  },
})
</script>

<template>
  <div>
    <h3 class="title mb-4 mt-8 text-xl">TG-Admin apps</h3>
    <p>Hier staan links en instructies om de app te downloaden</p>

    <div
      class="columns multiline !mt-3"
      :class="mobileOperatingSystem === 'iOS' && 'flex-col-reverse'"
    >
      <div class="column half sm:full">
        <VCard
          :highlight="mobileOperatingSystem === 'Android'"
          :lowlight="
            mobileOperatingSystem !== 'Android' && mobileOperatingSystem !== 'unknown'
          "
        >
          <template #title>Android</template>
          <template #icon>
            <VIcon icon="fa-android" icon-pack="fa-brands" size="large" color="info" />
          </template>
          <template #content>
            <div class="prose">
              Klik op de knop hieronder op je Android telefoon om naar de Google Play
              Store te gaan. Zorg wel dat je daar bent ingelogd met je Thuisgekookt
              e-mailadres, anders kan je de app niet zien. In de Google Play Store kan je
              vervolgens de app installeren.
              <br />
              <br />
              Als de app aangepast wordt, wordt die normaal gesproken vanzelf geüpdated,
              anders kan je naar Google Play Store gaan om de app handmatig te updaten.
            </div>
          </template>
          <template #footer>
            <a
              class="w-full"
              href="https://play.google.com/store/apps/details?id=com.thuisgekookt.thuisgekooktImportContacts"
              target="_blank"
            >
              <VButton width="full" color="info" outlined>
                Google Play Store
                <template #icon-left>
                  <VIcon icon="fa-android" icon-pack="fa-brands" />
                </template>
              </VButton>
            </a>
          </template>
        </VCard>
      </div>

      <div class="column half sm:full">
        <VCard
          :highlight="mobileOperatingSystem === 'iOS'"
          :lowlight="
            mobileOperatingSystem !== 'iOS' && mobileOperatingSystem !== 'unknown'
          "
        >
          <template #title>iOS</template>
          <template #icon>
            <VIcon icon="fa-apple" icon-pack="fa-brands" size="large" color="info" />
          </template>
          <template #content>
            <div class="prose">
              Klik op de knop hieronder op je iPhone om naar de App Store te gaan. Daar
              kan je de app installeren. Daarna kan je de app gelijk helemaal gebruiken.
              <br />
              <br />
              Als de app aangepast wordt, wordt die normaal gesproken vanzelf geüpdated,
              anders kan je naar App Store gaan om de app handmatig te updaten.
            </div>
          </template>
          <template #footer>
            <a
              class="w-full"
              href="https://apps.apple.com/app/tg-admin/id6450715429"
              target="_blank"
            >
              <VButton width="full" color="info" outlined>
                App Store
                <template #icon-left>
                  <VIcon icon="fa-apple" icon-pack="fa-brands" />
                </template>
              </VButton>
            </a>
          </template>
        </VCard>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
