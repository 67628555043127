<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

useHead({
  title: 'Gebruikers | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const route = useRoute()

const tabs: VTabsItem[] = [
  {
    label: 'Zoeken',
    value: 'search',
    to: {
      name: '/users/',
    },
  },
  {
    label: 'Bulk aanpassingen',
    value: 'bulk',
    to: {
      name: '/users/bulk',
    },
  },
  {
    label: 'CSV Export',
    value: 'csv-export',
    icon: 'fa-file-csv',
    to: {
      name: '/users/csv-export',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs
      v-if="!route.name.includes('[user]')"
      v-model:selected="activeTab"
      :tabs="tabs"
      size="medium"
    />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style scoped></style>
