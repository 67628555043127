<script setup lang="ts">
import type {
  ComponentSize,
  FAIconName,
  FAIconPack,
  TailwindColor,
} from '/@src/types/elements-ui'
import { LoadingSpinner } from '/@src/models/standardIcons'

interface VBlockProps {
  title?: string | number
  subtitle?: string
  icon?: FAIconName
  iconSize?: ComponentSize
  iconColor?: TailwindColor
  iconPack?: FAIconPack
  iconRounded?: boolean
  iconOutlined?: boolean
  vertical?: boolean
  reversed?: boolean
  loading?: boolean
  overrideClickable?: boolean
  disabled?: boolean
  bottomMargin?: `mb-${number}`
}

const props = withDefaults(defineProps<VBlockProps>(), {
  title: undefined,
  subtitle: undefined,
  icon: undefined,
  iconSize: 'normal',
  iconColor: 'info',
  iconPack: undefined,
  bottomMargin: 'mb-4',
})

const verticalAlignmentClass = computed(() => {
  if (props.vertical && props.reversed) {
    return 'flex-col-reverse'
  } else if (props.vertical && !props.reversed) {
    return 'flex-col'
  } else if (!props.vertical && props.reversed) {
    return 'flex-row-reverse'
  } else {
    return undefined
  }
})
</script>

<template>
  <div
    class="flex items-center"
    :class="[
      `${bottomMargin}`,
      verticalAlignmentClass,
      $attrs.onClick && !overrideClickable && 'cursor-pointer',
      !title && !subtitle && !$slots['title'] && 'justify-center',
    ]"
  >
    <slot name="icon">
      <VIconBox
        v-if="icon"
        :icon="loading ? LoadingSpinner : icon"
        :icon-pack="iconPack"
        :color="iconColor"
        :rounded="iconRounded"
        :outlined="iconOutlined"
        :size="iconSize"
        :disabled
      />
    </slot>
    <div
      v-if="title || subtitle || $slots['title']"
      class="title-subtitle"
      :class="vertical ? 'ml-0 text-center' : 'ml-3'"
    >
      <slot name="title">
        <span>{{ title }}</span>
        <span v-if="subtitle">{{ subtitle }}</span>
      </slot>
      <slot></slot>
    </div>
    <div v-if="$slots['action']" class="ml-auto items-end">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
