<script setup lang="ts">
import { onboarding } from '/@src/utils/onboarding'
import { useDarkMode } from '/@src/stores/darkmode'
import type { TailwindColor } from '/@src/types/elements-ui'

const darkMode = useDarkMode()

const hasTutorial = onboarding.hasTutorial
const iconColor = computed<TailwindColor>(() => {
  if (darkMode.isDark) {
    return hasTutorial.value ? 'white' : 'gray-dark'
  } else {
    return hasTutorial.value ? 'black' : 'gray'
  }
})
</script>
<template>
  <VTooltip class="mx-2">
    <template #popper>
      <template v-if="hasTutorial">
        Klik om de tutorial van deze pagina te beginnen
      </template>
      <template v-else> Deze pagina heeft nog geen tutorial</template>
    </template>
    <VIcon
      :class="
        hasTutorial
          ? 'rounded-full border-2 border-solid border-black dark:border-white'
          : 'cursor-not-allowed'
      "
      icon="fa-question"
      size="medium"
      :color="iconColor"
      :disabled="!hasTutorial"
      :override-clickable="!hasTutorial"
      @click="onboarding.start"
    />
  </VTooltip>
</template>

<style scoped></style>
