import _definePage_default_0 from '/app/src/pages/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_1 from '/app/src/pages/analysis/bugs.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_3 from '/app/src/pages/apps.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/app/src/pages/bug-report.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/app/src/pages/checks.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/app/src/pages/cooks.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/app/src/pages/dashboard.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/app/src/pages/dashboard/matchmakers.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/app/src/pages/donations.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/app/src/pages/events.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/app/src/pages/external.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/app/src/pages/finances.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/app/src/pages/finances/legacy.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/app/src/pages/matches.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/app/src/pages/matching.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/app/src/pages/meals.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/app/src/pages/payments.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/app/src/pages/reporting/absentee-rate.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/app/src/pages/reporting/bugs.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/app/src/pages/reporting/enps.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/app/src/pages/reporting/municipalities/[municipality].vue?definePage&vue&lang.tsx'
import _definePage_default_22 from '/app/src/pages/reporting/statistics/focus-municipalities.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from '/app/src/pages/reporting/statistics/matchmakers/index.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from '/app/src/pages/reporting/statistics/matchmakers/kpis.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from '/app/src/pages/rock.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from '/app/src/pages/secret-test-page.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from '/app/src/pages/settings.vue?definePage&vue&lang.tsx'
import _definePage_default_28 from '/app/src/pages/settings/index.vue?definePage&vue&lang.tsx'
import _definePage_default_29 from '/app/src/pages/settings/contact-moments.vue?definePage&vue&lang.tsx'
import _definePage_default_30 from '/app/src/pages/settings/local-targets.vue?definePage&vue&lang.tsx'
import _definePage_default_31 from '/app/src/pages/settings/meal-wishes.vue?definePage&vue&lang.tsx'
import _definePage_default_32 from '/app/src/pages/settings/signup-references.vue?definePage&vue&lang.tsx'
import _definePage_default_33 from '/app/src/pages/settings/stop-reasons.vue?definePage&vue&lang.tsx'
import _definePage_default_34 from '/app/src/pages/settings/tags.vue?definePage&vue&lang.tsx'
import _definePage_default_35 from '/app/src/pages/surveys.vue?definePage&vue&lang.tsx'
import _definePage_default_36 from '/app/src/pages/teamdag-quiz.vue?definePage&vue&lang.tsx'
import _definePage_default_37 from '/app/src/pages/to-do.vue?definePage&vue&lang.tsx'
import _definePage_default_38 from '/app/src/pages/to-do/index.vue?definePage&vue&lang.tsx'
import _definePage_default_39 from '/app/src/pages/users.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/app/src/pages/[...all].vue'),
    /* no children */
  },
  _mergeRouteRecord(
    {
      path: '/analysis',
      name: '/analysis',
      component: () => import('/app/src/pages/analysis.vue'),
      children: [
        {
          path: '',
          name: '/analysis/',
          component: () => import('/app/src/pages/analysis/index.vue'),
          /* no children */
        },
        _mergeRouteRecord(
          {
            path: 'bugs',
            name: '/analysis/bugs',
            component: () => import('/app/src/pages/analysis/bugs.vue'),
            /* no children */
          },
          _definePage_default_1
        ),
        {
          path: 'match-monitoring-result',
          name: '/analysis/match-monitoring-result',
          component: () => import('/app/src/pages/analysis/match-monitoring-result.vue'),
          /* no children */
        },
        {
          path: 'match-monitoring-up-to-date',
          name: '/analysis/match-monitoring-up-to-date',
          component: () => import('/app/src/pages/analysis/match-monitoring-up-to-date.vue'),
          /* no children */
        },
        {
          path: 'match-percentage',
          name: '/analysis/match-percentage',
          component: () => import('/app/src/pages/analysis/match-percentage.vue'),
          /* no children */
        },
        {
          path: 'match-request-status-trajectory',
          name: '/analysis/match-request-status-trajectory',
          component: () => import('/app/src/pages/analysis/match-request-status-trajectory.vue'),
          /* no children */
        },
        {
          path: 'matching-algorithm',
          name: '/analysis/matching-algorithm',
          component: () => import('/app/src/pages/analysis/matching-algorithm.vue'),
          /* no children */
        },
        {
          path: 'matching-hours',
          name: '/analysis/matching-hours',
          component: () => import('/app/src/pages/analysis/matching-hours.vue'),
          /* no children */
        },
        {
          path: 'municipality-infographic',
          name: '/analysis/municipality-infographic',
          component: () => import('/app/src/pages/analysis/municipality-infographic.vue'),
          /* no children */
        },
        {
          path: 'stop-reasons',
          name: '/analysis/stop-reasons',
          component: () => import('/app/src/pages/analysis/stop-reasons.vue'),
          /* no children */
        },
        {
          path: 'waiting-list',
          name: '/analysis/waiting-list',
          component: () => import('/app/src/pages/analysis/waiting-list.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_0
  ),
  _mergeRouteRecord(
    {
      path: '/apps',
      name: '/apps',
      component: () => import('/app/src/pages/apps.vue'),
      /* no children */
    },
    _definePage_default_3
  ),
  _mergeRouteRecord(
    {
      path: '/bug-report',
      name: '/bug-report',
      component: () => import('/app/src/pages/bug-report.vue'),
      /* no children */
    },
    _definePage_default_4
  ),
  _mergeRouteRecord(
    {
      path: '/checks',
      name: '/checks',
      component: () => import('/app/src/pages/checks.vue'),
      children: [
        {
          path: '',
          name: '/checks/',
          component: () => import('/app/src/pages/checks/index.vue'),
          /* no children */
        },
        {
          path: 'amount-of-people',
          name: '/checks/amount-of-people',
          component: () => import('/app/src/pages/checks/amount-of-people.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_5
  ),
  _mergeRouteRecord(
    {
      path: '/cooks',
      name: '/cooks',
      component: () => import('/app/src/pages/cooks.vue'),
      children: [
        {
          path: '',
          name: '/cooks/',
          component: () => import('/app/src/pages/cooks/index.vue'),
          /* no children */
        },
        {
          path: ':cook',
          name: '/cooks/[cook]',
          component: () => import('/app/src/pages/cooks/[cook].vue'),
          children: [
            {
              path: 'nearby-match-requests',
              name: '/cooks/[cook]/nearby-match-requests',
              component: () => import('/app/src/pages/cooks/[cook]/nearby-match-requests.vue'),
              /* no children */
            }
          ],
        },
        {
          path: 'reporting',
          name: '/cooks/reporting',
          component: () => import('/app/src/pages/cooks/reporting.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_6
  ),
  _mergeRouteRecord(
    {
      path: '/dashboard',
      name: '/dashboard',
      component: () => import('/app/src/pages/dashboard.vue'),
      children: [
        {
          path: '',
          name: '/dashboard/',
          component: () => import('/app/src/pages/dashboard/index.vue'),
          /* no children */
        },
        {
          path: 'absences',
          name: '/dashboard/absences',
          component: () => import('/app/src/pages/dashboard/absences.vue'),
          /* no children */
        },
        _mergeRouteRecord(
          {
            path: 'matchmakers',
            name: '/dashboard/matchmakers',
            component: () => import('/app/src/pages/dashboard/matchmakers.vue'),
            /* no children */
          },
          _definePage_default_8
        )
      ],
    },
    _definePage_default_7
  ),
  _mergeRouteRecord(
    {
      path: '/donations',
      name: '/donations',
      component: () => import('/app/src/pages/donations.vue'),
      children: [
        {
          path: '',
          name: '/donations/',
          component: () => import('/app/src/pages/donations/index.vue'),
          /* no children */
        },
        {
          path: 'donors',
          name: '/donations/donors',
          component: () => import('/app/src/pages/donations/donors.vue'),
          children: [
            {
              path: '',
              name: '/donations/donors/',
              component: () => import('/app/src/pages/donations/donors/index.vue'),
              /* no children */
            },
            {
              path: ':donor',
              name: '/donations/donors/[donor]',
              component: () => import('/app/src/pages/donations/donors/[donor].vue'),
              children: [
                {
                  path: '',
                  name: '/donations/donors/[donor]/',
                  component: () => import('/app/src/pages/donations/donors/[donor]/index.vue'),
                  /* no children */
                },
                {
                  path: 'donations',
                  name: '/donations/donors/[donor]/donations',
                  component: () => import('/app/src/pages/donations/donors/[donor]/donations.vue'),
                  /* no children */
                },
                {
                  path: 'edit',
                  name: '/donations/donors/[donor]/edit',
                  component: () => import('/app/src/pages/donations/donors/[donor]/edit.vue'),
                  /* no children */
                }
              ],
            }
          ],
        },
        {
          path: 'matching-donations',
          name: '/donations/matching-donations',
          component: () => import('/app/src/pages/donations/matching-donations.vue'),
          /* no children */
        },
        {
          path: 'meal-donations',
          name: '/donations/meal-donations',
          component: () => import('/app/src/pages/donations/meal-donations.vue'),
          /* no children */
        },
        {
          path: 'recurring-donations',
          name: '/donations/recurring-donations',
          component: () => import('/app/src/pages/donations/recurring-donations.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_9
  ),
  _mergeRouteRecord(
    {
      path: '/events',
      name: '/events',
      component: () => import('/app/src/pages/events.vue'),
      children: [
        {
          path: '',
          name: '/events/',
          component: () => import('/app/src/pages/events/index.vue'),
          /* no children */
        },
        {
          path: ':event',
          name: '/events/[event]',
          component: () => import('/app/src/pages/events/[event].vue'),
          children: [
            {
              path: '',
              name: '/events/[event]/',
              component: () => import('/app/src/pages/events/[event]/index.vue'),
              /* no children */
            },
            {
              path: 'cooks',
              name: '/events/[event]/cooks',
              component: () => import('/app/src/pages/events/[event]/cooks.vue'),
              /* no children */
            },
            {
              path: 'edit',
              name: '/events/[event]/edit',
              component: () => import('/app/src/pages/events/[event]/edit.vue'),
              children: [
                {
                  path: '',
                  name: '/events/[event]/edit/',
                  component: () => import('/app/src/pages/events/[event]/edit/index.vue'),
                  /* no children */
                }
              ],
            },
            {
              path: 'registrations',
              name: '/events/[event]/registrations',
              component: () => import('/app/src/pages/events/[event]/registrations.vue'),
              /* no children */
            }
          ],
        },
        {
          path: 'create',
          name: '/events/create',
          component: () => import('/app/src/pages/events/create.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_10
  ),
  _mergeRouteRecord(
    {
      path: '/external',
      name: '/external',
      component: () => import('/app/src/pages/external.vue'),
      children: [
        {
          path: 'contact-moments',
          name: '/external/contact-moments',
          component: () => import('/app/src/pages/external/contact-moments.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_11
  ),
  _mergeRouteRecord(
    {
      path: '/finances',
      name: '/finances',
      component: () => import('/app/src/pages/finances.vue'),
      children: [
        {
          path: 'balance-correctness',
          name: '/finances/balance-correctness',
          component: () => import('/app/src/pages/finances/balance-correctness.vue'),
          /* no children */
        },
        {
          path: 'balance-overview',
          name: '/finances/balance-overview',
          component: () => import('/app/src/pages/finances/balance-overview.vue'),
          /* no children */
        },
        {
          path: 'category',
          /* internal name: '/finances/category' */
          /* no component */
          children: [
            {
              path: ':key',
              name: '/finances/category/[key]',
              component: () => import('/app/src/pages/finances/category/[key].vue'),
              /* no children */
            }
          ],
        },
        {
          path: 'commission',
          name: '/finances/commission',
          component: () => import('/app/src/pages/finances/commission.vue'),
          /* no children */
        },
        {
          path: 'commission-table',
          name: '/finances/commission-table',
          component: () => import('/app/src/pages/finances/commission-table.vue'),
          /* no children */
        },
        {
          path: 'donations',
          name: '/finances/donations',
          component: () => import('/app/src/pages/finances/donations.vue'),
          /* no children */
        },
        _mergeRouteRecord(
          {
            path: 'legacy',
            name: '/finances/legacy',
            component: () => import('/app/src/pages/finances/legacy.vue'),
            children: [
              {
                path: 'commission',
                name: '/finances/legacy/commission',
                component: () => import('/app/src/pages/finances/legacy/commission.vue'),
                /* no children */
              },
              {
                path: 'commission-table',
                name: '/finances/legacy/commission-table',
                component: () => import('/app/src/pages/finances/legacy/commission-table.vue'),
                /* no children */
              },
              {
                path: 'year-overview',
                name: '/finances/legacy/year-overview',
                component: () => import('/app/src/pages/finances/legacy/year-overview.vue'),
                /* no children */
              }
            ],
          },
          _definePage_default_13
        ),
        {
          path: 'meals',
          name: '/finances/meals',
          component: () => import('/app/src/pages/finances/meals.vue'),
          /* no children */
        },
        {
          path: 'totals',
          name: '/finances/totals',
          component: () => import('/app/src/pages/finances/totals.vue'),
          /* no children */
        },
        {
          path: 'year-overview',
          name: '/finances/year-overview',
          component: () => import('/app/src/pages/finances/year-overview.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_12
  ),
  {
    path: '/login',
    name: '/login',
    component: () => import('/app/src/pages/login.vue'),
    /* no children */
  },
  _mergeRouteRecord(
    {
      path: '/matches',
      name: '/matches',
      component: () => import('/app/src/pages/matches.vue'),
      children: [
        {
          path: '',
          name: '/matches/',
          component: () => import('/app/src/pages/matches/index.vue'),
          /* no children */
        },
        {
          path: ':match',
          name: '/matches/[match]',
          component: () => import('/app/src/pages/matches/[match].vue'),
          children: [
            {
              path: '',
              name: '/matches/[match]/',
              component: () => import('/app/src/pages/matches/[match]/index.vue'),
              /* no children */
            },
            {
              path: 'contact-moments',
              name: '/matches/[match]/contact-moments',
              component: () => import('/app/src/pages/matches/[match]/contact-moments.vue'),
              /* no children */
            },
            {
              path: 'edit',
              name: '/matches/[match]/edit',
              component: () => import('/app/src/pages/matches/[match]/edit.vue'),
              children: [
                {
                  path: '',
                  name: '/matches/[match]/edit/',
                  component: () => import('/app/src/pages/matches/[match]/edit/index.vue'),
                  /* no children */
                },
                {
                  path: 'pause',
                  name: '/matches/[match]/edit/pause',
                  component: () => import('/app/src/pages/matches/[match]/edit/pause.vue'),
                  /* no children */
                },
                {
                  path: 'planning',
                  name: '/matches/[match]/edit/planning',
                  component: () => import('/app/src/pages/matches/[match]/edit/planning.vue'),
                  /* no children */
                },
                {
                  path: 'price',
                  name: '/matches/[match]/edit/price',
                  component: () => import('/app/src/pages/matches/[match]/edit/price.vue'),
                  /* no children */
                },
                {
                  path: 'stop',
                  name: '/matches/[match]/edit/stop',
                  component: () => import('/app/src/pages/matches/[match]/edit/stop.vue'),
                  /* no children */
                }
              ],
            },
            {
              path: 'history',
              name: '/matches/[match]/history',
              component: () => import('/app/src/pages/matches/[match]/history.vue'),
              /* no children */
            },
            {
              path: 'meals',
              name: '/matches/[match]/meals',
              component: () => import('/app/src/pages/matches/[match]/meals.vue'),
              /* no children */
            }
          ],
        },
        {
          path: 'match-monitoring',
          name: '/matches/match-monitoring',
          component: () => import('/app/src/pages/matches/match-monitoring.vue'),
          /* no children */
        },
        {
          path: 'reporting',
          name: '/matches/reporting',
          component: () => import('/app/src/pages/matches/reporting.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_14
  ),
  _mergeRouteRecord(
    {
      path: '/matching',
      name: '/matching',
      component: () => import('/app/src/pages/matching.vue'),
      children: [
        {
          path: '',
          name: '/matching/',
          component: () => import('/app/src/pages/matching/index.vue'),
          /* no children */
        },
        {
          path: ':matching',
          name: '/matching/[matching]',
          component: () => import('/app/src/pages/matching/[matching].vue'),
          children: [
            {
              path: '',
              name: '/matching/[matching]/',
              component: () => import('/app/src/pages/matching/[matching]/index.vue'),
              /* no children */
            },
            {
              path: 'add-match',
              /* internal name: '/matching/[matching]/add-match' */
              /* no component */
              children: [
                {
                  path: ':cook',
                  name: '/matching/[matching]/add-match/[cook]',
                  component: () => import('/app/src/pages/matching/[matching]/add-match/[cook].vue'),
                  /* no children */
                }
              ],
            },
            {
              path: 'cooks',
              name: '/matching/[matching]/cooks',
              component: () => import('/app/src/pages/matching/[matching]/cooks.vue'),
              /* no children */
            },
            {
              path: 'edit',
              name: '/matching/[matching]/edit',
              component: () => import('/app/src/pages/matching/[matching]/edit.vue'),
              children: [
                {
                  path: '',
                  name: '/matching/[matching]/edit/',
                  component: () => import('/app/src/pages/matching/[matching]/edit/index.vue'),
                  /* no children */
                },
                {
                  path: 'caretaker',
                  name: '/matching/[matching]/edit/caretaker',
                  component: () => import('/app/src/pages/matching/[matching]/edit/caretaker.vue'),
                  /* no children */
                },
                {
                  path: 'details',
                  name: '/matching/[matching]/edit/details',
                  component: () => import('/app/src/pages/matching/[matching]/edit/details.vue'),
                  /* no children */
                },
                {
                  path: 'foodie',
                  name: '/matching/[matching]/edit/foodie',
                  component: () => import('/app/src/pages/matching/[matching]/edit/foodie.vue'),
                  /* no children */
                },
                {
                  path: 'user',
                  name: '/matching/[matching]/edit/user',
                  component: () => import('/app/src/pages/matching/[matching]/edit/user.vue'),
                  /* no children */
                }
              ],
            },
            {
              path: 'history',
              name: '/matching/[matching]/history',
              component: () => import('/app/src/pages/matching/[matching]/history.vue'),
              /* no children */
            },
            {
              path: 'intake',
              name: '/matching/[matching]/intake',
              component: () => import('/app/src/pages/matching/[matching]/intake.vue'),
              /* no children */
            },
            {
              path: 'matches',
              name: '/matching/[matching]/matches',
              component: () => import('/app/src/pages/matching/[matching]/matches.vue'),
              /* no children */
            },
            {
              path: 'social-posts',
              name: '/matching/[matching]/social-posts',
              component: () => import('/app/src/pages/matching/[matching]/social-posts.vue'),
              /* no children */
            },
            {
              path: 'stop-reason',
              name: '/matching/[matching]/stop-reason',
              component: () => import('/app/src/pages/matching/[matching]/stop-reason.vue'),
              /* no children */
            },
            {
              path: 'suggest',
              /* internal name: '/matching/[matching]/suggest' */
              /* no component */
              children: [
                {
                  path: ':cook',
                  name: '/matching/[matching]/suggest/[cook]',
                  component: () => import('/app/src/pages/matching/[matching]/suggest/[cook].vue'),
                  /* no children */
                }
              ],
            }
          ],
        },
        {
          path: 'problem-areas',
          name: '/matching/problem-areas',
          component: () => import('/app/src/pages/matching/problem-areas.vue'),
          /* no children */
        },
        {
          path: 'reporting',
          name: '/matching/reporting',
          component: () => import('/app/src/pages/matching/reporting.vue'),
          /* no children */
        },
        {
          path: 'social-posts',
          name: '/matching/social-posts',
          component: () => import('/app/src/pages/matching/social-posts.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_15
  ),
  _mergeRouteRecord(
    {
      path: '/meals',
      name: '/meals',
      component: () => import('/app/src/pages/meals.vue'),
      children: [
        {
          path: ':meal',
          /* internal name: '/meals/[meal]' */
          /* no component */
          children: [
            {
              path: 'redirect',
              name: '/meals/[meal]/redirect',
              component: () => import('/app/src/pages/meals/[meal]/redirect.vue'),
              /* no children */
            },
            {
              path: 'user-wallets',
              name: '/meals/[meal]/user-wallets',
              component: () => import('/app/src/pages/meals/[meal]/user-wallets.vue'),
              /* no children */
            }
          ],
        }
      ],
    },
    _definePage_default_16
  ),
  {
    path: '/nextdoor-callback',
    name: '/nextdoor-callback',
    component: () => import('/app/src/pages/nextdoor-callback.vue'),
    /* no children */
  },
  _mergeRouteRecord(
    {
      path: '/payments',
      name: '/payments',
      component: () => import('/app/src/pages/payments.vue'),
      children: [
        {
          path: '',
          name: '/payments/',
          component: () => import('/app/src/pages/payments/index.vue'),
          /* no children */
        },
        {
          path: 'balances',
          name: '/payments/balances',
          component: () => import('/app/src/pages/payments/balances.vue'),
          /* no children */
        },
        {
          path: 'chargebacks',
          name: '/payments/chargebacks',
          component: () => import('/app/src/pages/payments/chargebacks.vue'),
          /* no children */
        },
        {
          path: 'non-meal-corrections',
          name: '/payments/non-meal-corrections',
          component: () => import('/app/src/pages/payments/non-meal-corrections.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_17
  ),
  {
    path: '/reporting',
    name: '/reporting',
    component: () => import('/app/src/pages/reporting.vue'),
    children: [
      _mergeRouteRecord(
        {
          path: 'absentee-rate',
          name: '/reporting/absentee-rate',
          component: () => import('/app/src/pages/reporting/absentee-rate.vue'),
          /* no children */
        },
        _definePage_default_18
      ),
      _mergeRouteRecord(
        {
          path: 'bugs',
          name: '/reporting/bugs',
          component: () => import('/app/src/pages/reporting/bugs.vue'),
          /* no children */
        },
        _definePage_default_19
      ),
      {
        path: 'cooks',
        name: '/reporting/cooks',
        component: () => import('/app/src/pages/reporting/cooks.vue'),
        /* no children */
      },
      _mergeRouteRecord(
        {
          path: 'enps',
          name: '/reporting/enps',
          component: () => import('/app/src/pages/reporting/enps.vue'),
          /* no children */
        },
        _definePage_default_20
      ),
      {
        path: 'event-requests',
        name: '/reporting/event-requests',
        component: () => import('/app/src/pages/reporting/event-requests.vue'),
        /* no children */
      },
      {
        path: 'match-requests',
        name: '/reporting/match-requests',
        component: () => import('/app/src/pages/reporting/match-requests.vue'),
        /* no children */
      },
      {
        path: 'matches',
        name: '/reporting/matches',
        component: () => import('/app/src/pages/reporting/matches.vue'),
        /* no children */
      },
      {
        path: 'meals',
        name: '/reporting/meals',
        component: () => import('/app/src/pages/reporting/meals.vue'),
        /* no children */
      },
      {
        path: 'municipalities',
        /* internal name: '/reporting/municipalities' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: ':municipality',
              name: '/reporting/municipalities/[municipality]',
              component: () => import('/app/src/pages/reporting/municipalities/[municipality].vue'),
              children: [
                {
                  path: 'cooks',
                  name: '/reporting/municipalities/[municipality]/cooks',
                  component: () => import('/app/src/pages/reporting/municipalities/[municipality]/cooks.vue'),
                  /* no children */
                },
                {
                  path: 'match-requests',
                  name: '/reporting/municipalities/[municipality]/match-requests',
                  component: () => import('/app/src/pages/reporting/municipalities/[municipality]/match-requests.vue'),
                  /* no children */
                }
              ],
            },
            _definePage_default_21
          )
        ],
      },
      {
        path: 'statistics',
        /* internal name: '/reporting/statistics' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: 'focus-municipalities',
              name: '/reporting/statistics/focus-municipalities',
              component: () => import('/app/src/pages/reporting/statistics/focus-municipalities.vue'),
              /* no children */
            },
            _definePage_default_22
          ),
          {
            path: 'matchmakers',
            /* internal name: '/reporting/statistics/matchmakers' */
            /* no component */
            children: [
              _mergeRouteRecord(
                {
                  path: '',
                  name: '/reporting/statistics/matchmakers/',
                  component: () => import('/app/src/pages/reporting/statistics/matchmakers/index.vue'),
                  /* no children */
                },
                _definePage_default_23
              ),
              {
                path: 'kpi-prediction',
                name: '/reporting/statistics/matchmakers/kpi-prediction',
                component: () => import('/app/src/pages/reporting/statistics/matchmakers/kpi-prediction.vue'),
                /* no children */
              },
              _mergeRouteRecord(
                {
                  path: 'kpis',
                  name: '/reporting/statistics/matchmakers/kpis',
                  component: () => import('/app/src/pages/reporting/statistics/matchmakers/kpis.vue'),
                  /* no children */
                },
                _definePage_default_24
              )
            ],
          }
        ],
      },
      {
        path: 'surveys',
        name: '/reporting/surveys',
        component: () => import('/app/src/pages/reporting/surveys.vue'),
        /* no children */
      },
      {
        path: 'unique-matches',
        name: '/reporting/unique-matches',
        component: () => import('/app/src/pages/reporting/unique-matches.vue'),
        /* no children */
      },
      {
        path: 'user-wallets',
        name: '/reporting/user-wallets',
        component: () => import('/app/src/pages/reporting/user-wallets.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
    {
      path: '/rock',
      name: '/rock',
      component: () => import('/app/src/pages/rock.vue'),
      /* no children */
    },
    _definePage_default_25
  ),
  _mergeRouteRecord(
    {
      path: '/secret-test-page',
      name: '/secret-test-page',
      component: () => import('/app/src/pages/secret-test-page.vue'),
      children: [
        {
          path: '',
          name: '/secret-test-page/',
          component: () => import('/app/src/pages/secret-test-page/index.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_26
  ),
  _mergeRouteRecord(
    {
      path: '/settings',
      name: '/settings',
      component: () => import('/app/src/pages/settings.vue'),
      children: [
        _mergeRouteRecord(
          {
            path: '',
            name: '/settings/',
            component: () => import('/app/src/pages/settings/index.vue'),
            /* no children */
          },
          _definePage_default_28
        ),
        _mergeRouteRecord(
          {
            path: 'contact-moments',
            name: '/settings/contact-moments',
            component: () => import('/app/src/pages/settings/contact-moments.vue'),
            /* no children */
          },
          _definePage_default_29
        ),
        _mergeRouteRecord(
          {
            path: 'local-targets',
            name: '/settings/local-targets',
            component: () => import('/app/src/pages/settings/local-targets.vue'),
            /* no children */
          },
          _definePage_default_30
        ),
        _mergeRouteRecord(
          {
            path: 'meal-wishes',
            name: '/settings/meal-wishes',
            component: () => import('/app/src/pages/settings/meal-wishes.vue'),
            /* no children */
          },
          _definePage_default_31
        ),
        {
          path: 'national-targets',
          name: '/settings/national-targets',
          component: () => import('/app/src/pages/settings/national-targets.vue'),
          /* no children */
        },
        _mergeRouteRecord(
          {
            path: 'signup-references',
            name: '/settings/signup-references',
            component: () => import('/app/src/pages/settings/signup-references.vue'),
            /* no children */
          },
          _definePage_default_32
        ),
        _mergeRouteRecord(
          {
            path: 'stop-reasons',
            name: '/settings/stop-reasons',
            component: () => import('/app/src/pages/settings/stop-reasons.vue'),
            /* no children */
          },
          _definePage_default_33
        ),
        _mergeRouteRecord(
          {
            path: 'tags',
            name: '/settings/tags',
            component: () => import('/app/src/pages/settings/tags.vue'),
            /* no children */
          },
          _definePage_default_34
        )
      ],
    },
    _definePage_default_27
  ),
  _mergeRouteRecord(
    {
      path: '/surveys',
      name: '/surveys',
      component: () => import('/app/src/pages/surveys.vue'),
      children: [
        {
          path: '',
          name: '/surveys/',
          component: () => import('/app/src/pages/surveys/index.vue'),
          /* no children */
        },
        {
          path: 'analysis',
          name: '/surveys/analysis',
          component: () => import('/app/src/pages/surveys/analysis.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_35
  ),
  _mergeRouteRecord(
    {
      path: '/teamdag-quiz',
      name: '/teamdag-quiz',
      component: () => import('/app/src/pages/teamdag-quiz.vue'),
      /* no children */
    },
    _definePage_default_36
  ),
  _mergeRouteRecord(
    {
      path: '/to-do',
      name: '/to-do',
      component: () => import('/app/src/pages/to-do.vue'),
      children: [
        _mergeRouteRecord(
          {
            path: '',
            name: '/to-do/',
            component: () => import('/app/src/pages/to-do/index.vue'),
            /* no children */
          },
          _definePage_default_38
        ),
        {
          path: 'automatic-reminders',
          name: '/to-do/automatic-reminders',
          component: () => import('/app/src/pages/to-do/automatic-reminders.vue'),
          /* no children */
        },
        {
          path: 'daily-overview',
          name: '/to-do/daily-overview',
          component: () => import('/app/src/pages/to-do/daily-overview.vue'),
          /* no children */
        },
        {
          path: 'monthly-overview',
          name: '/to-do/monthly-overview',
          component: () => import('/app/src/pages/to-do/monthly-overview.vue'),
          /* no children */
        },
        {
          path: 'upcoming-reminders',
          name: '/to-do/upcoming-reminders',
          component: () => import('/app/src/pages/to-do/upcoming-reminders.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_37
  ),
  _mergeRouteRecord(
    {
      path: '/users',
      name: '/users',
      component: () => import('/app/src/pages/users.vue'),
      children: [
        {
          path: '',
          name: '/users/',
          component: () => import('/app/src/pages/users/index.vue'),
          /* no children */
        },
        {
          path: ':user',
          name: '/users/[user]',
          component: () => import('/app/src/pages/users/[user].vue'),
          children: [
            {
              path: 'balance-history',
              name: '/users/[user]/balance-history',
              component: () => import('/app/src/pages/users/[user]/balance-history.vue'),
              /* no children */
            },
            {
              path: 'edit',
              name: '/users/[user]/edit',
              component: () => import('/app/src/pages/users/[user]/edit.vue'),
              children: [
                {
                  path: '',
                  name: '/users/[user]/edit/',
                  component: () => import('/app/src/pages/users/[user]/edit/index.vue'),
                  /* no children */
                },
                {
                  path: 'caretaker',
                  name: '/users/[user]/edit/caretaker',
                  component: () => import('/app/src/pages/users/[user]/edit/caretaker.vue'),
                  /* no children */
                },
                {
                  path: 'cook',
                  name: '/users/[user]/edit/cook',
                  component: () => import('/app/src/pages/users/[user]/edit/cook.vue'),
                  /* no children */
                },
                {
                  path: 'foodie',
                  name: '/users/[user]/edit/foodie',
                  component: () => import('/app/src/pages/users/[user]/edit/foodie.vue'),
                  /* no children */
                }
              ],
            },
            {
              path: 'ex-matchmaker-flow',
              name: '/users/[user]/ex-matchmaker-flow',
              component: () => import('/app/src/pages/users/[user]/ex-matchmaker-flow.vue'),
              /* no children */
            }
          ],
        },
        {
          path: 'bulk',
          name: '/users/bulk',
          component: () => import('/app/src/pages/users/bulk.vue'),
          /* no children */
        },
        {
          path: 'csv-export',
          name: '/users/csv-export',
          component: () => import('/app/src/pages/users/csv-export.vue'),
          /* no children */
        }
      ],
    },
    _definePage_default_39
  ),
  {
    path: '/vogs',
    name: '/vogs',
    component: () => import('/app/src/pages/vogs.vue'),
    children: [
      {
        path: 'new',
        name: '/vogs/new',
        component: () => import('/app/src/pages/vogs/new.vue'),
        /* no children */
      },
      {
        path: 'received',
        name: '/vogs/received',
        component: () => import('/app/src/pages/vogs/received.vue'),
        /* no children */
      },
      {
        path: 'requested',
        name: '/vogs/requested',
        component: () => import('/app/src/pages/vogs/requested.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

