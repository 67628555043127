<script
  setup
  lang="ts"
  generic="
    Data extends object,
    Keys extends string = string,
    RouterValue extends Data | undefined = undefined,
    IdKey extends string = string
  "
>
import {RecycleScroller} from 'vue-virtual-scroller'
import type {
  ComponentClass,
  CssHeightProp,
  FAIconName,
  VTableColumn,
  VTableColumns,
} from '/@src/types/elements-ui'
import type {AbstractId, KeyOfType} from '/@src/types/utils'
import {
  getFlexTableColumnClass,
  getFlexTableCellClass,
  getFlexTableColumnClasses,
} from '/@src/utils/tables'

interface VTableSlots {
  'toolbar-left'?: (props: {}) => any
  'toolbar-right'?: (props: {}) => any
  'item-select-popup'?: (props: { items: AbstractId<IdKey>[] }) => any
  'header-column'?: (props: { column: VTableColumn<Keys, RouterValue> }) => any
  'pre-body-cell'?: (props: {
    index: number
    column: VTableColumn<Keys, RouterValue>
  }) => any
  'body-cell'?: (props: {
    row: Data
    column: VTableColumn<Keys, RouterValue>
    index: number
  }) => any
  action?: (props: { row: Data }) => any
  'placeholder-cell'?: (props: { column: VTableColumn<Keys, RouterValue> }) => any
  'no-results'?: (props: { hasLoaded: boolean }) => any
}

interface VTableProps {
  columns: VTableColumns<Keys, RouterValue>
  data: Data[]
  idName?: KeyOfType<Data, AbstractId<string>>
  loading?: boolean
  showNoResults?: boolean | FAIconName
  virtual?: boolean
  automaticVirtual?: boolean
  itemSize?: number
  tableHeight?: CssHeightProp
  actionsName?: string
  noBorder?: boolean
  hideHeader?: boolean
  numberOfPreBodyCellRows?: number
  maxPlaceholders?: number
  toolbarClass?: ComponentClass
}

defineSlots<VTableSlots>()
const props = withDefaults(defineProps<VTableProps>(), {
  tableHeight: undefined,
  idName: undefined,
  itemSize: 60,
  actionsName: 'Acties',
  numberOfPreBodyCellRows: 1,
  maxPlaceholders: 20,
})

const selectedItems = defineModel<AbstractId<IdKey>[]>('selectedItems', {
  required: false,
  default: undefined,
})

const selectedAllItems = ref(false)

const isVirtual = computed(
  () => props.virtual || (props.automaticVirtual && props.data.length > 30),
)

const canSelectItems = !!selectedItems.value && !!props.idName

let isUpdatingFromSelectedAllItems = false

const selectItems = () => {
  if (!selectedItems.value) {
    return
  }
  const allIsSelected = selectedItems.value.length === props.data.length

  if (allIsSelected) {
    selectedItems.value = []
  } else {
    // props.idName is guaranteed to exist here, since this function is only called when canSelectItems is true.
    // canSelectItems can only be true when props.idName exists.
    selectedItems.value = props.data.map(
      (item) => item[props.idName!] as AbstractId<IdKey>,
    )
  }

  selectedAllItems.value = allIsSelected
}

watch(selectedItems, (newValues) => {
  if (isUpdatingFromSelectedAllItems) {
    isUpdatingFromSelectedAllItems = false
    return
  }

  isUpdatingFromSelectedAllItems = false

  selectedAllItems.value = newValues?.length === props.data.length
})

const hasLoaded = ref(false)
watch(
  () => props.loading,
  (newValue) => (hasLoaded.value = (hasLoaded.value || newValue) ?? false),
)

const visibleColumns = computed(() =>
  props.columns.filter((column) => !toValue(column.hidden)),
)

const placeholderColumns = computed<VTableColumns<Keys, RouterValue>>(() =>
  props.columns.filter((column) => !column.skipForPlaceholder && !toValue(column.hidden)),
)
</script>

<template>
  <div class="flex-table-wrapper">
    <div
      v-if="$slots['toolbar-left'] || $slots['toolbar-right']"
      class="flex-table-toolbar"
      :class="toolbarClass"
    >
      <div class="flex w-1/2 justify-start">
        <slot name="toolbar-left"/>
      </div>
      <div class="flex w-1/2 justify-end">
        <slot name="toolbar-right"/>
      </div>
    </div>

    <template v-if="$slots['item-select-popup']">
      <Transition name="fade-fast" mode="in-out">
        <div
          v-if="selectedItems && selectedItems.length > 0"
          class="card rounded-lg shadow"
        >
          <slot name="item-select-popup" :items="selectedItems"/>
        </div>
      </Transition>
    </template>

    <div class="flex-table">
      <div v-if="!hideHeader" class="flex-table-header">
        <div class="flex-table-row">
          <div
            v-if="canSelectItems && !('checkbox' in columns)"
            class="flex-table-cell fit-content"
          >
            <VCheckbox v-model="selectedAllItems" value="1" circle @click="selectItems"/>
          </div>

          <div
            v-for="column in visibleColumns"
            :key="`col-${column.key}`"
            class="flex-table-cell"
            :class="[
              getFlexTableColumnClasses(column),
              getFlexTableColumnClass(column, 'headerClass'),
              getFlexTableColumnClass(column, 'widthClass'),
            ]"
          >
            <slot name="header-column" :column="column">
              <span>
                {{ column.label }}
              </span>
            </slot>
          </div>

          <div
            v-if="$slots['action'] && !('actions' in columns)"
            class="flex-table-cell fit-content justify-end"
          >
            {{ actionsName }}
          </div>
        </div>
      </div>
      <div class="flex-table-body">
        <template v-if="!loading && data.length > 0">
          <template v-if="$slots['pre-body-cell']">
            <div
              v-for="n in numberOfPreBodyCellRows"
              :key="`pre-body-row-${n}`"
              class="flex-table-row"
            >
              <div
                v-for="column in visibleColumns"
                :key="`pre-body-cell-col${column.key}`"
                class="flex-table-cell"
                :class="[
                  getFlexTableColumnClasses(column),
                  getFlexTableColumnClass(column, 'preRowClass'),
                  getFlexTableColumnClass(column, 'widthClass'),
                ]"
              >
                <slot name="pre-body-cell" :index="n" :column="column"/>
              </div>
            </div>
          </template>

          <RecycleScroller
            v-if="isVirtual"
            v-slot="{ item: row, index }: { item: Data; index: number }"
            :items="data"
            :key-field="idName"
            :item-size="itemSize"
            :page-mode="!tableHeight"
            :style="!!tableHeight && { height: tableHeight }"
            item-class="flex-table-row is-virtual"
          >
            <VTableRow
              v-model:selected-items="selectedItems"
              :row="row"
              :columns="visibleColumns"
              :index="index"
              :can-select-items
              :id-name="idName as KeyOfType<Data, AbstractId<string>>"
            >
              <template #body-cell="{ row, column, index }">
                <slot name="body-cell" :row="row" :column="column" :index="index"/>
              </template>
              <template #action="{ row }">
                <slot name="action" :row="row"/>
              </template>
            </VTableRow>
          </RecycleScroller>

          <div
            v-else
            v-for="(row, index) in data"
            :key="`table-${row[idName as keyof Data]}`"
            class="flex-table-row"
          >
            <VTableRow
              v-model:selected-items="selectedItems"
              :row="row"
              :columns="visibleColumns"
              :index="index"
              :can-select-items
              :id-name="idName as KeyOfType<Data, AbstractId<string>>"
            >
              <template #body-cell="{ row, column, index }">
                <slot name="body-cell" :row="row" :column="column" :index="index"/>
              </template>
              <template
                v-if="$slots['action'] && !('actions' in columns)"
                #action="{ row }"
              >
                <slot name="action" :row="row"/>
              </template>
            </VTableRow>
          </div>
        </template>

        <template v-else-if="loading">
          <div
            v-for="row in [...Array(maxPlaceholders).keys()]"
            :key="`placeholder-${row}`"
            class="flex-table-row"
          >
            <div
              v-if="canSelectItems && !('checkbox' in columns)"
              class="flex-table-cell w-1"
            >
              <VPlaceloadAvatar size="small" rounded="sm"/>
            </div>

            <div
              v-for="column in placeholderColumns"
              :key="`placeholder-col-${column.key}`"
              class="flex-table-cell"
              :class="[
                getFlexTableColumnClasses(column),
                getFlexTableCellClass(undefined, column),
              ]"
            >
              <slot name="placeholder-cell" :column="column">
                <VPlaceload class="mx-1"/>
              </slot>
            </div>

            <div
              v-if="$slots['action'] && !('actions' in columns)"
              class="flex-table-cell w-1 cursor-pointer justify-end"
            >
              <VPlaceloadAvatar/>
            </div>
          </div>
        </template>

        <template v-else-if="showNoResults && !loading && data.length === 0">
          <div class="p-12 !text-center align-middle">
            <VIcon
              :icon="
                typeof showNoResults === 'string'
                  ? showNoResults
                  : hasLoaded
                    ? 'fa-book-open'
                    : 'fa-search'
              "
              size="large"
            />
            <slot name="no-results" :has-loaded="hasLoaded"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
