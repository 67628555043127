<script setup lang="ts">
import { useReportingStore } from '/@src/stores/reporting'
import type { StatisticsReportingReport } from '/@src/types/reporting'
import { clone } from 'lodash'
import { useHead } from '@unhead/vue'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

useHead({
  title: 'Matchmakers Statistieken | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

const reportingStore = useReportingStore()
const adminUserStore = useAdminUsersStore()

const isLoading = ref(false)
const reportData = ref<StatisticsReportingReport[]>()

const loadData = async () => {
  isLoading.value = true
  const result = await reportingStore.getMatchmakerReport()
  isLoading.value = false

  if (result) {
    reportData.value = result
  }
}

const matchmakerReport = computed(() => {
  const matchmakerReport = reportData.value

  if (!matchmakerReport || matchmakerReport.length === 0) {
    return undefined
  }

  return clone(matchmakerReport)
    .filter((matchStatus) => adminUserStore.isMatchmaker(matchStatus.subject))
    .map((matchStatus) => {
      return {
        ...matchStatus,
        subject: adminUserStore.getMatchmaker(matchStatus.subject).firstname,
      }
    })
    .sort((matchStatusA, matchStatusB) => {
      const matchmakerA = adminUserStore.getMatchmaker(matchStatusA.subject)
      const matchmakerB = adminUserStore.getMatchmaker(matchStatusB.subject)

      return matchmakerA.firstname.localeCompare(matchmakerB.firstname)
    })
})

onMounted(() => {
  loadData()
})
</script>

<template>
  <VLoader :active="isLoading">
    <StatisticsReportingTable v-if="matchmakerReport" :data="matchmakerReport" />
  </VLoader>
</template>

<style scoped></style>
