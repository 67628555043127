<script setup lang="ts">
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import { getActivePinia } from 'pinia'
import { useBuggieStore } from '/@src/stores/buggie'
import { useSlack } from '/@src/composable/useSlack'
import { toast } from 'vue-sonner'
import { useAuthStore } from '/@src/stores/auth'
import { z } from 'zod'
import { FileStorage } from '/@src/utils/file-list'
import { useBugsStore } from '/@src/stores/bugs'
import { UserRoleEnum } from '/@src/types/users'
import { type Bug, bugPriorityIcon, bugStatusColor } from '/@src/types/bugReport'
import type { ModalSidebarItem } from '/@src/types/elements-ui'
import { dayjsUTC, formatDateAndTime } from '/@src/utils/date-formatter'
import { isManager } from '/@src/utils/entity-checks'
import { useTypedForm } from '/@src/composable/useTypedForm'
import type { SlackErrorReport, SlackPermalinkResult } from '/@src/types/slack'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

const isOpen = defineModel<boolean>('open', { required: true })

const authStore = useAuthStore()
const adminUserStore = useAdminUsersStore()
const bugStore = useBugsStore()

const route = useRoute()
const router = useRouter()

const checkList = reactive({
  hasRefreshed: false,
  hasLoggedOut: false,
  hasCheckedActiveBugs: false,
})

const activeBugs = ref<Bug[]>([])

const isLoading = ref(false)
const postSlackMessage = ref(true)

const fileStorage = new FileStorage([
  'image/png',
  'image/jpeg',
  'image/gif',
  'video/mp4',
  'video/x-msvideo', // AVI
  'video/quicktime', // MOV
])

const { postMessage } = useSlack()

const hasGoneThroughCheckList = computed(() => Object.values(checkList).every((p) => p))

const hasDeveloperRole = computed(() => authStore.hasRole(UserRoleEnum.Developer))

watch(
  () => isOpen.value,
  async () => {
    if (isOpen.value) {
      const result = await bugStore.getActiveBugs()
      if (result) {
        activeBugs.value = result
      }
    }
  },
  { immediate: true },
)

const activeBugSidebarItems = computed<ModalSidebarItem[]>(() => {
  if (!isManager.value) {
    return []
  }

  return activeBugs.value.map((bug) => {
    return {
      key: `bug-sidebar-item-${bug.id}`,
      title: bug.expectedOutcome,
      color: bugStatusColor[bug.status],
      icon: bugPriorityIcon[bug.priority],
      subtitle: formatDateAndTime(bug.createdAt),
    }
  })
})

const onRefresh = async () => {
  window.location.href += '?eraseCache=true'
}

const onLogout = async () => {
  const isAdminMode = !!authStore.adminUser

  await authStore.logOut()
  toast.success('Uitgelogd.')

  if (!isAdminMode) {
    await router.push({ name: '/login' })
  }
}

const { handleSubmit, resetForm, formProps } = useTypedForm<SlackErrorReport>({
  id: 'error-reporting-modal',
  schema: {
    reproductionSteps: z.string().min(1),
    expectedOutcome: z.string().min(1),
    actualOutcome: z.string().min(1),
    link: z
      .string()
      .refine((value) => !hasDeveloperRole.value || (hasDeveloperRole.value && !!value)),
  },
  initialValues: {
    reproductionSteps: '',
    expectedOutcome: '',
    actualOutcome: '',
    link: window.location.href,
  },
})

watch(hasGoneThroughCheckList, (newValue) => {
  if (newValue) {
    resetForm({
      values: {
        reproductionSteps: '',
        expectedOutcome: '',
        actualOutcome: '',
        link: window.location.href,
      },
    })
  }
})

const sendSlackMessage = handleSubmit(async (values) => {
  isLoading.value = true

  const appElement = document.getElementById('app')

  if (!appElement) {
    isLoading.value = false
    return
  }

  const routeName = route.name.replaceAll('/', '').replaceAll('-', '_')

  const fileName = `${routeName}_${dayjsUTC().format('YYYY_MM_DD_HH_mm_ss')}`

  const slackId = adminUserStore.adminUser?.slackId

  let blob: Blob | null
  try {
    const canvas = await html2canvas(appElement)
    blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve))
  } catch {
    blob = null
  }

  const zip = new JSZip()

  if (blob) {
    zip.file(`screenshot-${fileName}.png`, blob)
  }

  if (getActivePinia()) {
    const state = getActivePinia()?.state.value
    const piniaDataDump = JSON.stringify(state, undefined, 4)
    zip.file(`data-dump-${fileName}.json`, piniaDataDump)

    const buggieStore = useBuggieStore()

    const networkErrorsDump = JSON.stringify(
      buggieStore.networkErrors.slice().reverse(),
      undefined,
      4,
    )
    zip.file(`network-errors-dump-${fileName}.json`, networkErrorsDump)
    const googleApiDump = JSON.stringify(
      buggieStore.googleApiErrors.slice().reverse(),
      undefined,
      4,
    )
    zip.file(`google-api-errors-dump-${fileName}.json`, googleApiDump)

    const validationErrorDump = JSON.stringify(buggieStore.validationErrors, undefined, 4)
    zip.file(`validation-error-dump-${fileName}.json`, validationErrorDump)
  }

  const zipFile = await zip.generateAsync({ type: 'blob' })

  let permalinkResponse: SlackPermalinkResult | false = false
  const file = new File([zipFile], `error_${fileName}.zip`)

  if (postSlackMessage.value) {
    permalinkResponse = await postMessage(
      file,
      fileStorage.file,
      values,
      window.location.href,
      slackId ?? undefined,
    )
  }

  if (authStore.user) {
    await bugStore.createBug({
      ...values,
      userId: authStore.user.id,
      linkToSlackMessage: permalinkResponse ? permalinkResponse?.permalink : null,
    })
  }

  isLoading.value = false
  if (permalinkResponse) {
    toast.success('Bug report verstuurd')
    isOpen.value = false

    resetForm({
      values: {
        reproductionSteps: '',
        expectedOutcome: '',
        actualOutcome: '',
        link: window.location.href,
      },
    })
    checkList.hasRefreshed = false
    checkList.hasLoggedOut = false
    checkList.hasCheckedActiveBugs = false
  } else if (!permalinkResponse && postSlackMessage.value) {
    toast.error('Er ging iets mis bij het versturen van het bug report #irony')
  }

  postSlackMessage.value = true
})
</script>

<template>
  <VModal
    v-model:open="isOpen"
    title="Buggie"
    size="2xl"
    :right-sidebar-items="activeBugSidebarItems"
    right-sidebar-title="Recente bugs"
    right-sidebar-width="20vw"
  >
    <template #content>
      <VCard>
        <template #title>
          <VIconBox
            icon="fa-face-frown-open"
            size="large"
            rounded
            color="info"
            class="pl-0"
          />
          <div class="title-subtitle ml-4 text-4xl">
            <span>Er gaat iets mis op pagina:</span>
            <span>{{ route.path }}</span>
          </div>
        </template>
        <template #content>
          <Transition name="slide-up" mode="out-in">
            <div v-if="!hasGoneThroughCheckList">
              <div class="prose">
                <p>
                  Hoi! Via dit scherm kan je gemakkelijk informatie bij elkaar verzamelen
                  om een fout te rapporteren. Voordat je bug report maakt, is het fijn als
                  je eerst de punten hieronder naloopt. Hiervoor kan je de knoppen
                  onderaan deze pop-up gebruiken. 😉
                </p>
                <p>
                  Heeft dat niet gewerkt? Dan verschijnen wat velden zodra alle checkboxes
                  aangevinkt zijn. Deze velden helpen Development met het sneller oplossen
                  van bugs en worden vanzelf meegestuurd in een bericht naar Slack. Ook
                  kan je dan een screenshot toevoegen, als je denkt dat dat Development
                  kan helpen. Er wordt dan automatisch een bericht in het #bugs kanaal van
                  Slack gestuurd met wat extra gegevens. Je wordt getagd in dit bericht in
                  Slack, zodat je gelijk updates krijgt over de bug.
                </p>
              </div>

              <div class="columns multiline">
                <VField class="column full !mb-0 !pb-0">
                  <VCheckbox
                    v-model="checkList.hasRefreshed"
                    value="has-refreshed"
                    label="Ik heb de pagina herladen met de knop hieronder"
                    size="large"
                  />
                </VField>
                <VField class="column full !my-0 !py-0">
                  <VCheckbox
                    v-model="checkList.hasLoggedOut"
                    value="has-logged-out"
                    label="Ik heb uit- en ingelogd"
                    size="large"
                  />
                </VField>
                <VField class="column full !my-0 !py-0">
                  <VCheckbox
                    v-model="checkList.hasCheckedActiveBugs"
                    value="has-checked-active-bugs"
                    label="Ik heb in #bugs gekeken of mijn probleem al bekend is"
                    size="large"
                  />
                </VField>
              </div>
            </div>

            <FormWrapper v-else v-bind="formProps" class="columns" v-slot="{ k }">
              <div class="column half">
                <div class="columns multiline">
                  <div v-if="hasDeveloperRole" class="column full">
                    <VField label="Plaats een Slack bericht">
                      <VSwitchBlock
                        v-model="postSlackMessage"
                        name="post-slack-message"
                        left-label="Niet"
                        right-label="Wel"
                      />
                    </VField>
                  </div>

                  <div class="column full">
                    <FormInput
                      :name="k.reproductionSteps"
                      label="Wat probeerde je te doen?"
                    >
                      <p class="mb-2 ml-1">
                        Beschrijf welke stappen je nam. Bijvoorbeeld: Ik vulde het nieuwe
                        telefoonnummer in (0612345678) en klikte op opslaan.
                      </p>
                    </FormInput>
                  </div>

                  <div class="column full">
                    <FormInput
                      :name="k.expectedOutcome"
                      label="Wat verwachtte je dat er gebeurde?"
                    >
                      <p class="mb-2 ml-1">
                        Bijvoorbeeld: Ik verwachtte dat de gegevens zouden opslaan en ik
                        terug zou gaan naar de tabel van de gebruikers.
                      </p>
                    </FormInput>
                  </div>

                  <div class="column full">
                    <FormInput :name="k.actualOutcome" label="Wat gebeurde er echt?">
                      <p class="sm mb-2 ml-1">
                        Bijvoorbeeld: De gegevens sloegen niet op en ik kreeg een rode
                        melding rechtsonderin het scherm.
                      </p>
                    </FormInput>
                  </div>

                  <div v-show="hasDeveloperRole" class="column full">
                    <FormInput :name="k.link" label="Op welke pagina gaat het mis?">
                      <p class="mb-2 ml-1">Vul hier alles behalve de domeinnaam in.</p>
                    </FormInput>
                  </div>
                </div>
              </div>

              <div class="column half">
                <FileDropZone v-model="fileStorage" vertical />
              </div>
            </FormWrapper>
          </Transition>
        </template>
      </VCard>
    </template>
    <template #actions>
      <VButton color="warning" :loading="isLoading" @click="onRefresh">
        Pagina verversen
      </VButton>
      <VButton color="danger" :loading="isLoading" @click="onLogout"> Uitloggen </VButton>
      <VButton
        v-if="hasGoneThroughCheckList"
        color="primary"
        :loading="isLoading"
        @click="sendSlackMessage"
      >
        Bug versturen
      </VButton>
    </template>
  </VModal>
</template>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
