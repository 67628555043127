<script setup lang="ts">
import type { FAIconName, TailwindColor, VButtonProps } from '/@src/types/elements-ui'
import { omit } from 'lodash'

type VAnimatedButtonEmits = {
  click: []
}

interface VAnimatedButtonProps extends VButtonProps {
  icon: FAIconName
  time: number | `${number}`
  secondaryColor: TailwindColor
  minWidth: `${number}%`
  notAutomatic?: boolean
  noLabel?: boolean
}

const emits = defineEmits<VAnimatedButtonEmits>()
const props = defineProps<VAnimatedButtonProps>()

const cssSeconds = computed(() => `${props.time}s`)
const color = computed(() => `var(--${props.secondaryColor})`)

const animationTriggered = ref<boolean>(false)
const revertingAnimation = ref<boolean>(false)

const timeProp = computed(() =>
  typeof props.time === 'number' ? props.time : parseFloat(props.time),
)

watch(
  () => props.loading,
  (newValue) => {
    if (!props.notAutomatic) {
      return
    }

    if (newValue) {
      animationTriggered.value = true
    } else {
      revertingAnimation.value = true
      setTimeout(() => {
        revertingAnimation.value = false
        animationTriggered.value = false
      }, timeProp.value * 1000)
    }
  },
)

const triggerAnimation = () => {
  emits('click')

  if (props.notAutomatic) {
    return
  }

  animationTriggered.value = true
  setTimeout(() => {
    revertingAnimation.value = true
    setTimeout(() => {
      revertingAnimation.value = false
      animationTriggered.value = false
    }, timeProp.value * 1000)
  }, timeProp.value * 1500)
}
</script>

<template>
  <VButton
    :icon-left="noLabel ? undefined : icon"
    :icon-only="noLabel ? icon : undefined"
    class="is-animated-button"
    :class="[
      animationTriggered && 'is-animating',
      revertingAnimation && 'is-reverting',
      noLabel && 'show-icon',
    ]"
    v-bind="
      omit(props, [
        'iconLeft',
        'iconOnly',
        'time',
        'secondaryColor',
        'minWidth',
        'notAutomatic',
      ])
    "
    @click.prevent="triggerAnimation"
  >
    <slot />
  </VButton>
</template>

<style scoped>
@keyframes button-smaller {
  0% {
    width: 100%;
  }

  100% {
    width: v-bind(minWidth);
    background-color: v-bind(color);
  }
}

@keyframes button-larger {
  0% {
    width: v-bind(minWidth);
    background-color: v-bind(color);
  }

  100% {
    width: 100%;
  }
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text-disappear {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes image-appear {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes image-disappear {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.is-animated-button {
  position: relative;

  &.is-animating {
    animation: button-smaller v-bind(cssSeconds) ease-in-out;
    animation-fill-mode: forwards;

    &:not(.show-icon) {
      :deep(.button-label) {
        animation: text-disappear v-bind(cssSeconds) ease-in-out;
        animation-fill-mode: forwards;
      }

      :deep(.icon) {
        animation: image-appear v-bind(cssSeconds) ease-in-out;
        animation-fill-mode: forwards;
      }
    }

    &.is-reverting {
      animation: button-larger v-bind(cssSeconds) ease-in-out;

      &:not(.show-icon) {
        :deep(.button-label) {
          animation: text-appear v-bind(cssSeconds) ease-in-out;
        }

        :deep(.icon) {
          animation: image-disappear v-bind(cssSeconds) ease-in-out;
        }
      }
    }
  }

  :deep(.icon) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
  }

  &:not(.show-icon) {
    :deep(.icon) {
      opacity: 0;
    }
  }
}
</style>
