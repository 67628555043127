import type { DriverHook, DriveStep } from 'driver.js'
import { driver } from 'driver.js'
import type { Ref } from 'vue'
import type { FAIconName } from '/@src/types/elements-ui'
import type { NavigationGuard } from 'vue-router'
import type { MaybePromise } from '/@src/types/utils'

export interface TutorialProps {
  steps: DriveStep[]
}

type PromiseDriverHook = DriverHook | ((...args: Parameters<DriverHook>) => Promise<void>)

class OnboardingObserver {
  private driver: Ref<ReturnType<typeof driver> | undefined>
  private beforeStart: (() => MaybePromise<any>) | undefined
  private result: any | undefined

  private isActiveRef: Ref<boolean> = ref(false)

  public get isActive() {
    return this.isActiveRef
  }

  constructor() {
    this.driver = ref<ReturnType<typeof driver>>()
    this.beforeStart = undefined
  }

  hasTutorial = computed(() => !!this.driver.value)

  private transformSteps(
    steps: DriveStep[],
    moveNext: () => DriverHook,
    movePrev: () => DriverHook,
  ) {
    return steps.map((step) => {
      if (!step.popover?.onNextClick && !step.popover?.onPrevClick) {
        return step
      }

      if (step.popover.onNextClick) {
        const onNextClick = step.popover.onNextClick as PromiseDriverHook
        step.popover.onNextClick = async (...args: Parameters<DriverHook>) => {
          const { state } = args[2]
          const nextButton = state.popover?.nextButton
          nextButton?.classList.add('is-loading', 'driver-popover-btn-disabled')
          await onNextClick(...args)
          nextButton?.classList.remove('is-loading', 'driver-popover-btn-disabled')

          const move = moveNext()
          move(...args)
        }
      }

      if (step.popover.onPrevClick) {
        const onPrevClick = step.popover.onPrevClick as PromiseDriverHook
        step.popover.onPrevClick = async (...args: Parameters<DriverHook>) => {
          const { state } = args[2]
          const nextButton = state.popover?.previousButton
          nextButton?.classList.add('is-loading', 'driver-popover-btn-disabled')
          await onPrevClick(...args)
          nextButton?.classList.remove('is-loading', 'driver-popover-btn-disabled')

          const move = movePrev()
          move(...args)
        }
      }

      return step
    })
  }

  setTutorial = async <TOut = undefined>(props: {
    tutorial: TutorialProps
    beforeRouteLeave: (leaveGuard: NavigationGuard) => void
    beforeStart?: () => MaybePromise<TOut | false>
    onEnd?: (arg: TOut) => MaybePromise<void>
  }) => {
    const { tutorial, beforeRouteLeave, beforeStart, onEnd } = props

    this.beforeStart = beforeStart

    this.driver.value = driver({
      showProgress: true,
      steps: this.transformSteps(
        tutorial.steps,
        () => this.driver.value!.moveNext,
        () => this.driver.value!.movePrevious,
      ),
      progressText: 'Stap {{current}} van {{total}}',
      prevBtnText: ' ',
      nextBtnText: ' ',
      doneBtnText: ' ',
      onDestroyed: async () => {
        if (onEnd) {
          await onEnd(this.result as TOut)
        }

        this.isActiveRef.value = false
      },
      onPopoverRender: (popover, { config, state }) => {
        const previousButton = popover.previousButton
        this.createFooterButton(previousButton, false, false)

        const currentStep = (state.activeIndex ?? 0) + 1
        const totalSteps = config.steps?.length ?? 0

        const nextButton = popover.nextButton
        this.createFooterButton(nextButton, true, currentStep === totalSteps)

        popover.closeButton.innerHTML = ''
        popover.closeButton.classList.add('delete')
      },
    })

    const destroyFunction = async () => {
      this.driver.value = undefined
      this.result = undefined
      this.beforeStart = undefined
    }

    beforeRouteLeave(destroyFunction)
    return destroyFunction
  }

  private createFooterButton = (
    button: HTMLButtonElement,
    isNext: boolean,
    isDone: boolean,
  ) => {
    button.classList.add('button', 'is-normal')
    if (isDone) {
      button.classList.add('!bg-success', '!text-success-text')
    } else {
      button.classList.add('!bg-info', '!text-info-text')
    }

    let label: string
    let icon: FAIconName
    if (!isNext) {
      label = 'Vorige'
      icon = 'fa-arrow-left'
    } else if (isDone) {
      label = 'Sluiten'
      icon = 'fa-check'
    } else {
      label = 'Volgende'
      icon = 'fa-arrow-right'
    }

    const buttonIcon = document.createElement('i')
    buttonIcon.classList.add('fas', icon)

    const buttonIconSpan = document.createElement('span')
    buttonIconSpan.classList.add('icon')
    buttonIconSpan.appendChild(buttonIcon)

    const buttonTextSpan = document.createElement('span')
    buttonTextSpan.classList.add('button-label')
    buttonTextSpan.innerHTML = label

    if (!isNext) {
      button.appendChild(buttonIconSpan)
    }
    button.appendChild(buttonTextSpan)
    if (isNext) {
      button.appendChild(buttonIconSpan)
    }
  }

  start = async (): Promise<boolean> => {
    if (!this.driver.value) {
      return false
    }

    if (this.beforeStart) {
      this.result = await this.beforeStart()
      if (this.result === false) {
        return false
      }
    }

    this.isActiveRef.value = true
    this.driver.value.drive()

    return true
  }

  refresh = (): void => {
    this.driver.value?.refresh()
  }
}

export const onboarding = new OnboardingObserver()
