import { z } from 'zod'
import type {
  Gender,
  LanguageEnum,
  PressAvailability,
  UserId,
  UserInformation,
  UserProfile,
} from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import { PressFieldEnum } from '/@src/models/types'
import type { Dayjs } from 'dayjs'
import type { Caretaker, CaretakerId } from '/@src/types/caretaker'
import type { AbstractId, CompleteAddress, PaymentInformation } from '/@src/types/utils'
import type { MealWishConnection } from '/@src/types/meal-wishes'
import type { TagConnection } from '/@src/types/tags'
import type { SignupReferenceId } from '/@src/types/signup-references'

export type FoodieId = AbstractId<'foodie'>

export enum ReasonOptionEnum {
  Social = 'social',
  Health = 'health',
  Age = 'age',
  Money = 'money',
  LowEnergy = 'low_energy',
  EatingHealthier = 'eating_healthier',
  Caretaker = 'caretaker',
  Motivation = 'motivation',
  Other = 'other',
}

export enum OldReasonOptionEnum {
  OldNotSelected = 'old_not_selected',
  OldNoTime = 'old_no_time',
  OldDontLikeCooking = 'old_dont_like_cooking',
  OldNewDishes = 'old_new_dishes',
  OldNotVulnerable = 'old_new_not_vulnerable',
  OldVulnerable = 'old_new_vulnerable',
}

export const ReasonOptions = z.nativeEnum(ReasonOptionEnum)
export type ReasonOption = z.infer<typeof ReasonOptions>

export const OldReasonOptions = z.nativeEnum(OldReasonOptionEnum)
export type OldReasonOption = z.infer<typeof OldReasonOptions>

export type FullReasonOption = ReasonOption | OldReasonOption
export const FullReasonOptionsEnum = z.nativeEnum({
  ...ReasonOptionEnum,
  ...OldReasonOptionEnum,
})

export interface Foodie {
  id: FoodieId
  createdAt: Dayjs
  reasonOption: number
  reasonText: string | null
  userProfile: UserProfile
  caretaker?: Caretaker
  press: PressFieldEnum
  pressText: string | null
  ambassador: PressFieldEnum
  ambassadorText: string | null
  mailUpdatesAccepted: boolean
  bankaccountName?: string
  bankaccountNumber?: string
}

export interface UpdateFoodie {
  mailUpdatesAccepted: boolean
  reasonOption: ReasonOption | OldReasonOption
  reasonText: string | null
  bankaccountNumber: string | null
  bankaccountName: string | null
  press: PressAvailability
  pressText: string | null
  ambassador: PressAvailability
  ambassadorText: string | null
}

export interface NonUpdateFoodie extends UpdateFoodie {
  reasonOption: ReasonOption | OldReasonOption
  id: FoodieId
  userId: UserId
  caretakerId: CaretakerId | null
  fullName: string
  blockedAt: Dayjs | null
}

export interface MatchRequestFoodie {
  id: UserId
  foodieId: FoodieId
  caretakerId: CaretakerId | null

  fullName: string
  email: string
  phone: string
  phoneAlt: string | null

  gender: Gender
  birthdate: Dayjs
  lastLoginAt: Dayjs

  blockedAt: Dayjs | null

  role: UserRoleEnum.Foodie

  avatar: string

  locale: LanguageEnum
  address: CompleteAddress

  press: PressFieldEnum
  pressText: string | null
  ambassador: PressFieldEnum
  ambassadorText: string | null

  reasonOption: ReasonOptionEnum
  reasonText: string | null
  promotions: string[] | null

  tags: TagConnection[]

  paymentInformation: PaymentInformation
}

export interface FoodieInformation extends UserInformation {
  foodieId: FoodieId

  role: UserRoleEnum.Foodie

  mealWishes: MealWishConnection[]

  press: PressFieldEnum
  pressText: string | null

  ambassador: PressFieldEnum
  ambassadorText: string | null

  tags: TagConnection[]

  paymentInformation: PaymentInformation
}

export interface SuggestFoodie {
  id: FoodieId
  userId: UserId

  fullName: string
  email: string
  phone: string

  birthdate: Dayjs | null
  gender: Gender

  address: CompleteAddress

  amountOfPeople: number

  reasonText: string | null
  reasonOption: FullReasonOption

  signupReferenceId: SignupReferenceId | null
  signupReferenceText: string | null
}
