<script setup lang="ts">
import { copyStringToClipBoard, formatCurrency } from '/@src/utils/helpers'
import { z } from 'zod'
import { toast } from 'vue-sonner'
import { CopyIcon } from '/@src/models/standardIcons'
import { useTypedForm } from '/@src/composable/useTypedForm'

interface TrainCosts {
  priceInput: number
}

const isOpen = defineModel<boolean>('open', { required: true })

const { useField } = useTypedForm<TrainCosts>({
  id: 'train-costs',
  schema: {
    priceInput: z.number(),
  },
})

const { value: priceInput } = useField('priceInput')

const withoutTaxes = computed(() =>
  priceInput.value ? (priceInput.value / 109) * 100 : undefined,
)
const taxes = computed(() =>
  priceInput.value && withoutTaxes.value
    ? priceInput.value - withoutTaxes.value
    : undefined,
)

const copyWithoutTaxes = () => {
  if (!withoutTaxes.value) {
    return
  }

  copyStringToClipBoard(withoutTaxes.value.toFixed(2))
    .then(() => toast.success('Bedrag excl. BTW gekopieerd'))
    .catch(() => toast.error('Fout bij kopiëren'))
}

const copyTaxes = () => {
  if (!taxes.value) {
    return
  }

  copyStringToClipBoard(taxes.value.toFixed(2))
    .then(() => toast.success('BTW bedrag gekopieerd'))
    .catch(() => toast.error('Fout bij kopiëren'))
}
</script>

<template>
  <VModal v-model:open="isOpen" title="BTW Rekentool">
    <template #content>
      <VCard>
        <template #icon>
          <VIcon icon="fa-calculator" size="large" />
        </template>
        <template #content>
          <div class="prose mb-2">
            <h2>Hier kan je de btw van reiskosten berekenen voor Actan</h2>
            <p>
              Vul het totaal van de reiskosten in en de BTW en het bedrag excl. BTW worden
              berekend.
            </p>
            <p>Let op, controleer de bedragen nog wel voor de zekerheid. 🙂</p>
          </div>

          <div class="columns multiline">
            <div class="column full">
              <VField label="Totaal prijs (incl. BTW)" icon-left="fa-euro">
                <VInput v-model="priceInput" type="number" />
              </VField>
            </div>
            <div class="column half !flex justify-center">
              <VField label="Totaal prijs (excl. BTW)" control-class="flex items-center">
                <VTags>
                  <VTag size="large" color="success">
                    {{ withoutTaxes ? formatCurrency(withoutTaxes) : '€-' }}
                  </VTag>
                  <VTag size="large" color="link" @click="copyWithoutTaxes">
                    Kopiëren
                    <VIcon class="ml-2" :icon="CopyIcon" />
                  </VTag>
                </VTags>
              </VField>
            </div>
            <div class="column half !flex justify-center">
              <VField label="BTW" control-class="flexitems-center">
                <VTags>
                  <VTag size="large" color="success">
                    {{ taxes ? formatCurrency(taxes) : '€-' }}
                  </VTag>
                  <VTag size="large" color="link" @click="copyTaxes">
                    Kopiëren
                    <VIcon class="ml-2" :icon="CopyIcon" />
                  </VTag>
                </VTags>
              </VField>
            </div>
          </div>
        </template>
      </VCard>
    </template>
  </VModal>
</template>

<style scoped></style>
