<script setup lang="ts">
import { useAuthStore } from '/@src/stores/auth'
import { UserRoleEnum } from '/@src/types/users'

definePage({
  meta: {
    roles: 'external',
  },
})

const authStore = useAuthStore()

const route = useRoute()
const router = useRouter()

const openSpecificPage = () => {
  const externalRole = authStore.user?.roles.find((r) => r.role === UserRoleEnum.External)
  if (externalRole?.param) {
    router.push({
      name: `/external/${externalRole.param}`,
    } as any) // Route is dynamic so any cast is okay
  }
}

watch(
  () => authStore.user,
  (newValue) => {
    if (newValue && route.name === '/external') {
      openSpecificPage()
    }
  },
  { immediate: true },
)
watch(
  () => route.name,
  (newValue) => {
    if (newValue === '/external') {
      openSpecificPage()
    }
  },
  { immediate: true },
)
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Transition name="translate-page-x" mode="out-in">
      <KeepAlive>
        <Component :is="Component" />
      </KeepAlive>
    </Transition>
  </RouterView>
</template>

<style scoped></style>
