<script setup lang="ts" generic="T extends RadioBaseOption = RadioBaseOption">
import type { OptionsIconMap, RadioBaseOption } from '/@src/types/elements-ui'

interface VRadioIconProps {
  name: string
  options: OptionsIconMap<T>[]
  vertical?: boolean
  centered?: boolean
  disabled?: boolean
}

const props = defineProps<VRadioIconProps>()

const modelValue = defineModel<T>()

const filteredOptions = computed(() => props.options.filter((option) => !option.disabled))

const setValue = (id: T) => {
  if (props.disabled) {
    return
  }
  modelValue.value = id
}

const columnClasses = computed(() => {
  if (props.vertical && props.centered) {
    return 'flex-col items-center'
  } else if (props.vertical) {
    return 'flex-col items-start'
  } else if (props.centered) {
    return 'flex-row justify-center'
  } else {
    return 'flex-row justify-start'
  }
})
</script>

<template>
  <div class="columns radio-icon" :class="columnClasses">
    <div
      v-for="(option, index) in filteredOptions"
      :key="`${name}-radio-icon-option-${index}`"
      class="column !flex items-center"
      :class="[
        !disabled && 'cursor-pointer',
        centered && !vertical ? 'justify-center' : 'justify-start',
      ]"
      @click.prevent="setValue(option.id as T)"
      @keyup.prevent="setValue(option.id as T)"
    >
      <VIconBox
        :icon="option.icon"
        :icon-pack="option.iconPack"
        :color="modelValue === option.id ? 'info' : undefined"
        size="small"
        bordered
        :disabled
        :outlined="modelValue !== option.id"
      />
      <p
        class="radio-label"
        :class="[
          modelValue === option.id && 'font-bold',
          disabled && 'pointer-events-none opacity-60',
        ]"
      >
        {{ option.name }}
      </p>
    </div>
  </div>
</template>

<style scoped></style>
