<script setup lang="ts">
import type { ComponentSize } from '/@src/types/elements-ui'

export interface VPlaceloadAvatarProps {
  size?: ComponentSize | 'big' | 'xl'
}

const props = withDefaults(defineProps<VPlaceloadAvatarProps>(), {
  size: 'small',
})
</script>

<template>
  <div class="placeload-avatar" :class="[props.size && `is-${props.size}`]"></div>
</template>

<style></style>
