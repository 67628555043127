<script setup lang="ts">
import { computed, provide } from 'vue'
import { $FieldKey } from '/@src/types/inject-types'
import type {
  BaseTailwindColor,
  ComponentClass,
  FAIconName,
} from '/@src/types/elements-ui'

type VFieldEmits = {
  iconLeftClick: [value: Event]
  iconRightClick: [value: Event]
}

interface FieldProps {
  label?: string
  labelClass?: ComponentClass
  controlClass?: ComponentClass
  color?: BaseTailwindColor
  addons?: boolean
  iconLeft?: FAIconName
  clickableIconLeft?: boolean
  iconRight?: FAIconName
  clickableIconRight?: boolean
  message?: string
}

const emits = defineEmits<VFieldEmits>()
const props = defineProps<FieldProps>()

const color = computed(() => props.color)

provide($FieldKey, {
  color: color,
})
</script>

<template>
  <div class="field" :class="[(addons || $slots['addon']) && 'has-addons']">
    <label v-if="label || $slots['label']" class="field-label" :class="labelClass">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="field-inner">
      <div
        class="field-control"
        :class="[
          (iconLeft || $slots['icon-left']) && 'has-icons-left',
          (iconRight || $slots['icon-right']) && 'has-icons-right',
          (addons || $slots['addon']) && 'is-expanded',
          controlClass,
        ]"
      >
        <slot />

        <slot name="icon-left">
          <VIcon
            v-if="iconLeft"
            :icon="iconLeft"
            size="small"
            class="is-left"
            :override-clickable="!clickableIconLeft"
            @click.prevent="emits('iconLeftClick', $event)"
          >
          </VIcon>
        </slot>

        <slot name="icon-right">
          <VIcon
            v-if="iconRight"
            :icon="iconRight"
            size="small"
            class="is-right"
            :override-clickable="!clickableIconRight"
            @click.prevent="emits('iconRightClick', $event)"
          >
          </VIcon>
        </slot>
      </div>
      <div v-if="$slots['addon']" class="field-addons">
        <slot name="addon" />
      </div>
    </div>
    <span v-if="message" class="field-message" :class="color && `text-${color}`">
      {{ message }}
    </span>
  </div>
</template>

<style scoped></style>
