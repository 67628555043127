<script setup lang="ts">
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { SimpleWebSocketMessage } from '/@src/types/webSockets'
import { UserRoleEnum } from '/@src/types/users'
import { toast } from 'vue-sonner'
import { useAuthStore } from '/@src/stores/auth'
import { useGlobalsStore } from '/@src/stores/global'
import { useTasksStore } from '/@src/stores/tasks'
import type { MatchmakerStatisticsResult } from '/@src/types/dashboard'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { enableFestiveOrnaments } from '/@src/utils/entity-checks'

interface GlobalsProps {
  hideBars?: boolean
}

const props = defineProps<GlobalsProps>()

const globalsStore = useGlobalsStore()
const authStore = useAuthStore()

const taskStore = useTasksStore()

const focusMunicipalityStore = useFocusMunicipalitiesStore()

const lockdownModalIsOpen = ref(false)
const lockdownReason = ref<string>()
const lockdownAdmin = (message: { reason?: string }) => {
  lockdownModalIsOpen.value = true
  lockdownReason.value = message.reason
}

const websocketCallback = (message: SimpleWebSocketMessage) => {
  if (
    (!message.userId && authStore.hasRole(UserRoleEnum.Developer)) ||
    (message.userId && authStore.user?.id === message.userId)
  ) {
    toast.success(message.message)
  }
}

const updateMatchmakerStats = (stats: { statistics: MatchmakerStatisticsResult }) => {
  globalsStore.matchmakerStatistics = stats.statistics
}

useWebSocket({ event: 'AdminMessage', callback: websocketCallback })
useWebSocket({ event: 'MatchmakerStatistics', callback: updateMatchmakerStats })
useWebSocket({ event: 'LockdownAdmin', callback: lockdownAdmin })

const initialDeficit = -230

const currentDeficit = computed(
  () =>
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.matchActual ?? 0) -
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.normalisedTarget ?? 0),
)

const deficitDifference = computed(() =>
  currentDeficit.value === 0 ? 0 : Math.round(initialDeficit - currentDeficit.value),
)
const deficitDifferencePercentage = computed(
  () => `${50 + (deficitDifference.value / initialDeficit) * 50}%`,
)
const deficitDifferenceLabel = computed(() => {
  if (Math.abs(deficitDifference.value) < 0.5) {
    return '0'
  } else if (deficitDifference.value < 0) {
    return `+${Math.abs(deficitDifference.value).toFixed(0)}`
  } else {
    return `-${deficitDifference.value.toFixed(0)}`
  }
})

/**
 * Deficit difference of +2 = Bronze
 * Deficit difference of +72 = Silver
 * Deficit difference of +122 = Gold
 */
const deficitColour = computed(() => {
  if (deficitDifference.value === 0) {
    return 'bg-gray-light'
  } else if (deficitDifference.value <= -122) {
    return 'bg-gold'
  } else if (deficitDifference.value <= -72) {
    return 'bg-slver'
  } else if (deficitDifference.value <= -2) {
    return 'bg-bronze'
  } else {
    return 'bg-danger'
  }
})

const showFestiveOrnaments = computed(
  () => globalsStore.showFestiveOrnaments && enableFestiveOrnaments.value,
)

onMounted(async () => {
  const isExternalUser =
    authStore.hasRole(UserRoleEnum.External) && !authStore.hasRole(UserRoleEnum.Admin)
  if (isExternalUser) {
    globalsStore.hasLoaded = true
  } else if (!globalsStore.doneLoading) {
    await globalsStore.fetchGlobalData()
  }

  const userHash = await authStore.fetchUserHash()

  if (userHash !== authStore.userHash || !authStore.user) {
    await authStore.check()
    authStore.userHash = userHash
  }
})
</script>

<template>
  <div>
    <template v-if="!hideBars && globalsStore.doneLoading">
      <HeadlessKPIBars>
        <template #bar-1="{ value, targets, texts, color, disabled }">
          <GlobalTargetBar
            :targets
            :value
            :color
            :disable-targets="disabled"
            :texts
            :bottom="0"
          />
        </template>

        <template #bar-2="{ value, targets, texts, color, disabled }">
          <GlobalTargetBar
            :targets
            :value
            :color
            :disable-targets="disabled"
            :texts
            bottom="-0.23rem"
          />
        </template>

        <template #bar-3="{ value, targets, texts, color, disabled }">
          <GlobalTargetBar
            :targets
            :value
            :color
            :disable-targets="disabled"
            :texts
            bottom="-0.46rem"
          />
        </template>
      </HeadlessKPIBars>

      <div class="deficit-bar hidden bg-gray">
        <div
          v-tooltip.left="
            `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
          "
          class="mid-point"
          :class="deficitDifference < 0 ? deficitColour : 'bg-gray'"
          :style="{ width: '50%', zIndex: deficitDifference < 0 ? 2 : 1 }"
        ></div>

        <div
          v-tooltip.left="
            `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
          "
          class="current-status"
          :class="deficitColour"
          :style="{
            width: deficitDifferencePercentage,
            zIndex: deficitDifference < 0 ? 1 : 2,
          }"
        ></div>
      </div>
    </template>

    <Ornaments v-if="showFestiveOrnaments" />

    <EmojiPopper />

    <LockdownModal
      v-if="lockdownModalIsOpen"
      v-model:open="lockdownModalIsOpen"
      :reason="lockdownReason"
      @close="lockdownModalIsOpen = false"
    />

    <QuickAdminTaskModal
      v-model:open="taskStore.taskModalParams.open"
      :taskable-id="taskStore.taskModalParams.taskableId"
      :taskable-type="taskStore.taskModalParams.taskableType"
      @close="taskStore.closeTaskModal"
    />
  </div>
</template>

<style scoped>
.deficit-bar {
  position: absolute;
  left: 0;
  height: 0.2rem;
  bottom: -0.28rem;
  width: 100vw;

  .mid-point {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.2rem;
    border-right: 2px solid black;
  }

  .current-status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.2rem;
    border-right: 2px solid black;
  }
}
</style>
