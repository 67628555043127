<script setup lang="ts">
import type { ComponentClass, FAIconName } from '/@src/types/elements-ui'

type VCardEmits = {
  iconClick: []
}

interface VCardProps {
  title?: string
  icon?: FAIconName

  headerClass?: ComponentClass
  headerTitleClass?: ComponentClass
  headerIconClass?: ComponentClass
  contentClass?: ComponentClass
  footerClass?: ComponentClass

  fullHeight?: boolean
  clickableIcon?: boolean

  animated?: boolean
  highlight?: boolean
  lowlight?: boolean

  loading?: boolean
}

const emits = defineEmits<VCardEmits>()
const props = withDefaults(defineProps<VCardProps>(), {
  title: undefined,
  icon: undefined,
  headerClass: undefined,
  headerTitleClass: undefined,
  headerIconClass: undefined,
  contentClass: undefined,
  footerClass: undefined,
})
</script>

<template>
  <div
    class="card"
    :class="[
      animated && 'is-animated',
      highlight && 'is-highlighted',
      lowlight && 'is-lowlighted',
      fullHeight && 'h-full',
    ]"
  >
    <slot name="header">
      <header class="card-header" :class="headerClass">
        <div
          v-if="$slots['title'] || title"
          class="card-header-title"
          :class="headerTitleClass"
        >
          <slot name="title">
            <div class="title-subtitle">
              <span class="text-xl">
                {{ title }}
              </span>
            </div>
          </slot>
        </div>

        <div
          v-if="$slots['icon'] || icon"
          class="card-header-icon"
          :class="[headerIconClass, clickableIcon && 'cursor-pointer']"
          @click="emits('iconClick')"
          @keyup="emits('iconClick')"
        >
          <slot name="icon">
            <VIcon v-if="icon" :icon size="large" :force-clickable="clickableIcon" />
          </slot>
        </div>
      </header>
    </slot>

    <div class="card-content" :class="[fullHeight && 'h-[90%]', contentClass]">
      <div v-if="loading" class="card-loading">
        <div class="is-loading" />
      </div>
      <slot name="content" />
    </div>

    <footer
      v-if="$slots['footer']"
      class="card-footer"
      :class="[fullHeight && 'h-[10%]', footerClass]"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<style scoped></style>
