<script setup lang="ts" generic="Data extends object, Keys extends string = string">
import type { RouteLocationRaw } from 'vue-router'
import type { VTableColumn } from '/@src/types/elements-ui'
import { isFunction } from 'lodash'

interface VTableBodyCellProps {
  row: Data
  column: VTableColumn<Keys, Data>
}

const props = defineProps<VTableBodyCellProps>()

const iconLeft = computed(() =>
  isFunction(props.column.iconLeft)
    ? props.column.iconLeft(props.row as Data, props.column)
    : props.column.iconLeft,
)
const iconRight = computed(() =>
  isFunction(props.column.iconRight)
    ? props.column.iconRight(props.row as Data, props.column)
    : props.column.iconRight,
)
const iconOnly = computed(() =>
  isFunction(props.column.iconOnly)
    ? props.column.iconOnly(props.row as Data, props.column)
    : props.column.iconOnly,
)
const iconPack = computed(() =>
  isFunction(props.column.iconPack)
    ? props.column.iconPack(props.row as Data, props.column)
    : props.column.iconPack,
)
const iconColor = computed(() =>
  isFunction(props.column.iconColor)
    ? props.column.iconColor(props.row as Data, props.column)
    : (props.column.iconColor ?? 'primary'),
)

const cellClass = computed(() => {
  const cell = props.column.cellClass

  if (!cell) {
    return undefined
  }

  if (isFunction(cell)) {
    return cell(props.row, props.column)
  } else {
    return cell
  }
})
</script>

<template>
  <RouterLink
    v-if="column.to && !!column.to(row as unknown as Data)"
    :to="column.to(row as unknown as Data) as RouteLocationRaw"
    class="hover:text-[unset]"
  >
    <span :class="cellClass">
      <template v-if="column.transform">
        {{ column.transform(row as unknown as Data, column.key) }}
      </template>
      <template v-else>
        <VIcon
          v-if="iconLeft"
          :icon-pack="iconPack"
          :icon="iconLeft"
          :color="iconColor"
          class="mr-1"
        />
        <VIconBox
          v-if="iconOnly"
          :icon-pack="iconPack"
          :icon="iconOnly"
          :color="iconColor"
          size="small"
        />
        <span v-if="!iconOnly" class="empty:hidden">
          {{ props.row[column.key as unknown as keyof Data] }}
        </span>
        <VIcon
          v-if="iconRight"
          :icon-pack="iconPack"
          :icon="iconRight"
          :color="iconColor"
          class="ml-1"
        />
      </template>
    </span>
  </RouterLink>
  <span v-else :class="cellClass">
    <VIcon
      v-if="iconLeft"
      :icon-pack="iconPack"
      :icon="iconLeft"
      :color="iconColor"
      class="mr-1"
    />
    <VIconBox
      v-if="iconOnly"
      :icon-pack="iconPack"
      :icon="iconOnly"
      :color="iconColor"
      size="small"
    />
    <span v-if="!iconOnly" class="empty:hidden">
      <template v-if="column.transform">
        {{ column.transform(row as unknown as Data, column.key) }}
      </template>
      <template v-else-if="column.key in props.row">
        {{ props.row[column.key as unknown as keyof Data] }}
      </template>
    </span>
    <VIcon
      v-if="iconRight"
      :icon-pack="iconPack"
      :icon="iconRight"
      :color="iconColor"
      class="ml-1"
    />
  </span>
</template>

<style scoped></style>
