<script
  lang="ts"
  setup
  generic="TLeft extends string = string, TRight extends string = string"
>
import { computed, type StyleValue } from 'vue'
import type {
  ComponentClass,
  ComponentSize,
  FAIconName,
  ModalSidebarItem,
} from '/@src/types/elements-ui'
import type { LengthUnit } from '/@src/types/utils'

type VModalEmits = {
  open: []
  close: []
}

interface VModalProps<TLeft extends string = string, TRight extends string = string> {
  title?: string
  size?: ComponentSize | 'xl' | '2xl'

  modalId?: string

  cardClasses?: ComponentClass
  cardStyle?: StyleValue

  leftSidebarTitle?: string
  leftSidebarItems?: ModalSidebarItem<TLeft>[]
  leftSidebarWidth?: LengthUnit

  rightSidebarTitle?: string
  rightSidebarItems?: ModalSidebarItem<TRight>[]
  rightSidebarWidth?: LengthUnit

  cancelLabel?: string
  headerText?: string
  headerIcon?: FAIconName
  headerClass?: ComponentClass
  headerImageSource?: string
}

const emits = defineEmits<VModalEmits>()
const props = withDefaults(defineProps<VModalProps<TLeft, TRight>>(), {
  title: 'Modal',
  size: 'normal',

  modalId: '',

  cardClasses: undefined,
  cardStyle: undefined,

  leftSidebarTitle: undefined,
  leftSidebarItems: undefined,
  leftSidebarWidth: undefined,

  rightSidebarTitle: undefined,
  rightSidebarItems: undefined,
  rightSidebarWidth: undefined,

  cancelLabel: 'Sluiten',
  headerText: undefined,
  headerIcon: 'fa-arrow-right',
  headerClass: undefined,
  headerImageSource: undefined,
})

const isOpen = defineModel<boolean>('open', {
  required: false,
  default: false,
})

const leftSidebarStyle = computed(() =>
  !!props.leftSidebarWidth ? { width: props.leftSidebarWidth } : undefined,
)
const rightSidebarStyle = computed(() =>
  !!props.rightSidebarWidth ? { width: props.rightSidebarWidth } : undefined,
)

const openModal = () => {
  isOpen.value = true
  emits('open')
}

const closeModal = () => {
  isOpen.value = false
  emits('close')
}
</script>

<template>
  <slot :open="openModal" name="open-modal">
    <div
      v-if="headerText"
      class="cursor-pointer"
      :class="headerClass"
      @click="openModal"
      @keyup="openModal"
    >
      <span class="font-semibold">{{ headerText }} </span>
      <span>
        <VIcon :icon="headerIcon" />
      </span>
    </div>
  </slot>
  <Teleport to="#modal-root">
    <div class="modal" :class="[isOpen && 'is-active', `is-${size}`]" v-bind="$attrs">
      <div
        class="modal-background"
        @click.prevent="closeModal"
        @keyup.prevent="closeModal"
      />
      <div :id="modalId" class="modal-content">
        <div
          v-if="
            (leftSidebarItems && leftSidebarItems.length > 0) || $slots['left-sidebar']
          "
          class="modal-sidebar modal-sidebar-left"
          :style="leftSidebarStyle"
        >
          <p v-if="leftSidebarTitle" class="modal-sidebar-title">
            {{ leftSidebarTitle }}
          </p>
          <div class="modal-sidebar-content">
            <slot name="left-sidebar">
              <VBlock
                v-for="item in leftSidebarItems"
                :key="`sidebar-modal-item-left-${item.key}`"
                class="modal-sidebar-item"
                :class="item.isActive && 'modal-sidebar-item-active'"
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="item.icon"
                :icon-color="item.color"
                reversed
                :override-clickable="!item.onClick"
                @click.prevent="
                  () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                "
                @keyup.prevent="
                  () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                "
              />
            </slot>
          </div>
        </div>
        <div
          v-if="
            (rightSidebarItems && rightSidebarItems.length > 0) || $slots['right-sidebar']
          "
          class="modal-sidebar modal-sidebar-right"
          :style="rightSidebarStyle"
        >
          <p v-if="rightSidebarTitle" class="modal-sidebar-title">
            {{ rightSidebarTitle }}
          </p>
          <div class="modal-sidebar-content">
            <slot name="right-sidebar">
              <VBlock
                v-for="item in leftSidebarItems"
                :key="`sidebar-modal-item-left-${item.key}`"
                class="modal-sidebar-item"
                :class="item.isActive && 'modal-sidebar-item-active'"
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="item.icon"
                :icon-color="item.color"
                :override-clickable="!item.onClick"
                @click.prevent="
                  () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                "
                @keyup.prevent="
                  () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                "
              />
            </slot>
          </div>
        </div>
        <div class="modal-content-inner" :class="cardClasses" :style="cardStyle">
          <header class="modal-header">
            <h3 class="modal-title">
              {{ title }}
            </h3>

            <slot :close="closeModal" name="cross">
              <button
                aria-label="close"
                class="modal-close"
                tabindex="0"
                @click="closeModal"
                @keydown.space.prevent="closeModal"
              ></button>
            </slot>
          </header>
          <div class="modal-body">
            <slot name="content" />
          </div>
          <footer class="modal-footer">
            <slot name="pre-cancel-action" />
            <slot :close="closeModal" name="cancel">
              <VButton @click="closeModal">
                {{ cancelLabel }}
              </VButton>
            </slot>
            <slot name="actions" />
          </footer>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped></style>
