<script setup lang="ts">
import { sample } from 'lodash'

const ornamentColors = [
  '#751717',
  '#BA0C0C',
  '#CD5C08',
  '#FFEBEB',
  '#ECFFEB',
  '#27A300',
  '#2A850E',
  '#2D661B',
  '#6A9C89',
]

const { width: viewPortWidth, height: viewPortHeight } = useWindowSize()
const maxHeight = computed(() => viewPortHeight.value / 10)

const minOrnaments = 15
const maxOrnaments = 20
const numberOfChristmasOrnaments =
  Math.ceil(Math.random() * (maxOrnaments - minOrnaments)) + minOrnaments

const ornamentDistance = computed(() => viewPortWidth.value / numberOfChristmasOrnaments)

const ornaments = computed(() => {
  return Array.from({ length: numberOfChristmasOrnaments }, (_, index) => {
    const radius = Math.floor(Math.random() * 10) + 5
    return {
      x:
        Math.floor((Math.random() * 6 * viewPortWidth.value) / ornamentDistance.value) +
        ornamentDistance.value * index,
      height: Math.floor(Math.random() * (maxHeight.value / 2)) + 20,
      radius: radius,
      color: sample(ornamentColors),
      animationDuration: (radius / 5).toFixed(1),
    }
  })
})
</script>

<template>
  <div class="christmas-ornaments">
    <svg :width="viewPortWidth" :height="maxHeight">
      <g v-for="(ornament, index) in ornaments" :key="index">
        <!-- Hanging line -->
        <path
          :d="`M${ornament.x},0 L${ornament.x},${ornament.height}`"
          stroke="black"
          stroke-width="2"
        />
        <!-- Ornament -->
        <circle
          :cx="ornament.x"
          :cy="ornament.height"
          :r="ornament.radius"
          :fill="ornament.color"
          :style="{
            '--glow-color': ornament.color,
            '--glow-radius': `${ornament.radius * 1.5}px`,
            '--glow-speed': `${ornament.animationDuration}s`,
          }"
        />
      </g>
    </svg>
  </div>
</template>

<style scoped></style>
