import {
  type RoleIcon,
  type TeamRoleEnum,
  UserRoleEnum,
  type UserTypeEnum,
} from '/@src/types/users'
import { CookIcon, FoodieIcon, UserIcon } from '/@src/models/standardIcons'
import type { OptionsMap } from '/@src/types/elements-ui'

export const userRoleMapping: Record<UserRoleEnum, string> = {
  [UserRoleEnum.Cook]: 'Thuiskok',
  [UserRoleEnum.Foodie]: 'Maaltijdzoeker',

  [UserRoleEnum.Admin]: 'Admin',
  [UserRoleEnum.Administrator]: 'Beheerder',

  [UserRoleEnum.Strategy]: 'Strategie',
  [UserRoleEnum.GeneralEmployee]: 'Algemeen',

  [UserRoleEnum.Matchmaking]: 'Matchmaking',
  [UserRoleEnum.Matchmaker]: 'Matchmaker',
  [UserRoleEnum.ExMatchmaker]: 'Ex-matchmaker',
  [UserRoleEnum.Helpdesker]: 'Helpdesker',
  [UserRoleEnum.OfficeManager]: 'Office manager',
  [UserRoleEnum.LeadMatchmaker]: 'Lead-matchmaker',

  [UserRoleEnum.Developer]: 'Development',

  [UserRoleEnum.Marketing]: 'Marketing',
  [UserRoleEnum.VolunteerCoordinator]: 'Vrijwilligerscoördinator',
  [UserRoleEnum.RegionalCoordinator]: 'Regiocoördinator',

  [UserRoleEnum.ImpactAccelerator]: 'Impactversneller',

  [UserRoleEnum.Manager]: 'Team Lead',

  [UserRoleEnum.Finance]: 'Finance',

  [UserRoleEnum.Reporting]: 'Rapportage',
  [UserRoleEnum.ReadOnly]: 'Read-only',
  [UserRoleEnum.External]: 'Extern / Uitbesteed',
}

export const teamRoleMapping: Record<TeamRoleEnum, string> = {
  [UserRoleEnum.GeneralEmployee]: 'Algemeen',
  [UserRoleEnum.Matchmaking]: 'Matchmaking',
  [UserRoleEnum.Developer]: 'Development',
  [UserRoleEnum.Marketing]: 'Marketing',
  [UserRoleEnum.ImpactAccelerator]: 'Impactversneller',
}

export const userTypeLowercaseMapping: Record<UserTypeEnum, string> = {
  [UserRoleEnum.Cook]: 'thuiskok',
  [UserRoleEnum.Foodie]: 'maaltijdzoeker',
}

export const roles: Record<UserRoleEnum, RoleIcon> = {
  [UserRoleEnum.Cook]: {
    name: userRoleMapping[UserRoleEnum.Cook],
    icon: CookIcon,
  },
  [UserRoleEnum.Foodie]: {
    name: userRoleMapping[UserRoleEnum.Foodie],
    icon: FoodieIcon,
  },

  [UserRoleEnum.Admin]: {
    name: userRoleMapping[UserRoleEnum.Admin],
    icon: UserIcon,
  },
  [UserRoleEnum.Administrator]: {
    name: userRoleMapping[UserRoleEnum.Administrator],
    icon: 'fa-users',
  },

  [UserRoleEnum.Strategy]: {
    name: userRoleMapping[UserRoleEnum.Strategy],
    icon: 'fa-bullseye',
  },
  [UserRoleEnum.GeneralEmployee]: {
    name: userRoleMapping[UserRoleEnum.GeneralEmployee],
    icon: 'fa-user-tie',
  },

  [UserRoleEnum.Matchmaking]: {
    name: userRoleMapping[UserRoleEnum.Matchmaking],
    icon: 'fa-user-astronaut',
  },
  [UserRoleEnum.Matchmaker]: {
    name: userRoleMapping[UserRoleEnum.Matchmaker],
    icon: 'fa-phone',
  },
  [UserRoleEnum.ExMatchmaker]: {
    name: userRoleMapping[UserRoleEnum.ExMatchmaker],
    icon: 'fa-phone-slash',
  },
  [UserRoleEnum.LeadMatchmaker]: {
    name: userRoleMapping[UserRoleEnum.LeadMatchmaker],
    icon: 'fa-hat-wizard',
  },
  [UserRoleEnum.Helpdesker]: {
    name: userRoleMapping[UserRoleEnum.Helpdesker],
    icon: 'fa-headset',
  },
  [UserRoleEnum.OfficeManager]: {
    name: userRoleMapping[UserRoleEnum.OfficeManager],
    icon: 'fa-building',
  },

  [UserRoleEnum.Developer]: {
    name: userRoleMapping[UserRoleEnum.Developer],
    icon: 'fa-laptop-code',
  },

  [UserRoleEnum.Marketing]: {
    name: userRoleMapping[UserRoleEnum.Marketing],
    icon: 'fa-chart-line',
  },
  [UserRoleEnum.VolunteerCoordinator]: {
    name: userRoleMapping[UserRoleEnum.VolunteerCoordinator],
    icon: 'fa-shop',
  },
  [UserRoleEnum.RegionalCoordinator]: {
    name: userRoleMapping[UserRoleEnum.RegionalCoordinator],
    icon: 'fa-map-location-dot',
  },

  [UserRoleEnum.ImpactAccelerator]: {
    name: userRoleMapping[UserRoleEnum.ImpactAccelerator],
    icon: 'fa-handshake',
  },

  [UserRoleEnum.Manager]: {
    name: userRoleMapping[UserRoleEnum.Manager],
    icon: 'fa-people-roof',
  },

  [UserRoleEnum.Finance]: {
    name: userRoleMapping[UserRoleEnum.Finance],
    icon: 'fa-sack-dollar',
  },

  [UserRoleEnum.ReadOnly]: {
    name: userRoleMapping[UserRoleEnum.ReadOnly],
    icon: 'fa-user-lock',
  },
  [UserRoleEnum.Reporting]: {
    name: userRoleMapping[UserRoleEnum.Reporting],
    icon: 'fa-chart-simple',
  },
  [UserRoleEnum.External]: {
    name: userRoleMapping[UserRoleEnum.External],
    icon: 'fa-graduation-cap',
  },
}

export const userRoleValues = Object.values(UserRoleEnum)

export const teamColour: Record<TeamRoleEnum, `#${string}`> = {
  [UserRoleEnum.Developer]: '#EDF4E0',
  [UserRoleEnum.GeneralEmployee]: '#4C3B4D',
  [UserRoleEnum.ImpactAccelerator]: '#C89F9C',
  [UserRoleEnum.Marketing]: '#C97C5D',
  [UserRoleEnum.Matchmaking]: '#628233',
}
export const teamTextColour: Record<TeamRoleEnum, `#${string}`> = {
  [UserRoleEnum.Developer]: '#000000',
  [UserRoleEnum.GeneralEmployee]: '#FFFFFF',
  [UserRoleEnum.ImpactAccelerator]: '#000000',
  [UserRoleEnum.Marketing]: '#FFFFFF',
  [UserRoleEnum.Matchmaking]: '#FFFFFF',
}
export const teamOptionsMap: OptionsMap<TeamRoleEnum>[] = [
  {
    id: UserRoleEnum.Developer,
    name: userRoleMapping[UserRoleEnum.Developer],
  },
  {
    id: UserRoleEnum.GeneralEmployee,
    name: userRoleMapping[UserRoleEnum.GeneralEmployee],
  },
  {
    id: UserRoleEnum.ImpactAccelerator,
    name: userRoleMapping[UserRoleEnum.ImpactAccelerator],
  },
  {
    id: UserRoleEnum.Marketing,
    name: userRoleMapping[UserRoleEnum.Marketing],
  },
  {
    id: UserRoleEnum.Matchmaking,
    name: userRoleMapping[UserRoleEnum.Matchmaking],
  },
]
