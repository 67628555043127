<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

type TabKey =
  | 'today'
  | 'daily-overview'
  | 'monthly-overview'
  | 'upcoming-reminders'
  | 'automatic-reminders'
const tabs: VTabsItem<TabKey>[] = [
  {
    label: 'Vandaag',
    value: 'today',
    to: { name: '/to-do/' },
  },
  {
    label: 'Dagelijks overzicht',
    value: 'daily-overview',
    to: { name: '/to-do/daily-overview' },
  },
  {
    label: 'Maandelijks overzicht',
    value: 'monthly-overview',
    to: { name: '/to-do/monthly-overview' },
  },
  {
    label: 'Binnenkort',
    value: 'upcoming-reminders',
    to: { name: '/to-do/upcoming-reminders' },
  },
  {
    label: 'Lijst van automatische herinneringen',
    value: 'automatic-reminders',
    to: { name: '/to-do/automatic-reminders' },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style scoped></style>
