<script setup lang="ts">
import { useReportingStore } from '/@src/stores/reporting'
import type { StatisticsReportingReport } from '/@src/types/reporting'
import { clone } from 'lodash'
import { useHead } from '@unhead/vue'

useHead({
  title: 'Focus-gemeentes Statistieken | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

const reportingStore = useReportingStore()

const isLoading = ref(false)
const reportData = ref<StatisticsReportingReport[]>()

const municipalityReport = computed(() => {
  const municipalityReport = reportData.value

  if (!municipalityReport || municipalityReport.length === 0) {
    return undefined
  }

  return clone(municipalityReport).sort((matchStatusA, matchStatusB) => {
    return matchStatusA.subject.toString().localeCompare(matchStatusB.subject.toString())
  })
})

onMounted(async () => {
  isLoading.value = true
  const result = await reportingStore.getMunicipalityReport()
  isLoading.value = false

  if (result) {
    reportData.value = result
  }
})
</script>

<template>
  <div>
    <StatisticsReportingTable v-if="municipalityReport" :data="municipalityReport" />
  </div>
</template>

<style scoped></style>
