<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const route = useRoute()

const tabs: VTabsItem[] = [
  {
    label: 'Zoeken',
    value: 'search',
    to: {
      name: '/matches/',
    },
  },
  {
    label: 'Match opvolging',
    value: 'match-monitoring-result',
    to: {
      name: '/matches/match-monitoring',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs
      v-if="route.name === '/matches/' || route.name === '/matches/match-monitoring'"
      v-model:selected="activeTab"
      :tabs="tabs"
      size="medium"
    />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>
