export const UserIcon = 'fa-user'
export const BlockedUserIcon = 'fa-user-lock'
export const DeletedUserIcon = 'fa-user-slash'

export const FoodieIcon = 'fa-utensils'
export const CookIcon = 'fa-bread-slice'
export const MatchRequestIcon = 'fa-folder-closed'
export const MatchIcon = 'fa-folder-closed'
export const MealsIcon = 'fa-plate-wheat'

export const CaretakerIcon = 'fa-user-nurse'

export const CopyIcon = 'fa-copy'
export const EditIcon = 'fa-edit'
export const SaveIcon = 'fa-save'
export const DeleteIcon = 'fa-x'

export const SuccessIcon = 'fa-check'
export const FailIcon = 'fa-thumbs-down'
export const ThumbsUpIcon = 'fa-thumbs-up'
export const ThumbsDownIcon = 'fa-thumbs-down'

export const NoPreferenceIcon = 'fa-shuffle'

export const PriceIcon = 'fa-money-bill'
export const CollectionIcon = 'fa-credit-card'
export const MutualIcon = 'fa-wallet'
export const PrepaidIcon = 'fa-piggy-bank'
export const DonationIcon = 'fa-scale-unbalanced'

export const ExperimentalIcon = 'fa-flask'
export const FrozenIcon = 'fa-snowflake'

export const PayoutIcon = 'fa-money-bill'

export const SaldoCorrectionIcon = 'fa-money-bill-transfer'

export const FocusMunicipalityIcon = 'fa-crosshairs'

export const LockIcon = 'fa-lock'
export const OpenLockIcon = 'fa-lock-open'

export const StopIcon = 'fa-xmark-circle'
export const PauseIcon = 'fa-pause'

export const LoadingSpinner = 'fa-spinner fa-pulse'

export const WaitingListIcon = 'fa-list-ul'
export const CancelledIcon = 'fa-times-circle'

export const RecurringIcon = 'fa-repeat'
export const FlexibleIcon = 'fa-shuffle'

export const StopMatchRequestIcon = 'fa-circle-stop'

export const DietWishIcon = 'fa-wheat-awn-circle-exclamation'
export const KitchenPreferenceIcon = 'fa-kitchen-set'

export const AbnIcon = 'fa-shield'
export const RaboIcon = 'fa-person-arrow-up-from-line'

//Navbar icons
export const TasksNavbarIcon = 'fa-bookmark'
export const DashboardNavbarIcon = 'fa-clipboard'
export const ReportingNavbarIcon = 'fa-chart-pie'
export const AnalysisNavbarIcon = 'fa-magnifying-glass-chart'
export const ChecksNavbarIcon = 'fa-check'
export const MatchRequestNavbarIcon = 'fa-heart'
export const MatchesNavbarIcon = 'fa-layer-group'
export const MealsNavbarIcon = MealsIcon
export const VOGNavbarIcon = 'fa-box-archive'
export const SurveyNavbarIcon = 'fa-pencil'
export const EventsNavbarIcon = 'fa-calendar'
export const PaymentsNavbarIcon = 'fa-euro-sign'
export const DonationsNavbarIcon = 'fa-hand-holding-dollar'
export const SettingsNavbarIcon = 'fa-gear'
export const RockNavbarIcon = 'fa-bullseye'
export const BugNavbarIcon = 'fa-bug'

export const DashboardTotalIcon = 'fa-users'
export const DashboardInProgressIcon = 'fa-phone'
export const DashboardSuggestedIcon = 'fa-handshake'
export const DashboardSampleMealIcon = 'fa-utensils'
export const DashboardMatchesIcon = 'fa-heart'
export const DashboardPausedIcon = 'fa-pause'
export const DashboardNoCookFoundIcon = 'fa-x'
