<script setup lang="ts">
import { sample } from 'lodash'
import anime from 'animejs'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { emojiPopper, type SpawnEmojiProps } from '/@src/utils/emoji-popper'

const adminUserStore = useAdminUsersStore()

const emojiTargetRef = ref<HTMLDivElement | undefined>()
const emojiTemplateRef = ref<HTMLDivElement | undefined>()

const emojiOfTheDay = computed(() => adminUserStore.adminUser?.emojiOfTheDay)

const unsubscribe = emojiPopper.subscribe((props) => {
  if (emojiTemplateRef.value && emojiTargetRef.value) {
    const maxElements = 30

    for (let i = 0; i < maxElements; i++) {
      const div = prepareDiv(props, true)

      const x =
        Math.cos((i / maxElements) * 2 * Math.PI) *
        (300 + Math.round(Math.random() * 200))
      const y =
        Math.sin((i / maxElements) * 2 * Math.PI) *
        (300 + Math.round(Math.random() * 200))

      anime({
        targets: div,
        duration: 1000,
        translateX: `${x}px`,
        translateY: `${y}px`,
      })
      emojiTargetRef.value?.appendChild(div)

      setTimeout(() => {
        div.remove()
      }, 1000)
    }

    const div = prepareDiv(props, false)
    anime({
      targets: div,
      duration: 1000,
      translateY: `-10px`,
    })
    emojiTargetRef.value?.appendChild(div)

    setTimeout(() => {
      div.remove()
    }, 1000)

    return true
  }

  return false
})

const prepareDiv = (props: Required<SpawnEmojiProps>, isEmoji: boolean) => {
  const div = emojiTemplateRef.value?.cloneNode() as HTMLDivElement

  if (isEmoji) {
    const emojis = props.emojis
    if (emojiOfTheDay.value && !props.emojis.includes(emojiOfTheDay.value)) {
      emojis.push(emojiOfTheDay.value)
    }
    div.textContent = sample(emojis) as string | null
  } else {
    div.textContent = sample(props.text) as string | null
  }

  div.style.left = props.centerOfPage
    ? `${window.innerWidth / 2}px`
    : `${props.location.x - 32}px`
  div.style.top = props.centerOfPage
    ? `${window.innerHeight / 2}px`
    : `${props.location.y - 32}px`
  div.style.display = 'inline'
  div.style.userSelect = 'none'

  return div
}

onUnmounted(unsubscribe)
</script>

<template>
  <Teleport to="body">
    <div ref="emojiTargetRef" class="fixed left-0 top-0 z-[2000]">
      <div ref="emojiTemplateRef" class="absolute hidden text-[48px]"></div>
    </div>
  </Teleport>
</template>

<style scoped></style>
