<script setup lang="ts">
import { type AdminTask, AdminTaskCategoryList } from '/@src/types/admin-tasks'
import { useTasksStore } from '/@src/stores/tasks'
import { categoryListIcon, getTaskCategory } from '/@src/mapping/admin-tasks'
import { useModal } from '/@src/composable/useModal'
import { useHead } from '@unhead/vue'
import { isLeadMatchmaker } from '/@src/utils/entity-checks'
import type { UserId } from '/@src/types/users'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { useWebSocket, useWebSocketModel } from '/@src/composable/useWebSocket'
import { updateModel } from '/@src/utils/webSockets'
import { LoadingSpinner } from '/@src/models/standardIcons'
import { useAuthStore } from '/@src/stores/auth'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

useHead({
  title: 'Herinneringen | Thuisgekookt Admin',
})

const route = useRoute('/to-do/')

const taskStore = useTasksStore()
const authStore = useAuthStore()
const adminUserStore = useAdminUsersStore()

const isLoading = ref(false)
const tasks = ref<AdminTask[]>([])

const selectedTask = ref<AdminTask>()
const modalType = ref<'create' | 'update'>('create')

const selectedUser = ref<UserId>()

const selectedCategory = ref<AdminTaskCategoryList>(AdminTaskCategoryList.NotAutomatic)

const categoryCardTitle = computed(() => {
  if (isLoading.value) {
    return ''
  } else if (selectedUser.value) {
    const name = adminUserStore.getAdminUser(selectedUser.value)?.firstname ?? 'Onbekend'
    return `Herinneringen van ${name}`
  } else {
    return 'Categorieën'
  }
})

const { setModels: setAdminTaskModels } = useWebSocketModel<AdminTask>({
  baseChannel: 'tg-admin-channel-admin-task',
  event: '.AdminTaskUpdated',
  callback: (event: WebSocketsModelReply<AdminTask>) => {
    const updatedTask = event.model

    const todayTaskExists = tasks.value.find((task) => task.id === updatedTask.id)

    if (updatedTask.scheduledFor.isToday() && todayTaskExists) {
      updateModel(todayTaskExists, updatedTask)
    }
  },
})

const receivedNewTask = (event: WebSocketsModelReply<AdminTask>) => {
  tasks.value.push(event.model)
  setAdminTaskModels(tasks)
}

const baseNewTasksChannel = 'tg-admin-channel-user'
const { setChannel } = useWebSocket({
  event: '.AdminTaskCreated',
  callback: receivedNewTask,
  channel: `${baseNewTasksChannel}-${authStore.user?.id}`,
})

const fetchTasks = async () => {
  isLoading.value = true
  const result = await taskStore.getToday(selectedUser.value)
  isLoading.value = false

  if (result) {
    tasks.value = result
    setAdminTaskModels(tasks)
    const channelId = `${baseNewTasksChannel}-${authStore.user?.id}`
    setChannel(channelId)
  }
}

watch(selectedUser, fetchTasks)

const setCategory = (key: AdminTaskCategoryList) => {
  selectedCategory.value = key
}

const tasksForToday = computed<Record<AdminTaskCategoryList, AdminTask[]>>(() => {
  const result: Record<AdminTaskCategoryList, AdminTask[]> = {
    [AdminTaskCategoryList.Null]: [],
    [AdminTaskCategoryList.Finished]: [],
    [AdminTaskCategoryList.MatchRequestSuggestedFollowUp]: [],
    [AdminTaskCategoryList.MatchFollowUp]: [],
    [AdminTaskCategoryList.Balance]: [],
    [AdminTaskCategoryList.NotAutomatic]: [],
  }

  tasks.value.forEach((task) => {
    const key = getTaskCategory(task)
    result[key].push(task)
  })

  return result
})

const { modalIsOpen, openModal: internalOpenModal } = useModal({
  onClose: () => {
    selectedTask.value = undefined
    modalType.value = 'create'
  },
})

const newTask = () => {
  selectedTask.value = undefined
  modalType.value = 'create'

  internalOpenModal()
}

const updateTask = (task: AdminTask) => {
  selectedTask.value = task
  modalType.value = 'update'

  internalOpenModal()
}

watch(
  route,
  async (newRoute) => {
    await fetchTasks()

    const task = taskStore.tasksForToday.find(
      (task) => task.id === Number(newRoute.query.task),
    )

    if (!task) {
      return
    }

    await nextTick(() => {
      updateTask(task)
      selectedCategory.value = getTaskCategory(task)
    })
  },
  { deep: true, immediate: true },
)
</script>

<template>
  <div>
    <div class="columns account-wrapper pb-0">
      <div class="column one-fifth !p-1">
        <VCard
          :title="categoryCardTitle"
          class="account-box is-navigation"
          content-class="!px-0"
          :icon="isLoading ? LoadingSpinner : undefined"
        >
          <template #content>
            <div
              v-for="category in AdminTaskCategoryList"
              :key="`category-selector-${category}`"
              class="columns cursor-pointer items-center justify-center"
              :class="selectedCategory === category && 'is-active'"
              @click.prevent="() => setCategory(category)"
              @keyup.prevent="() => setCategory(category)"
            >
              <VIcon
                class="column is-category-icon !flex w-2/12 justify-center"
                :icon="categoryListIcon[category]"
                :color="selectedCategory === category ? 'info' : undefined"
                size="large"
              />
              <div class="column two-thirds is-category-text title-subtitle select-none">
                <span
                  class="text-base font-semibold"
                  :class="selectedCategory === category && '!text-info'"
                  >{{ category }}</span
                >
                <span
                  class="text-base"
                  :class="selectedCategory === category && '!text-info'"
                  >{{ tasksForToday[category].length }} herinnering{{
                    tasksForToday[category].length !== 1 ? 'en' : ''
                  }}</span
                >
              </div>
            </div>
          </template>
        </VCard>

        <VCard
          title="Nieuwe herinnering maken"
          icon="fa-note-sticky"
          content-class="!px-0"
        >
          <template #content>
            <VButton
              icon-only="fa-plus"
              color="info"
              width="full"
              @click.prevent="newTask"
            />
          </template>
        </VCard>

        <VCard
          v-if="isLeadMatchmaker"
          title="Herinneringen van andere gebruiker openen"
          icon="fa-users"
          content-class="!px-0"
        >
          <template #content>
            <AdminUserFilter
              v-model="selectedUser"
              type="all"
              :require-model-value="false"
              label="&nbsp;"
            />
          </template>
        </VCard>
      </div>

      <div class="column four-fifths !p-1">
        <AdminTaskListCard
          :title="selectedCategory"
          :list="tasksForToday[selectedCategory]"
          :user-id="selectedUser"
          @edit="(task) => updateTask(task)"
        />
      </div>
    </div>

    <AdminTaskModal
      v-model:is-open="modalIsOpen"
      :task="selectedTask"
      :modal-type="modalType"
    />
  </div>
</template>

<style scoped></style>
