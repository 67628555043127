<script setup lang="ts">
import {
  type AdminTask,
  AdminTaskPriority,
  AdminTaskStatus,
} from '/@src/types/admin-tasks'
import BananaDance from '/@src/assets/gifs/banana-dance.gif'
import { useNotifications } from '/@src/composable/useNotifications'
import { useElementHover } from '@vueuse/core'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import { useTasksStore } from '/@src/stores/tasks'
import nl from 'dayjs/locale/nl'
import { useWebSocketModel } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { useAuthStore } from '/@src/stores/auth'
import type { AuthUser } from '/@src/types/auth'
import { capitalize } from '/@src/utils/helpers'
import {
  TaskPriorityColor,
  TaskPriorityIcon,
  TaskStatusColor,
  TaskStatusIcon,
  TaskStatusText,
} from '/@src/mapping/admin-tasks'
import type { FAIconName } from '/@src/types/elements-ui'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { dayjsUTC, formatTime } from '/@src/utils/date-formatter'
import { emojiPopper } from '/@src/utils/emoji-popper'
import { UserRoleEnum } from '/@src/types/users'

dayjs.extend(timezone)
dayjs.extend(LocalizedFormat)
dayjs.locale(nl)

const adminUserStore = useAdminUsersStore()
const taskStore = useTasksStore()
const authStore = useAuthStore()

// No further typing needed as it's only used for the useElementHover function
const notificationButton = ref<HTMLElement | null>(null)
const isHovered = useElementHover(notificationButton)

const birthdateIsToday = computed(() => {
  if (!adminUserStore.adminUser) {
    return false
  }
  const birthdate = adminUserStore.adminUser.birthdate
  const now = dayjsUTC()
  return birthdate?.date() === now.date() && birthdate?.month() === now.month()
})

const tasks = computed(() => taskStore.toolbarTasks)

const isLoadingTasks = ref(false)
const isLoadingTask = ref<number | undefined>(undefined)

const showAnimation = computed(() => taskStore.showAnimationForNewTasks)

const showRipple = ref(false)
const isHighPrio = ref(false)

const { start } = useTimeoutFn(
  () => {
    showRipple.value = false
    isHighPrio.value = false
  },
  2900,
  { immediate: false },
)

const updateAdminTask = async (event: WebSocketsModelReply<AdminTask>) => {
  taskStore.updateInternalTask(event.model)
}

const { setModels: setAdminTaskModels } = useWebSocketModel<AdminTask>({
  baseChannel: 'tg-admin-channel-admin-task',
  event: '.AdminTaskUpdated',
  callback: updateAdminTask,
})

const receivedNewTask = (event: WebSocketsModelReply<AdminTask>) => {
  const addedNewTaskToToday = taskStore.receivedNewTask(event.model)

  setAdminTaskModels(taskStore.tasksForToday)

  if (addedNewTaskToToday && showAnimation.value) {
    showRipple.value = true
    isHighPrio.value = event.model.priority === AdminTaskPriority.High
    start()
  }
}

const { setModel: setAdminUserModel } = useWebSocketModel<AuthUser>({
  baseChannel: 'tg-admin-channel-user',
  event: '.AdminTaskCreated',
  callback: receivedNewTask,
})

watch(
  () => taskStore.tasksForToday,
  () => setAdminTaskModels(taskStore.tasksForToday),
)

const toggleAnimationProperty = () => {
  taskStore.showAnimationForNewTasks = !taskStore.showAnimationForNewTasks
}

const getTaskTime = (task: AdminTask) => {
  return formatTime(task.scheduledFor)
}

const getTaskIcon = (task: AdminTask) => {
  if (task.id === isLoadingTask.value) {
    return 'fa-spinner fa-pulse'
  }

  if (task.priority === 'high') {
    return TaskPriorityIcon[AdminTaskPriority.High]
  }

  return TaskStatusIcon[task.status]
}

const getTaskColor = (task: AdminTask) => {
  if (task.id === isLoadingTask.value) {
    return 'info'
  }

  if (task.priority === AdminTaskPriority.High) {
    return TaskPriorityColor[AdminTaskPriority.High]
  }

  return TaskStatusColor[task.status]
}

const completeItem = async (task: AdminTask, event: Event) => {
  if (task.status === AdminTaskStatus.Active) {
    const target = event.target as Element
    const boundClientRec = target?.getBoundingClientRect()

    isLoadingTask.value = task.id
    const success = await taskStore.completeTasks(task.id)
    isLoadingTask.value = undefined

    if (success) {
      const didPop = emojiPopper.pop({
        location: boundClientRec,
        chance: taskStore.emojiChance,
      })

      if (didPop) {
        taskStore.showedEmojis()
      }
    }
  }
}

const itemIsLoading = (task: AdminTask) => {
  return task.id === isLoadingTask.value
}

const {
  hasNotificationPermission,
  requestPermission,
  isFetchingTasks,
  notificationsIsActive,
  toggleNotifications,
} = useNotifications()

const notificationsIcon = computed<FAIconName>(() => {
  if (!hasNotificationPermission.value) {
    return 'fa-envelope'
  }

  if (isHovered.value) {
    if (notificationsIsActive.value) {
      return 'fa-pause'
    } else {
      return 'fa-play'
    }
  }

  if (notificationsIsActive.value) {
    return 'fa-check'
  } else {
    return 'fa-pause'
  }
})

const notificationsText = computed(() => {
  if (!hasNotificationPermission.value) {
    return 'Notificaties aanzetten'
  }

  if (notificationsIsActive.value) {
    return 'Notificaties pauzeren'
  } else {
    return 'Notificaties aanzetten'
  }
})

const notificationsColor = computed(() => {
  if (!hasNotificationPermission.value) {
    return 'info'
  }

  if (notificationsIsActive.value) {
    return 'success'
  } else {
    return 'warning'
  }
})

const notificationsButtonClick = () => {
  if (hasNotificationPermission.value) {
    toggleNotifications()
  } else {
    requestPermission()
  }
}

const newTask = (hide: Function) => {
  taskStore.newTask()
  hide()
}

watch(
  () => authStore.authUser.id,
  () => setAdminUserModel(authStore.authUser),
)
onMounted(async () => {
  if (authStore.hasRole(UserRoleEnum.External)) {
    return
  }

  setAdminUserModel(authStore.authUser)

  isLoadingTasks.value = true
  const result = await taskStore.getToday()
  isLoadingTasks.value = false
  if (result) {
    taskStore.tasksForToday = result
  }
})
</script>

<template>
  <VMenu theme="dropdown" placement="bottom-end" :triggers="['click']">
    <VIcon
      class="is-toolbar-button cursor-pointer rounded-full text-black hover:bg-gray-light dark:text-white dark:hover:bg-gray-dark"
      :class="{
        'has-ripple': showRipple,
        'high-prio': isHighPrio,
      }"
      icon="fa-bookmark"
      size="large"
      font-awesome-icon-size="lg"
    />

    <template #popper="{ hide }">
      <div class="dropdown-head mb-3">
        <div class="title-subtitle">
          <span class="font-semibold">Herinneringen van:</span>
          <br />
          <span>{{ capitalize(dayjs('2023-08-29').format('MMMM D')) }}</span>
        </div>
      </div>

      <div class="dropdown-item no-hover">
        <div class="flex flex-row">
          <VTooltip>
            <template #popper>{{ notificationsText }}</template>

            <VIconBox
              ref="notificationButton"
              :icon="notificationsIcon"
              :color="notificationsColor"
              outlined
              rounded
              :loading="isFetchingTasks"
              @click="notificationsButtonClick"
            />
          </VTooltip>

          <VTooltip>
            <template #popper>Herinnering toevoegen</template>

            <VIconBox
              icon="fa-plus"
              color="success"
              rounded
              outlined
              @click="newTask(hide)"
            />
          </VTooltip>

          <VTooltip>
            <template #popper
              >Speel {{ !showAnimation ? 'geen' : '' }} animatie bij nieuwe herinneringen
            </template>

            <VIconBox
              :icon="showAnimation ? 'fa-sun' : 'fa-cloud'"
              :color="showAnimation ? 'success' : 'danger'"
              rounded
              outlined
              @click.prevent="toggleAnimationProperty"
            />
          </VTooltip>
        </div>
      </div>

      <template v-if="birthdateIsToday">
        <hr class="dropdown-divider" />
        <div class="dropdown-item">
          <VAvatar :picture="BananaDance" />
          <div class="title-subtitle">
            <span> Gefeliciteerd! </span>
            <span>:bananadance:</span>
          </div>
        </div>
      </template>

      <hr class="dropdown-divider" />

      <template v-if="isLoadingTasks">
        <div class="dropdown-item no-hover flex flex-row items-center">
          <VIconBox icon="fa-check" color="info" :loading="isLoadingTasks" />
          <span class="font-semibold">Herinneringen aan het laden</span>
        </div>
      </template>

      <template v-if="tasks.length > 0">
        <div
          v-for="task in tasks"
          :key="`task-${task.id}`"
          class="dropdown-item no-hover flex items-center"
        >
          <VTooltip>
            <template #popper>{{ TaskStatusText[task.status] }}</template>
            <VIconBox
              size="normal"
              :icon="getTaskIcon(task)"
              :color="getTaskColor(task)"
              :loading="itemIsLoading(task)"
              :hover-icon="TaskStatusIcon[AdminTaskStatus.Completed]"
              :hover-color="TaskStatusColor[AdminTaskStatus.Completed]"
              @click="completeItem(task, $event)"
              @keyup="completeItem(task, $event)"
            />
          </VTooltip>
          <RouterLink
            :to="{ name: '/to-do/', query: { task: task.id.toString() } }"
            class="title-subtitle mx-2"
            @click.prevent="hide"
          >
            <span>{{ task.title }}</span>
            <span>{{ getTaskTime(task) }}</span>
          </RouterLink>
        </div>
      </template>

      <div v-else>
        <div class="dropdown-item no-hover">
          <VAvatar :picture="BananaDance" />
          <span class="title-subtitle"> Geen herinneringen meer voor vandaag! </span>
        </div>
      </div>
    </template>
  </VMenu>
</template>

<style scoped>
.is-toolbar-button {
  &:focus {
    outline: none;
  }

  &.has-ripple {
    transition: all 0.7s ease;
    animation: ripple-light 1.5s linear infinite;

    &.high-prio {
      animation-name: ripple-important;
    }
  }
}

.is-dark {
  .is-toolbar-button {
    &:not(.high-prio) {
      animation-name: ripple-dark;
    }
  }
}

@keyframes ripple-light {
  0% {
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 30%),
      0 0 0 1px rgb(0 0 0 / 30%),
      0 0 0 3px rgb(0 0 0 / 30%),
      0 0 0 5px rgb(0 0 0 / 30%);
  }

  100% {
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 30%),
      0 0 0 4px rgb(0 0 0 / 30%),
      0 0 0 20px rgb(0 0 0 / 0%),
      0 0 0 30px rgb(0 0 0 / 0%);
  }
}

@keyframes ripple-dark {
  0% {
    box-shadow:
      0 0 0 0 rgb(255 255 255 / 30%),
      0 0 0 1px rgb(255 255 255 / 30%),
      0 0 0 3px rgb(255 255 255 / 30%),
      0 0 0 5px rgb(255 255 255 / 30%);
  }

  100% {
    box-shadow:
      0 0 0 0 rgb(255 255 255 / 30%),
      0 0 0 4px rgb(255 255 255 / 30%),
      0 0 0 20px rgb(255 255 255 / 0%),
      0 0 0 30px rgb(255 255 255 / 0%);
  }
}

@keyframes ripple-important {
  0% {
    box-shadow:
      0 0 0 0 rgb(255 0 0 / 30%),
      0 0 0 1px rgb(255 0 0 / 30%),
      0 0 0 3px rgb(255 0 0 / 30%),
      0 0 0 5px rgb(255 0 0 / 30%);
  }

  100% {
    box-shadow:
      0 0 0 0 rgb(255 0 0 / 30%),
      0 0 0 4px rgb(255 0 0 / 30%),
      0 0 0 20px rgb(255 0 0 / 0%),
      0 0 0 30px rgb(255 0 0 / 0%);
  }
}
</style>
