<script setup lang="ts">
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'
import { useDarkMode } from '/@src/stores/darkmode'
import { twMerge } from '/@src/styling/ts-merge'
import type { VInputProps } from '/@src/types/form-props'

const props = withDefaults(defineProps<VInputProps>(), {
  type: 'text',
  color: undefined,
  size: 'normal',
  autocomplete: undefined,
  inputMode: 'text',
  placeholder: undefined,
  step: undefined,
})

const modelValue = defineModel<any>({ required: true })

const darkMode = useDarkMode()

const input = ref<HTMLInputElement | null>(null)
const textArea = ref<HTMLTextAreaElement | null>(null)

const focusElement = () => {
  setTimeout(() => {
    if (props.type === 'textarea') {
      textArea.value?.focus()
    } else {
      input.value?.focus()
    }
  }, 50)
}
defineExpose({
  textArea,
  focusElement,
})

const $field = useVFieldInjection()

const computedSize = computed(() => `is-${props.size}`)
const computedColor = computed(() => {
  let color = props.color
  if ($field.color?.value) {
    color = $field.color.value
  }

  if (!color) {
    let colorClasses = 'border-border'
    if (darkMode.isDark) {
      colorClasses = twMerge(colorClasses, 'border-border-dark')
      if (!props.disabled && !props.readOnly) {
        colorClasses = twMerge(colorClasses, 'hover:border-gray-darker-800')
      }
    } else {
      if (!props.disabled && !props.readOnly) {
        colorClasses = twMerge('hover:border-gray-500')
      }
    }
    return colorClasses
  }

  if (darkMode.isDark) {
    return `border-border-dark focus:border-${color}-800`
  } else {
    return `border-${color}`
  }
})
</script>

<template>
  <input
    v-if="type !== 'textarea'"
    ref="input"
    v-model="modelValue"
    class="input"
    :class="[computedColor, size && computedSize]"
    :inputmode="inputMode"
    :readonly="readOnly"
    :type
    :placeholder
    :autocomplete
    :tabindex
    :disabled
    :step
  />
  <textarea
    v-else
    ref="textArea"
    v-model="modelValue"
    class="textarea"
    :class="[computedColor, size && computedSize]"
    :inputmode="inputMode"
    :readonly="readOnly"
    :placeholder
    :autocomplete
    :disabled
  />
</template>

<style scoped></style>
