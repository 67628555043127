<script
  setup
  lang="ts"
  generic="
    Data extends object,
    Keys extends string = string,
    RouterValue extends Data | undefined = undefined,
    IdKey extends string = string
  "
>
import type { VTableColumn, VTableColumns } from '/@src/types/elements-ui'
import {
  getFlexTableCellClass,
  getFlexTableColumnClass,
  getFlexTableColumnClasses,
} from '/@src/utils/tables'
import type { AbstractId, KeyOfType } from '/@src/types/utils'

interface VTableRowProps {
  row: Data
  columns: VTableColumns<Keys, RouterValue>
  index: number
  canSelectItems?: boolean
  idName?: KeyOfType<Data, AbstractId<string>>
}

const props = defineProps<VTableRowProps>()

const selectedItems = defineModel<AbstractId<IdKey>[]>('selectedItems', {
  required: false,
  default: undefined,
})
</script>

<template>
  <div
    v-if="canSelectItems && !('checkbox' in columns)"
    class="flex-table-cell fit-content"
  >
    <VCheckbox
      v-model="selectedItems"
      :value="row[idName as keyof Data] as number"
      circle
    />
  </div>
  <div
    v-for="column in columns"
    :key="`col-${column.key}`"
    class="flex-table-cell"
    :class="[
      getFlexTableColumnClasses(column),
      getFlexTableCellClass(row, column),
      getFlexTableColumnClass(column, 'widthClass'),
      column.onClick && 'cursor-pointer',
    ]"
    :tabindex="column.onClick ? 0 : undefined"
    @keydown="
      () => {
        column.onClick && column.onClick(row as RouterValue, column.key)
      }
    "
    @click="
      () => {
        column.onClick && column.onClick(row as RouterValue, column.key)
      }
    "
  >
    <slot name="body-cell" :row="row" :column="column" :index="index">
      <VTableBodyCell
        :row="row"
        :column="column as unknown as VTableColumn<Keys, Data>"
      />
    </slot>
  </div>

  <div
    v-if="$slots['action'] && !('actions' in columns)"
    class="flex-table-cell fit-content cursor-pointer justify-end"
  >
    <slot name="action" :row="row"></slot>
  </div>
</template>

<style scoped></style>
