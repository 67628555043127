<script setup lang="ts">
import type { ComponentSize } from '/@src/types/elements-ui'

type VCheckboxEmits = {
  click: []
}

interface VCheckboxProps {
  label?: string
  value: string | number // Name of this option
  size?: ComponentSize
  circle?: boolean
  disabled?: boolean
}

const emits = defineEmits<VCheckboxEmits>()
const props = withDefaults(defineProps<VCheckboxProps>(), {
  label: undefined,
  size: 'normal',
})

const modelValue = defineModel<any>()
</script>

<template>
  <label
    class="checkbox"
    :class="[circle && 'is-circle', size && `is-${size}`, disabled && 'is-disabled']"
  >
    <input
      v-model="modelValue"
      type="checkbox"
      class="checkbox-input"
      :value
      :disabled
      @change="emits('click')"
    />
    <span class="checkbox-element" />
    <span class="checkbox-label">
      <slot> {{ label }}</slot>
    </span>
  </label>
</template>

<style scoped></style>
