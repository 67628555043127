<script setup lang="ts" generic="T = never">
import { type FormWrapperProps } from '/@src/utils/zodUtils'
import type { ComponentClass } from '/@src/types/elements-ui'

export interface FormWrapperPropsCombined<T = never> extends FormWrapperProps<T> {
  errorColumnClass?: ComponentClass
  hideErrors?: boolean
}

const props = defineProps<FormWrapperPropsCombined<T>>()

const errorObject = computed(() => toValue(props.errors))
</script>

<template>
  <div>
    <slot v-if="!hideErrors" name="errors" :errors="errorObject">
      <div
        v-if="Object.keys(errorObject).length > 0"
        class="prose max-w-[unset]"
        :class="errorColumnClass"
      >
        <span class="!text-lg !font-bold !text-danger">Validatiefouten:</span>
        <ul class="marker:text-danger">
          <li
            v-for="(message, key) in errorObject"
            :key="`error-list-${id}-${key}`"
            class="font-sans !text-danger"
          >
            {{ message }}
          </li>
        </ul>
      </div>
    </slot>
    <slot :k="schema"></slot>
  </div>
</template>

<style scoped></style>
