<script setup lang="ts" generic="Keys extends string = string">
import type { DropdownElement } from '/@src/types/elements-ui'
import { toValue } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

type VDropdownExtendedEmits = {
  open: []
  close: []
}

interface VDropdownExtendedProps {
  elements?: DropdownElement<Keys>[]
  placement?: string
  skidding?: number
}

const emits = defineEmits<VDropdownExtendedEmits>()
const props = defineProps<VDropdownExtendedProps>()

const internalIsOpen = defineModel<boolean>('open')

const visibleElements = computed(() =>
  props.elements?.filter((element) => !toValue(element.hidden)),
)

const internalOnClick = (event: Event, onClick?: (event: Event) => void) => {
  if (onClick) {
    closeDropdown()
    onClick(event)
  }
}

const openDropdown = () => {
  internalIsOpen.value = true
  emits('open')
}
const closeDropdown = () => {
  internalIsOpen.value = false
  emits('close')
}

defineExpose({
  openDropdown,
  closeDropdown,
})
</script>

<template>
  <VDropdown
    class="cursor-pointer"
    :distance="12"
    :shown="internalIsOpen"
    :placement
    :skidding
    @apply-show="openDropdown"
    @apply-hide="closeDropdown"
  >
    <slot></slot>
    <template #popper>
      <slot name="pre-popper"></slot>

      <template v-for="element in visibleElements" :key="`element-${element.key}`">
        <hr v-if="toValue(element.topDivider)" class="dropdown-divider" />
        <RouterLink
          v-if="toValue(element.to)"
          :to="toValue(element.to) as RouteLocationRaw"
          class="dropdown-item"
          :class="toValue(element.disabled) && 'is-disabled'"
          @click="internalOnClick($event, element?.onClick)"
          @keyup="internalOnClick($event, element?.onClick)"
        >
          <VIcon :icon="element.icon" />
          <div class="title-subtitle">
            <span v-if="toValue(element.title)">{{ toValue(element.title) }}</span>
            <span v-if="toValue(element.subtitle)">{{ toValue(element.subtitle) }}</span>
          </div>
        </RouterLink>
        <div
          v-else
          class="dropdown-item"
          :class="toValue(element.disabled) && 'is-disabled'"
          @click.prevent="internalOnClick($event, element?.onClick)"
          @keyup.prevent="internalOnClick($event, element?.onClick)"
        >
          <VIcon :icon="element.icon" />
          <div class="title-subtitle">
            <span v-if="toValue(element.title)">{{ toValue(element.title) }}</span>
            <span v-if="toValue(element.subtitle)">{{ toValue(element.subtitle) }}</span>
          </div>
        </div>
        <hr v-if="toValue(element.bottomDivider)" class="dropdown-divider" />
      </template>

      <slot name="popper"></slot>
    </template>
  </VDropdown>
</template>

<style scoped></style>
