import type { ComponentClass, VTableColumn } from '/@src/types/elements-ui'
import { isFunction } from 'lodash'

/**
 * TODO: Find replacements for these classes
 * @param column
 */
export function getFlexTableColumnClasses<
  Data extends object | undefined,
  Keys extends string = string,
  RouterValue extends Data | undefined = undefined,
>(column: VTableColumn<Keys, RouterValue>): ComponentClass {
  return [
    column.narrow === true && 'narrow',
    column.fitContent === true && 'fit-content',
    column.align === 'end' && 'justify-end',
    column.align === 'center' && 'justify-center',
  ]
}

export function getFlexTableCellClass<
  Data extends object,
  Keys extends string = string,
  RouterValue extends Data | undefined = undefined,
>(
  row: Data | undefined,
  column: VTableColumn<Keys, RouterValue>,
): ComponentClass | undefined {
  const classes = column.cellClass

  if (!classes) {
    return undefined
  }

  if (isFunction(classes)) {
    if (!row) {
      return undefined
    }
    return classes(row as RouterValue, column)
  } else {
    return classes
  }
}

export function getFlexTableColumnClass<
  Data extends object,
  Keys extends string = string,
  RouterValue extends Data | undefined = undefined,
>(
  column: VTableColumn<Keys, RouterValue>,
  type: 'headerClass' | 'preRowClass' | 'widthClass',
): ComponentClass {
  const classes = column[type]

  if (!classes) {
    return undefined
  }

  if (isFunction(classes)) {
    return classes(column)
  } else {
    return classes
  }
}
