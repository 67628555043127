<script setup lang="ts">
import { computed } from 'vue'
import type {
  ComponentClass,
  ComponentSize,
  TailwindColor,
} from '/@src/types/elements-ui'
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'

type VSwitchBlockEmits = {
  click: []
}

interface VSwitchBlockProps {
  name: string
  rightLabel: string
  leftLabel: string
  controlClass?: ComponentClass
  color?: TailwindColor
  size?: ComponentSize
  noVModel?: boolean
  disabled?: boolean
  checked?: boolean
}

const emits = defineEmits<VSwitchBlockEmits>()
const props = withDefaults(defineProps<VSwitchBlockProps>(), {
  color: undefined,
  size: 'normal',
  controlClass: undefined,
})

const modelValue = defineModel<boolean | undefined>()

const $field = useVFieldInjection()

const computedSize = computed(() => `is-${props.size}`)
const computedColor = computed(() => {
  if (props.color) {
    return `before:bg-${props.color} after:bg-${props.color}-text`
  }

  return 'before:bg-success after:bg-success-text'
})

const toggleValue = () => {
  if (props.disabled) {
    return
  }
  modelValue.value = !modelValue.value
  emits('click')
}
const switchBlockModelValue = computed<boolean>({
  get() {
    if (props.noVModel) {
      return props.checked
    } else {
      return !!modelValue.value
    }
  },
  set() {
    if (!props.noVModel) {
      toggleValue()
    }
  },
})
</script>

<template>
  <div class="switch-block" :class="disabled && 'is-disabled'">
    <label
      v-if="$slots['left-text'] || leftLabel"
      class="switch-block-label left-label"
      :class="[controlClass, size && computedSize, disabled && 'is-disabled']"
      :for="name"
      @click.prevent="toggleValue"
      @keyup.prevent="toggleValue"
    >
      <slot name="left-text">{{ leftLabel }}</slot>
    </label>
    <input
      v-model="switchBlockModelValue"
      class="switch-input"
      :class="[size && computedSize, computedColor]"
      type="checkbox"
      :name
      :disabled
    />
    <label
      v-if="rightLabel"
      class="switch-block-label right-label"
      :class="[controlClass, size && computedSize, computedColor]"
      :for="name"
      @click.prevent="toggleValue"
      @keyup.prevent="toggleValue"
    >
      <slot> {{ rightLabel }}</slot>
    </label>
  </div>
</template>

<style scoped></style>
