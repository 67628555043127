<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'
import { isLeadMatchmaker, isOfficeManager } from '/@src/utils/entity-checks'

useHead({
  title: 'Dashboard | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const route = useRoute('/dashboard')
const router = useRouter()

const tabs: VTabsItem[] = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    to: {
      name: '/dashboard/',
    },
  },
  {
    label: 'Matchmakers',
    value: 'matchmakers',
    hidden: () => !isLeadMatchmaker.value && !isOfficeManager.value,
    to: {
      name: '/dashboard/matchmakers',
    },
  },
  {
    label: 'Absenties',
    value: 'absences',
    to: {
      name: '/dashboard/absences',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)

onMounted(() => {
  if (route.name === '/dashboard') {
    router.push({ name: '/dashboard/' })
  }
})
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" size="large" />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style scoped></style>
