<script setup lang="ts">
import { useDashboardStore } from '/@src/stores/dashboard'
import {
  type DashboardWidgetInfo,
  dashboardWidgetSelectorMap,
} from '/@src/mapping/dashboard'
import type { DashboardSlot, DashboardWidgetKey } from '/@src/types/dashboard'
import { userRolePermissionsMap } from '/@src/utils/entity-checks'
import type { UserRoleEnum } from '/@src/types/users'
import { uniq } from 'lodash'
import type { VTabsItem } from '/@src/types/elements-ui'
import { roles } from '/@src/mapping/users'
import type { MaybeArray } from '/@src/types/utils'

type CategoryTab = UserRoleEnum | 'general'

type DashboardWidgetModalEmits = {
  select: [slot: DashboardSlot | undefined, key: DashboardWidgetKey]
}

interface DashboardWidgetModalProps {
  slot: DashboardSlot | undefined
  isOverlay?: boolean
}

const emits = defineEmits<DashboardWidgetModalEmits>()
const props = defineProps<DashboardWidgetModalProps>()

const isOpen = defineModel<boolean>('open')

const dashboardStore = useDashboardStore()

const selectedTab = ref<CategoryTab>('general')
const availableCategories = computed<VTabsItem<CategoryTab>[]>(() => {
  const categories = Object.values(dashboardWidgetSelectorMap)
    .flatMap((v) => v.category)
    .filter((c): c is UserRoleEnum => {
      if (!c) {
        return false
      }

      return toValue(userRolePermissionsMap[c]) ?? true
    })

  const categoryTabs = uniq<UserRoleEnum>(categories).map<VTabsItem<CategoryTab>>(
    (category) => {
      const roleInfo = roles[category]
      return {
        value: category,
        label: roleInfo.name,
        icon: roleInfo.icon,
        hidden:
          !availableWidgets.value[category] ||
          availableWidgets.value[category]?.length === 0,
      }
    },
  )

  const baseTabs: VTabsItem<CategoryTab>[] = [
    {
      value: 'general',
      label: 'Algemeen',
    },
  ]

  return baseTabs.concat(categoryTabs)
})

const availableWidgets = computed<Partial<Record<CategoryTab, DashboardWidgetInfo[]>>>(
  () => {
    if (!dashboardStore.currentLoadout?.loadout) {
      return {} as Partial<Record<CategoryTab, DashboardWidgetInfo[]>>
    }

    const currentWidgets = Object.values(dashboardStore.currentLoadout.loadout.position)

    const widgets = Object.entries(dashboardWidgetSelectorMap)
      .filter(([key, value]) => {
        const settings = dashboardStore.getOrCreateWidgetSettings(
          key as DashboardWidgetKey,
          true,
        )

        const isCurrentWidget =
          !currentWidgets.includes(key as DashboardWidgetKey) || settings?.hidden
        return isCurrentWidget && !toValue(value.disabled)
      })
      .map(([_, v]) => v)

    const result: Partial<Record<CategoryTab, DashboardWidgetInfo[]>> = {}
    widgets.forEach((widget) => {
      let category: MaybeArray<CategoryTab> = widget.category ?? 'general'
      if (!Array.isArray(category)) {
        category = [category]
      }

      category.forEach((c) => {
        if (!result[c]) {
          result[c] = []
        }
        result[c].push(widget)
      })
    })

    return result
  },
)
</script>

<template>
  <VModal
    v-model:open="isOpen"
    :modal-id="`widget-modal${isOverlay ? '-overlay' : ''}`"
    size="2xl"
    title="Widget kiezen"
  >
    <template #content>
      <VTabs v-model:selected="selectedTab" :tabs="availableCategories" />

      <div class="columns multiline">
        <div
          v-for="widget in availableWidgets[selectedTab]"
          :id="`widget-modal-${widget.key}${isOverlay ? '-overlay' : ''}`"
          :key="`available-widget-${widget.key}`"
          class="column half"
        >
          <div class="columns items-center">
            <div class="column four-fifths title-subtitle text-xl">
              <h4 class="!mb-1">{{ widget.title }}</h4>
              <p class="!text-base !font-light">{{ widget.description }}</p>
            </div>
            <div class="column one-fifth h-full">
              <VIconBox
                icon="fa-plus"
                color="info"
                @click="emits('select', slot, widget.key)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<style scoped></style>
